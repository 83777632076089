export const createFeedbacksQuery = (initialRef, filter) => {
  const { lastFeedback, previousOrNext } = filter;
  let ref = initialRef;

  ref = ref.orderBy('createdAt', 'desc');

  if (lastFeedback && previousOrNext) {
    if (previousOrNext === 'previous') {
      ref = ref.endAt(lastFeedback);
    } else if (previousOrNext === 'next') {
      ref = ref.startAfter(lastFeedback);
    }
  }

  return ref.limit(10);
};
