import React from 'react';
// import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularLoadingButton from '../commoncomponents/CircularLoadingButton';
import {
  handleCreatePromptType,
  handleUpdatePromptType,
  loadingType,
  textStringType,
  createOrEditType,
  classesType,
  handleLoggedOutButtonClickType,
  authType
} from '../../types';

const styles = theme => ({
  // wrapper is needed to center the progress indicator with the button
  wrapper: {
    margin: theme.spacing(1), // gives it 8px margin around it
    position: 'relative' // relative allows progress indicator, which is absolute, to go inside it
  }
});

const PostPromptButton = ({
  classes,
  handleUpdatePrompt,
  handleCreatePrompt,
  loading,
  text,
  createOrEdit,
  handleLoggedOutButtonClick,
  auth
}) => {
  // if id for prompt exists then we call edit, if it does not then we call create
  const handleClick = () => {
    if (createOrEdit === 'create') {
      handleCreatePrompt();
    } else if (createOrEdit === 'edit') {
      handleUpdatePrompt();
    }
  };

  return (
    <div className={classes.wrapper}>
      <Button
        // size={isSmallScreen ? 'small' : 'medium'}
        size="medium"
        variant="contained"
        color="primary"
        disabled={loading}
        onClick={
          auth && auth.authenticated ? handleClick : handleLoggedOutButtonClick
        }
      >
        {text}
      </Button>
      {loading && <CircularLoadingButton />}
    </div>
  );
};

PostPromptButton.propTypes = {
  handleCreatePrompt: handleCreatePromptType.isRequired,
  handleUpdatePrompt: handleUpdatePromptType.isRequired,
  loading: loadingType.isRequired,
  text: textStringType.isRequired,
  createOrEdit: createOrEditType.isRequired,
  classes: classesType.isRequired,
  handleLoggedOutButtonClick: handleLoggedOutButtonClickType.isRequired,
  auth: authType.isRequired
};

const enhance = compose(withStyles(styles));

export default enhance(PostPromptButton);
