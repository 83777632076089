// used to show page is loading but is used on top of buttons
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { classesType } from '../../types';

const styles = () => ({
  buttonProgress: {
    color: lightBlue[300],
    position: 'absolute', // absolute allows it to go inside / on top of the button
    top: '50%', // center it vertically
    left: '50%', // center it horizontally
    marginTop: -12, // the progress indicator is 24px so this centers the indicator itself
    marginLeft: -12 // the progress indicator is 24px so this centers the indicator itself
  }
});

const CircularLoadingProgress = ({ classes }) => {
  return <CircularProgress size={24} className={classes.buttonProgress} />;
};

CircularLoadingProgress.propTypes = {
  classes: classesType.isRequired
};

export default withStyles(styles)(CircularLoadingProgress);
