import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { openModal } from '../../actions';
import {
  loadingType,
  classesType,
  promptIDType,
  promptTitleType,
  openModalType,
  isSmallScreenBoolType
} from '../../types';

const styles = () => ({});

const DeleteButton = ({
  promptID,
  promptTitle,
  classes,
  loading,
  isSmallScreen,
  ...rest
}) => {
  const handleDelete = () => {
    rest.openModal('DeletePromptModal', { promptID, promptTitle });
  };

  if (isSmallScreen) {
    return <MenuItem onClick={handleDelete}>Delete</MenuItem>;
  }
  return (
    <Button
      // variant="contained"
      // color="primary"
      disabled={loading}
      onClick={handleDelete}
      className={classes.deleteButton}
    >
      Delete
    </Button>
  );
};
DeleteButton.propTypes = {
  loading: loadingType.isRequired,
  classes: classesType.isRequired,
  promptID: promptIDType.isRequired,
  promptTitle: promptTitleType.isRequired,
  openModal: openModalType.isRequired,
  isSmallScreen: isSmallScreenBoolType.isRequired
};

const mapStateToProps = ({ isSmallScreen }) => ({
  isSmallScreen
});

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps, { openModal })
);

export default enhance(DeleteButton);
