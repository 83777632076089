// an array of all of the kink types
export default [
  'bodies',
  'clothing',
  'groupings',
  'general',
  'ass play',
  'restrictive',
  'toys',
  'domination',
  'no consent',
  'taboo',
  'surrealism',
  'fluids',
  'degradation',
  'touch and stimulation',
  'miscellaneous fetish',
  'pain'
];
