// flag the prompt or user, update the flaggedPrompts or flaggedUsers subcollection of the reporter
// note that this action creator does Not have a corresponding reducer
import firebase, { firestore } from '../firebase';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';
import { openSnackbar } from './snackbarActions';
import { GET_FLAGGED_USERS } from './types';

// get flagged users, only used in Flagged Users Admin page
export const getFlaggedUsers = () => async dispatch => {
  dispatch(asyncActionStart());
  try {
    const usersFlaggedRef = firestore.collection('usersFlagged');
    const usersFlaggedSnapshots = await usersFlaggedRef.get();
    const usersFlagged = usersFlaggedSnapshots.docs.map(doc => {
      const { id } = doc;
      const data = doc.data();
      return { ...data, id };
    });
    console.log({ usersFlagged });
    dispatch({ type: GET_FLAGGED_USERS, payload: { usersFlagged } });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const flagUser = flagObject => async (dispatch, getState) => {
  dispatch(asyncActionStart());
  try {
    const { userID, reason, comment } = flagObject;
    const reporterID = getState().auth.currentUser.uid;

    // add the flag to the reporter's flaggedUsers subcollection
    const reporterFlaggedUserRef = firestore
      .collection('usersPrivate')
      .doc(reporterID)
      .collection('flaggedUsers')
      .doc(userID);

    await reporterFlaggedUserRef.set({
      reason,
      comment,
      userID,
      reporterID,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    });
    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: 'successfully reported the user!'
      })
    );
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const flagPrompt = flagObject => async (dispatch, getState) => {
  dispatch(asyncActionStart());
  try {
    const {
      promptID,
      reason,
      comment,
      promptCreatorID,
      promptCreator,
      promptTitle
    } = flagObject;
    const userID = getState().auth.currentUser.uid;

    // add the flag to the user
    const userPromptRef = firestore
      .collection('usersPrivate')
      .doc(userID)
      .collection('flaggedPrompts')
      .doc(promptID);
    await userPromptRef.set({
      reason,
      comment,
      promptCreatorID,
      promptCreator,
      promptTitle
    });
    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: 'successfully reported the prompt!'
      })
    );
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
  return null;
};
