// update my kinks
import merge from 'lodash/merge'; // used for deep merge
import { UPDATE_MY_KINKS_REDUX_STATE } from '../actions/types'; // import the action type
import transformKinksList from '../utils/transformKinksList';

const initialState = () => transformKinksList();

export default function(state = initialState(), action) {
  switch (action.type) {
    case UPDATE_MY_KINKS_REDUX_STATE: {
      return merge({}, state, action.payload);
    }
    default:
      return state;
  }
}
