import { combineReducers } from 'redux';
import myKinksReducer from './myKinksReducer';
import filterPromptsReducer from './filterPromptsReducer';
import asyncReducer from './asyncReducer';
import getPromptsReducer from './getPromptsReducer';
import getMyPromptsReducer from './getMyPromptsReducer';
import getPromptReducer from './getPromptReducer';
import modalReducer from './modalReducer';
import snackbarReducer from './snackbarReducer';
import authReducer from './authReducer';
import bookmarkReducer from './bookmarkReducer';
import myBookmarksReducer from './myBookmarksReducer';
import loveReducer from './loveReducer';
import myLovesReducer from './myLovesReducer';
import userPageReducer from './userPageReducer';
import followReducer from './followReducer';
import myUserReducer from './myUserReducer';
import conversationsReducer from './conversationsReducer';
import conversationReducer from './conversationReducer';
import inboxReducer from './inboxReducer';
import myPenpalsReducer from './myPenpalsReducer';
import myStatsReducer from './myStatsReducer';
import expandRetractReducer from './expandRetractReducer';
import feedbacksReducer from './feedbacksReducer';
import feedbacksFilterReducer from './feedbacksFilterReducer';
import expandRetractKinksReducer from './expandRetractKinksReducer';
import errorsReducer from './errorsReducer';
import tagsReducer from './tagsReducer';
import newStuffAlertsReducer from './newStuffAlertsReducer';
import flaggedUsersReducer from './flaggedUsersReducer';
import isSmallScreenReducer from './isSmallScreenReducer';
import componentVisibilityReducer from './componentVisibilityReducer';
import usersReducer from './usersReducer';
import specialReducer from './specialReducer';

// combine all the reducers here
export default combineReducers({
  async: asyncReducer,
  auth: authReducer,
  bookmark: bookmarkReducer,
  componentVisibility: componentVisibilityReducer,
  conversation: conversationReducer,
  conversations: conversationsReducer,
  errors: errorsReducer,
  feedbacks: feedbacksReducer,
  feedbacksFilter: feedbacksFilterReducer,
  filter: filterPromptsReducer,
  flaggedUsers: flaggedUsersReducer,
  follow: followReducer,
  inbox: inboxReducer,
  isSmallScreen: isSmallScreenReducer,
  love: loveReducer,
  modal: modalReducer,
  tags: tagsReducer,
  myBookmarks: myBookmarksReducer,
  myKinks: myKinksReducer,
  myLoves: myLovesReducer,
  myPenpals: myPenpalsReducer,
  myPrompts: getMyPromptsReducer,
  myStats: myStatsReducer,
  myUser: myUserReducer,
  newStuffAlerts: newStuffAlertsReducer,
  prompt: getPromptReducer,
  prompts: getPromptsReducer,
  retracted: expandRetractReducer,
  retractedKinks: expandRetractKinksReducer,
  snackbar: snackbarReducer,
  special: specialReducer,
  userPage: userPageReducer,
  users: usersReducer
});
