// make sure the email is valid
// used by ContactForm.js

export default email => {
  const valid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const errors = {};
  if (!valid) {
    errors.email = 'Email is invalid!';
  }
  if (Object.keys(errors).length === 0) {
    return null;
  }
  errors.type = 'manmade';
  return errors;
};
