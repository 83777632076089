// reducer for bookmarkListener and removeBookmarkListener in bookmarkPrompt.js action creators
import {
  BOOKMARKED,
  NOT_BOOKMARKED,
  REMOVE_BOOKMARK_LISTENER,
  BOOKMARK_BUTTON_CLICK_PENDING_START,
  BOOKMARK_BUTTON_CLICK_PENDING_FINISH
} from '../actions/types';

const initialState = {
  listenerLoaded: false,
  bookmarked: false,
  unsubscribe: null,
  bookmarkButtonClickPending: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BOOKMARKED: {
      return {
        ...state,
        listenerLoaded: true,
        bookmarked: true,
        unsubscribe: action.payload.unsubscribe
      };
    }
    case NOT_BOOKMARKED:
      return {
        ...state,
        listenerLoaded: true,
        bookmarked: false,
        unsubscribe: action.payload.unsubscribe
      };
    case REMOVE_BOOKMARK_LISTENER:
      return {
        ...state,
        listenerLoaded: false,
        bookmarked: false,
        unsubscribe: null
      };
    case BOOKMARK_BUTTON_CLICK_PENDING_START:
      return { ...state, bookmarkButtonClickPending: true };
    case BOOKMARK_BUTTON_CLICK_PENDING_FINISH:
      return { ...state, bookmarkButtonClickPending: false };
    default:
      return state;
  }
};
