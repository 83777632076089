// on desktop, this just renders the DeleteButton.js
// on mobile, this renders the menu with DeleteButton and SaveDraftButton
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteButton from './DeleteButton';
import {
  authType,
  classesType,
  handleLoggedOutButtonClickType,
  handleSaveDraftOrUnpublishType,
  isSmallScreenBoolType,
  loadingType,
  matchType,
  promptType,
  promptIDType,
  promptTitleType
} from '../../types';

const styles = () => ({});

const BottomLeftMobileMenu = ({
  auth,
  classes,
  handleLoggedOutButtonClick,
  handleSaveDraftOrUnpublish,
  isSmallScreen,
  loading,
  match,
  prompt,
  promptID,
  promptTitle
}) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  if (isSmallScreen) {
    // opens the mobile menu by changing the menuAnchor from null to something else
    // toggles the mobile menu by changing this.state.menuAnchor
    // if menuAnchor is null (currently menu is closed) then open the menu
    // if menuAnchor is Not null (currently menu is open) then close the menu by changing the menuAnchor to null
    const toggleMenu = event => {
      if (menuAnchor === null) {
        setMenuAnchor(event.currentTarget);
      } else if (menuAnchor !== null) {
        setMenuAnchor(null);
      }
    };

    const text = prompt.published === true ? 'Hide Prompt' : 'Save Draft';

    const handleClickSaveDraftMenuItem = () => {
      if (auth && auth.authenticated) {
        handleSaveDraftOrUnpublish(text)();
      } else {
        handleLoggedOutButtonClick();
      }
      toggleMenu();
    };

    return (
      <>
        <IconButton
          color="inherit"
          disabled={loading}
          onClick={toggleMenu}
          className={classes.deleteButton}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={toggleMenu}
        >
          {/* we use a MenuItem for Save Draft instead of our SaveDraftButton component
          because the first item in a menu needs to be a menuitem or I get an error */}
          <MenuItem onClick={handleClickSaveDraftMenuItem}>{text}</MenuItem>
          {match.path !== '/create' && (
            <DeleteButton
              promptID={promptID}
              promptTitle={promptTitle}
              loading={loading}
            />
          )}
        </Menu>
      </>
    );
  }
  if (match.path !== '/create') {
    return (
      <DeleteButton
        promptID={promptID}
        promptTitle={promptTitle}
        loading={loading}
      />
    );
  }
  //   we need an empty div when not rendering the delete button because
  // we want the other buttons to be at the end of the row. We achieve this
  // by using display:flex and justifyContent: space-between
  return <div />;
};

BottomLeftMobileMenu.propTypes = {
  auth: authType.isRequired,
  classes: classesType.isRequired,
  handleLoggedOutButtonClick: handleLoggedOutButtonClickType.isRequired,
  handleSaveDraftOrUnpublish: handleSaveDraftOrUnpublishType.isRequired,
  isSmallScreen: isSmallScreenBoolType.isRequired,
  loading: loadingType.isRequired,
  match: matchType.isRequired,
  prompt: promptType.isRequired,
  promptID: promptIDType.isRequired,
  promptTitle: promptTitleType.isRequired
};

const mapStateToProps = ({ isSmallScreen }) => ({
  isSmallScreen
});

const actions = {};

const enhance = compose(connect(mapStateToProps, actions), withStyles(styles));

export default enhance(BottomLeftMobileMenu);
