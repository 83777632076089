// prepares draft js editor state for saving to or retreiving from firebase
import {
  convertToRaw,
  convertFromRaw,
  EditorState,
  ContentState
} from 'draft-js';

// prepares the draft js editor state for storage
// takes in an EditorState and outputs a json converted into a string
// first takes the current state then converts into json then converts into string
export const prepareEditorStateForStorage = editorState => {
  const contentState = editorState.getCurrentContent();
  const textJson = convertToRaw(contentState);
  const textString = JSON.stringify(textJson);
  return textString;
};

// after retreiving text version of draft js editor state from firebase
// converts the text into JSON then make it into the editor state
export const reviveEditorStateAfterStorage = textString => {
  const parsedText = JSON.parse(textString);
  const contentState = convertFromRaw(parsedText);
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
};

// get plain string from text, which is an editorState object
// used for meta description for HeadManager
// also truncates the text length to 160 max
// and replaces all double quotes (") with &quot;
// because double quotes cuts off the meta description
export const editorStateToPlainTextMetaDescription = textEditorState => {
  const plainText = textEditorState.getCurrentContent().getPlainText();
  const truncatedPlainText = plainText.substring(0, 160);
  const modPlainText = truncatedPlainText.replace(/"/g, '&quot;');
  return modPlainText;
};

export const editorStateToPlainText = textEditorState => {
  const contentState = textEditorState.getCurrentContent();
  const plainText = contentState.getPlainText();
  return plainText;
};

// we use draftjs to convert plain string into a string of a contentState
// in order to save text generated by our ML model (plain string) into the doc
// of the conversation message as the messageText field
export const preparePlainTextForStorage = plainText => {
  const contentState = ContentState.createFromText(plainText);
  const textJson = convertToRaw(contentState);
  const textString = JSON.stringify(textJson);
  return textString;
};

// we use draftjs to decode the text in prompt directly from storage into plain text
export const retreievePlainTextAfterStorage = textString => {
  const parsedText = JSON.parse(textString);
  const contentState = convertFromRaw(parsedText);
  const editorState = EditorState.createWithContent(contentState);
  const plainText = editorState.getCurrentContent().getPlainText();
  const plainTextMod = plainText
    .replace(/\n/g, '\n\n')
    .replace(/\n\n\n/g, '\n\n');
  return plainTextMod;
};
