// get the currently auth'd user stats such as number of prompts, conversations
// started and conversations received
// used in profilepage/ProfileStats.js
import { firestore } from '../firebase';
import { GET_MY_STATS } from './types';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';

export const getMyStats = () => async (dispatch, getState) => {
  dispatch(asyncActionStart());
  try {
    const user = getState().auth.currentUser;
    const userID = user.uid;

    // get number of prompts user made
    const promptsQuery = firestore
      .collection('prompts')
      .where('creatorID', '==', userID);
    const promptsSnapshot = await promptsQuery.get();
    const promptsNumber = promptsSnapshot.docs.length;

    // // get number of conversations the user is in
    // const conversationsQuery = firestore
    //   .collection('conversations')
    //   .where('participantsIDs', 'array-contains', userID);
    // const conversationsSnapshot = await conversationsQuery.get();
    // const conversationsNumber = conversationsSnapshot.docs.length;

    // get number of conversations the user has started
    const conversationsStartedQuery = firestore
      .collection('conversations')
      .where('conversationStarterID', '==', userID);
    const conversationsStartedSnapshot = await conversationsStartedQuery.get();
    const conversationsStartedNumber = conversationsStartedSnapshot.docs.length;

    // get number of conversations the user has received
    const conversationsReceivedQuery = firestore
      .collection('conversations')
      .where('promptCreatorID', '==', userID);
    const conversationsReceivedSnapshot = await conversationsReceivedQuery.get();
    const conversationsReceivedNumber =
      conversationsReceivedSnapshot.docs.length;

    dispatch({
      type: GET_MY_STATS,
      payload: {
        promptsNumber,
        conversationsStartedNumber,
        conversationsReceivedNumber
      }
    });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};
