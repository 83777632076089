// check if the screen is large (over 600px) or small (under 600px)
// if it is large isSmallScreen action creator sends false
// if it is small isSmallScreen action creator sends true
import React from 'react';
import { connect } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { isSmallScreen } from '../../actions';
import { isSmallScreenFuncType } from '../../types';

const SmallScreenCheck = props => {
  // down('xs') means anything below 600px is TRUE!
  // check if the width of the screen width is less than 600px (sm) or not
  // if it is less than 600px then matchesSmallScreen is true
  // if the screen is larger than 600px returns false
  const theme = useTheme();
  const isSmallScreenConst = useMediaQuery(theme.breakpoints.down('xs'));
  props.isSmallScreen(isSmallScreenConst);
  return <></>;
};

SmallScreenCheck.propTypes = {
  isSmallScreen: isSmallScreenFuncType.isRequired
};

const actions = { isSmallScreen };

export default connect(null, actions)(SmallScreenCheck);
