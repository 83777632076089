// login to or register with firebase auth
import { LOGIN_USER, LOGOUT_USER, UNSUB_FROM_AUTH } from './types';
import firebase, { firestore } from '../firebase';
import { openModal, closeModal } from './modalActions';
import { getMyKinks } from './kinksActions';
import { openSnackbar } from './snackbarActions';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';
import { dispatchFirebaseError } from './errorsActions';
import { getMyUserInfoPrivate } from './getUserActions';
import { resetMyUserReduxState } from './updateMyUserReduxState';
import { newStuffAlertsListener } from './newStuffAlertsActions';

const createNewUserPublic = newUserObject => {
  return {
    ...newUserObject,
    about: '',
    points: 0
  };
};

const createNewUserPrivate = (newUserObject, email, age) => {
  return {
    ...newUserObject,
    email,
    age,
    notifications: {
      newConvos: true, // not used
      newReplies: true, // not used
      newPrompts: true, // not used
      newConvoMessages: true
    },
    lookingforPreferences: {
      iam: 'female',
      lookingfor: 'male'
    }
  };
};

// called by fetchAuthUser() and fetchAuthUserOnce() if the user is logged in
// fetches the username from the user's usersPublic doc
// add certain things to the redux state and start certain listeners
const loggedInActions = async (user, dispatch) => {
  // console.log('currently logged in');
  const { email, uid, providerData } = user;

  const userPublicSnapshot = await firestore
    .collection('usersPublic')
    .doc(uid)
    .get();

  // we check if the userPublic doc exists so we don't crash the app
  // when we accidentally login with google without signing up first
  // only needed in fetchAuthUser(), not in fetchAuthUserOnce(), because fetchAuthUser is a listener
  if (userPublicSnapshot.exists) {
    const { username } = userPublicSnapshot.data();
    dispatch({
      type: LOGIN_USER,
      payload: {
        currentUser: {
          email,
          uid,
          displayName: username
        },
        providerData: [...providerData]
      }
    });

    // getMyKinks is called here so matching kinks would work
    // if the user is newly created then kinksTree fetched from firestore would be undefined
    dispatch(getMyKinks());
    dispatch(getMyUserInfoPrivate()); // get the doc from usersPrivate to update myUser in redux
    dispatch(newStuffAlertsListener()); // get the latest alerts for conversations, messages and prompts and start listening for new ones!
  }
};

// called by fetchAuthUser() and fetchAuthUserOnce() if the user is logged out
// remove certain things in redux state and stops certain listeners
const loggedOutActions = async (dispatch, getState) => {
  // console.log('currently logged out');
  dispatch({ type: LOGOUT_USER, payload: null });
  // remove the myUser redux state to an empty object
  dispatch(resetMyUserReduxState());
  // unsubscribe to various listeners after logging out
  // unsubscribe to newStuffAlerts listener
  const { newStuffAlerts } = getState();
  if (newStuffAlerts && newStuffAlerts.unsubscribeNewStuffAlertsListener) {
    await newStuffAlerts.unsubscribeNewStuffAlertsListener();
  }
};

// called by registerUser() and socialLogin()
// creates the user document in both usersPrivate and usersPublic collections with batch
const createUserDocsWithBatch = async (
  userID,
  newUserPrivate,
  newUserPublic
) => {
  const batch = firestore.batch();
  const userPrivateRef = firestore.collection('usersPrivate').doc(userID);
  const userPublicRef = firestore.collection('usersPublic').doc(userID);
  await batch.set(userPrivateRef, newUserPrivate);
  await batch.set(userPublicRef, newUserPublic);
  await batch.commit();
};

// automatically detects the change in auth state when the user logs in or out
// it is called in componentDidMount of App.js
// this activates when the page loads initially or when we call login or logout explicitly
export const fetchAuthUser = () => async (dispatch, getState) => {
  const unsubscribeFromAuth = await firebase
    .auth()
    .onAuthStateChanged(async user => {
      if (user) {
        // the user is currently logged in
        await loggedInActions(user, dispatch);
      } else {
        // the user is currently logged out
        await loggedOutActions(dispatch, getState);
      }
    });

  dispatch({ type: UNSUB_FROM_AUTH, payload: { unsubscribeFromAuth } });
};

// get the currently authenticated user and updates auth redux state
// This only gets it ONCE, unlike fetchAuthUser which is a listener
export const fetchAuthUserOnce = () => async (dispatch, getState) => {
  const user = firebase.auth().currentUser;
  if (user) {
    // the user is currently logged in
    await loggedInActions(user, dispatch);
  } else {
    // the user is currently logged out
    await loggedOutActions(dispatch, getState);
  }
};

// when logging in, LOGIN_USER of fetchAuthUser is triggered
export const login = (creds, usingModal = true) => async dispatch => {
  dispatch(asyncActionStart());
  try {
    await firebase
      .auth()
      .signInWithEmailAndPassword(creds.email, creds.password);
    // if using LoginModal or LoginPage
    if (usingModal) {
      dispatch(closeModal());
      dispatch(asyncActionFinish());
    } else {
      dispatch(asyncActionFinish());
      // if using LoginPage or SignupPage
      const loginSuccess = true;
      return loginSuccess;
    }
  } catch (error) {
    console.log(error);
    dispatch(dispatchFirebaseError(error));
    dispatch(asyncActionError());
  }
  return null;
};

// when logging out, LOGOUT_USER of fetchAuthUser is triggered
export const logout = () => async () => {
  try {
    await firebase.auth().signOut();
  } catch (error) {
    console.log(error);
  }
};

// registers the user with email and password
export const registerUser = (user, usingModal = true) => async dispatch => {
  dispatch(asyncActionStart());
  try {
    const { username, age, email, password } = user;

    // need to save a lowercase version because we want to easily query and check if the username is taken
    const usernameLower = username.toLowerCase();

    // create the user in firebase authentication
    const result = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);

    const newUserObject = {
      username,
      usernameLower,
      createdAt: firebase.firestore.FieldValue.serverTimestamp() // only this works. firestore.FieldValue.serverTimestamp() does Not work!
    };

    // create the user in firestore
    const newUserPublic = createNewUserPublic(newUserObject);
    const newUserPrivate = createNewUserPrivate(newUserObject, email, age);

    // get the uid from the newly created firebase authentication user
    const userID = result.user.uid;
    // creates the user doc in usersPublic and usersPrivate collections
    await createUserDocsWithBatch(userID, newUserPrivate, newUserPublic);
    // get updated info of the user
    dispatch(fetchAuthUserOnce());

    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: 'Welcome to EroPenPal!'
      })
    );
    // if using SignupModal or SignupPage
    if (usingModal) {
      dispatch(closeModal());
      dispatch(asyncActionFinish());
    } else {
      dispatch(asyncActionFinish());
      const signupSuccess = true;
      return signupSuccess;
    }
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    dispatch(dispatchFirebaseError(error));
  }
  return null;
};

// use google, facebook, twitter etc. to login, currently only google works
export const socialLogin = (
  selectedProvider,
  user,
  usingModal = true
) => async dispatch => {
  dispatch(asyncActionStart());
  try {
    let provider;
    if (selectedProvider === 'google') {
      provider = new firebase.auth.GoogleAuthProvider();
    }
    const result = await firebase.auth().signInWithPopup(provider);
    // check if firebase auth user we just logged in with existed before or not
    // if it did not exist then we are either registering a new user, or accidentally
    // logging in without registering first
    if (result.additionalUserInfo.isNewUser) {
      // if the user argument does not exist, then we are registering from the logging menu
      // delete the user we just registered and show an error snackbar and a sign up modal
      if (!user) {
        const { currentUser } = firebase.auth();
        await currentUser.delete();
        throw new Error('logged in user does not exist');
      }

      // if the user argument exists then we are properly registering the user
      // add the username as displayName to firebase auth
      // also create user docs in usersPrivate and usersPublic collections
      const { username, age } = user;
      const usernameLower = username.toLowerCase();

      const newUserObject = {
        username,
        usernameLower,
        createdAt: firebase.firestore.FieldValue.serverTimestamp() // only this works. firestore.FieldValue.serverTimestamp() does Not work!
      };

      // create the user in firestore
      const newUserPublic = createNewUserPublic(newUserObject);
      const newUserPrivate = createNewUserPrivate(
        newUserObject,
        result.user.email,
        age
      );
      // get the uid from the newly created firebase authentication user
      const userID = result.user.uid;
      // creates the user doc in usersPublic and usersPrivate collections
      await createUserDocsWithBatch(userID, newUserPrivate, newUserPublic);

      // get updated info of the user
      dispatch(fetchAuthUserOnce());

      dispatch(
        openSnackbar('SuccessSnackbar', {
          message: 'Welcome to EroPenPal!'
        })
      );
    }
    // if using LoginModal or SignupModal
    if (usingModal) {
      dispatch(asyncActionFinish());
      dispatch(closeModal());
    } else {
      dispatch(asyncActionFinish());
      // if using LoginPage or SignupPage
      const loginSuccess = true;
      return loginSuccess;
    }
  } catch (error) {
    console.log(error);
    if (error.message === 'logged in user does not exist') {
      dispatch(
        openSnackbar('ErrorSnackbar', {
          message: 'User does not exist. Please sign up!',
          autoHideDuration: 12000
        })
      );
      dispatch(openModal('SignUpModal'));
    }
    dispatch(asyncActionError());
  }
  return null;
};

// used to reauthenticate user before performing sensitive actions
export const reAuthenticateUser = error => async dispatch => {
  dispatch(openModal('LogInModal'));
  dispatch(dispatchFirebaseError(error));
};
