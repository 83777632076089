// used to edit prompts
// used by editpromptpage/EditPromptForm.js, conversationpage/EditMessageForm.js
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  textObjectType,
  onChangeType,
  classesType,
  placeholderType
} from '../../types';

const styles = {
  wrapper: {},
  editor: {
    minHeight: '18rem', // minimum height of the textarea
    border: '1px solid #F1F1F1', // border color and size of the textarea
    padding: '0.25rem', // 4px padding around the text in textarea
    borderRadius: '0.125rem', // 2px, rounded corners of the textarea
    resize: 'vertical', // allow you to increase the height of textarea using the tab in bottom-right

    // my own customization
    fontSize: '16px' // 16px is the recommended size for mobile
  }
};

const TextEditorPrompt = props => {
  const { text: editorState, classes, placeholder } = props;
  return (
    <div className="editorContainer">
      <div className="editor">
        <Editor
          placeholder={placeholder}
          editorState={editorState}
          wrapperClassName={classes.wrapper}
          editorClassName={classes.editor}
          onEditorStateChange={props.onChange}
          toolbar={{
            options: [
              'inline',
              'blockType',
              // 'fontSize',
              // 'fontFamily',
              'list',
              'textAlign',
              // 'colorPicker',
              'link',
              'embedded',
              'emoji',
              'image',
              // 'remove',
              'history'
            ],
            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: [
                'bold',
                'italic',
                'underline',
                'strikethrough',
                // 'monospace',
                'superscript'
                // 'subscript'
              ]
            },
            list: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['unordered', 'ordered'] // 'indent', 'outdent']
            },
            textAlign: {
              inDropdown: true,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['left', 'center', 'right', 'justify']
            },
            link: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              popupClassName: undefined,
              dropdownClassName: undefined,
              showOpenOptionOnHover: true,
              defaultTargetOption: '_self',
              options: ['link'] // 'unlink']
            },
            history: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['undo', 'redo']
            }
          }}
        />
        {/* <textarea
            rows="5"
            cols="100"
            disabled
            value={JSON.stringify(editorState.getCurrentContent(), null, 4)}
          /> */}
      </div>
    </div>
  );
};

TextEditorPrompt.propTypes = {
  text: textObjectType.isRequired,
  onChange: onChangeType.isRequired,
  classes: classesType.isRequired,
  placeholder: placeholderType.isRequired
};

export default withStyles(styles)(TextEditorPrompt);
