// get most popular things like most popular tags from firestore
import { GET_MOST_POPULAR_TAGS, GET_ALL_TAGS } from './types';
import { firestore } from '../firebase';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';

// get the allTags doc in misc collection that updates once every XX hour
// used by the TagsPages
export const getAllTags = () => async dispatch => {
  dispatch(asyncActionStart());
  try {
    const allTagsMiscRef = firestore.collection('misc').doc('allTags');
    const allTagsSnapshot = await allTagsMiscRef.get();
    const allTags = allTagsSnapshot.data();
    dispatch({ type: GET_ALL_TAGS, payload: { allTags } });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

// get the most popular tags (docs with the highest totalCount) from tags collection
// note that the tags collection is live (updates constantly)
export const getMostPopularTags = (numberOfTags = 15) => async dispatch => {
  try {
    // get 10 tags with the most totalCount
    const tagsQuery = firestore
      .collection('tags')
      .orderBy('totalCount', 'desc')
      .limit(numberOfTags);
    const tagsSnapshot = await tagsQuery.get();
    // each tag has its names in various cases such as hello, HELLO, Hello etc.
    // for each tag, use the case name with the highest value
    const mostPopularTags = tagsSnapshot.docs.map(tagSnapshot => {
      const tagData = tagSnapshot.data();
      const tagDataFiltered = Object.keys(tagData).filter(tagAltName => {
        return tagAltName !== 'totalCount';
      });
      const tagDataSorted = tagDataFiltered.sort((a, b) => {
        return tagData[b] - tagData[a];
      });
      return tagDataSorted[0];
    });
    dispatch({ type: GET_MOST_POPULAR_TAGS, payload: { mostPopularTags } });
  } catch (error) {
    console.log(error);
  }
};
