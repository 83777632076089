import React from 'react';
import { connect } from 'react-redux';
import SuccessSnackbar from './SuccessSnackbar';
import ErrorSnackbar from './ErrorSnackbar';
import { currentSnackbarType } from '../../types';

const snackbarLookup = {
  SuccessSnackbar,
  ErrorSnackbar
};

const SnackbarManager = ({ currentSnackbar }) => {
  let renderedSnackbar;
  if (currentSnackbar) {
    const { snackbarType, snackbarProps } = currentSnackbar;
    const SnackbarComponent = snackbarLookup[snackbarType];

    renderedSnackbar = <SnackbarComponent {...snackbarProps} />;
  }

  return <div>{renderedSnackbar}</div>;
};

SnackbarManager.propTypes = {
  currentSnackbar: currentSnackbarType
};

SnackbarManager.defaultProps = {
  currentSnackbar: null
};

const mapStateToProps = ({ snackbar }) => ({
  currentSnackbar: snackbar
});

export default connect(mapStateToProps)(SnackbarManager);
