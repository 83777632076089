import { firestore } from '../firebase';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';
import {
  UPDATE_MY_KINKS_REDUX_STATE,
  UPDATE_USER_KINKS_REDUX_STATE,
  REMOVE_USER_KINKS_REDUX_STATE
} from './types';
import { openSnackbar } from './snackbarActions';

// ----userKinks-------
// get the kinks for that user (you're on that user's page) from the firestore
// then update it in the userKinks of redux state
export const getUserKinks = userID => async dispatch => {
  dispatch(asyncActionStart());
  try {
    const userRef = firestore.collection('usersPublic').doc(userID);
    const result = await userRef
      .collection('kinks')
      .doc('kinksTree')
      .get();
    const kinksTree = result.data();

    // console.log('getting kinks', { kinksTree });
    // the reducer catching it is userPageReducer!
    dispatch({ type: UPDATE_USER_KINKS_REDUX_STATE, payload: kinksTree });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError(error));
  }
};

export const removeUserKinksInState = () => async dispatch => {
  try {
    dispatch({ type: REMOVE_USER_KINKS_REDUX_STATE });
  } catch (error) {
    console.log(error);
  }
};

// ----myKinks-------
// update myKinks state in redux state
export const updateMyKinksReduxState = myKinksObject => async dispatch => {
  dispatch({ type: UPDATE_MY_KINKS_REDUX_STATE, payload: myKinksObject });
};

// get my kinks (kinks for authenticated user) then place it in myKinks of redux state
export const getMyKinks = () => async (dispatch, getState) => {
  // dispatch(asyncActionStart()); //removed so PromptPage doesn't flash on screen due to loading when authenticating
  try {
    const userID = getState().auth.currentUser.uid;
    const userRef = firestore.collection('usersPublic').doc(userID);
    const result = await userRef
      .collection('kinks')
      .doc('kinksTree')
      .get();
    const kinksTree = result.data();

    dispatch(updateMyKinksReduxState(kinksTree));
    // dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    // dispatch(asyncActionError(error));
  }
};

// export the myKinks in the redux state to the corresponding user's subcollection in firestore
export const submitMyKinks = () => async (dispatch, getState) => {
  dispatch(asyncActionStart());
  try {
    const kinksTree = getState().myKinks;
    const userID = getState().auth.currentUser.uid;

    // place it in user profile in usersPublic collection
    const userRef = firestore.collection('usersPublic').doc(userID);
    // kinksTree is in it's own collection because if it is placed in the base
    // user document then it absorbs the fields from base user document when updated
    await userRef
      .collection('kinks')
      .doc('kinksTree')
      .set({ ...kinksTree });

    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: 'Successfully updated My Kinks!'
      })
    );
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log({ error });
    dispatch(asyncActionError(error));
  }
};
