// convert to and from tags and prompts into their uri form
// we want to make sure uri uses hyphen (-) in place of space ( )

// convert the tag to its uri form
export const tagToUri = tag => {
  const tagMod = tag.replace(/\s/g, '-');
  return `/tag/${tagMod}`;
};

// convert the uri from props.match.params.tagID to its tag form
export const tagFromUri = uri => {
  const tag = uri.replace(/-/g, ' ');
  return tag;
};

// convert id and title of a prompt into uri for the prompt
// note that we modify the prompt title quite a bit
export const promptIdTitleToUri = (promptId, promptTitle) => {
  // 1. remove all reserved characters : / ? # [ ] @ ! $ & ' ( ) * + , ; =
  // 2. remove all unsafe characters " < > % { } | \ ^ `
  // 3. remove all periods (.) and tilde (~)
  // 4. replace all spaces with hyphen
  // 5. truncate the length to 50 characters or less
  // 6. make everything lowercase
  const promptTitleUri = promptTitle
    .replace(/[:/?#[\]@!$&'()*+,;=]+/g, '')
    .replace(/["<>%{}|\\^`]+/g, '')
    .replace(/[.~]/g, '')
    .replace(/\s+/g, '-')
    .substring(0, 50)
    .toLowerCase();
  return `/prompt/${promptId}/${promptTitleUri}`;
};
