// if prompt.published is false, then SaveDraftButton is shown
// otherwise, UnpublishButton is shown
import React from 'react';
// import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularLoadingButton from '../commoncomponents/CircularLoadingButton';
import {
  handleSaveDraftType,
  loadingType,
  textStringType,
  classesType,
  handleLoggedOutButtonClickType,
  authType
} from '../../types';

const styles = theme => ({
  // wrapper is needed to center the progress indicator with the button
  wrapper: {
    margin: theme.spacing(1), // gives it 8px margin around it
    position: 'relative' // relative allows progress indicator, which is absolute, to go inside it
  }
  // buttonRoot: {
  //   backgroundColor: theme.palette.primary.main,
  //   border: 0
  // }
});

const SaveDraftButton = ({
  classes,
  handleSaveDraft,
  loading,
  text,
  handleLoggedOutButtonClick,
  auth
}) => {
  return (
    <div className={classes.wrapper}>
      <Button
        // classes={{ root: classes.buttonRoot }}
        size="medium"
        variant="outlined"
        // color="primary"
        disabled={loading}
        onClick={
          auth && auth.authenticated
            ? handleSaveDraft(text)
            : handleLoggedOutButtonClick
        } // text is used to show a different snackbar upon completion
      >
        {text}
      </Button>
      {loading && <CircularLoadingButton />}
    </div>
  );
};

SaveDraftButton.propTypes = {
  handleSaveDraft: handleSaveDraftType.isRequired,
  loading: loadingType.isRequired,
  text: textStringType.isRequired,
  classes: classesType.isRequired,
  handleLoggedOutButtonClick: handleLoggedOutButtonClickType.isRequired,
  auth: authType.isRequired
};

const enhance = compose(withStyles(styles));

export default enhance(SaveDraftButton);
