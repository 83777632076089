// delete the prompt from firestore
// import { DELETE_PROMPT } from './types';
import { firestore } from '../firebase';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';
import { openSnackbar } from './snackbarActions';
import { DELETE_PROMPT } from './types';

export const deletePrompt = (
  promptID,
  promptTitle,
  deletingFromPromptsList = false
) => async dispatch => {
  dispatch(asyncActionStart());
  try {
    const promptRef = firestore.collection('prompts').doc(promptID);
    await promptRef.delete();
    dispatch(asyncActionFinish());
    const message = `Successfully deleted ${promptTitle}!`;
    dispatch(openSnackbar('SuccessSnackbar', { message }));
    // if deleting from promptslist, we need to remove the deleted prompt from the
    // list of prompts in the redux state
    if (deletingFromPromptsList) {
      dispatch({ type: DELETE_PROMPT, payload: { promptID } });
    }
  } catch (error) {
    const { code, message } = error;
    console.log(error);
    dispatch(asyncActionError({ code, message }));
    dispatch(openSnackbar('ErrorSnackbar', { message }));
  }
};
