// when a conversation is rendered, the last/newest message of that conversation is fetched
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import { formatDistance } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import TextEditorReadOnly from '../texteditor/TextEditorReadOnly';
import { reviveEditorStateAfterStorage } from '../../utils/convertEditorState';
import {
  messagesType,
  authType,
  talkingToType,
  classesType,
  participantDeletedType
} from '../../types';

const styles = theme => ({
  lastMessageMeta: { color: theme.palette.text.meta },
  // when it is 600px or less
  '@media (max-width: 37.5rem)': {
    lastMessageMeta: {
      fontSize: '14px'
    }
  }
});

const ConversationMessage = props => {
  const { messages, auth, talkingTo, classes, participantDeleted } = props;

  if (!isEmpty(messages)) {
    const message = Object.values(messages)[0];
    const isItMyMessage = auth.currentUser.uid === message.creatorID;
    const revivedEditorState = reviveEditorStateAfterStorage(
      message.messageText
    );
    return (
      <>
        <div>
          <TextEditorReadOnly text={revivedEditorState} />
        </div>
        <div>
          <Typography className={classes.lastMessageMeta}>
            {`${isItMyMessage ? 'to' : 'from'} ${
              participantDeleted ? '[deleted]' : talkingTo
            } sent ${formatDistance(
              message.createdAt.toDate(),
              new Date()
            )} ago`}
          </Typography>
        </div>
      </>
    );
  }
  return null;
};

ConversationMessage.propTypes = {
  messages: messagesType,
  auth: authType.isRequired,
  talkingTo: talkingToType.isRequired,
  classes: classesType.isRequired,
  participantDeleted: participantDeletedType
};

ConversationMessage.defaultProps = {
  messages: {},
  participantDeleted: null
};

export default withStyles(styles)(ConversationMessage);
