// ErrorBoundary is suppose to catch and display errors!
// NOTE that this is NOT TESTED, so NOT SURE IF IT WORKS!!!
// this is from the codepen: https://codepen.io/gaearon/pen/wqvxGa?editors=0010
import React, { Component } from 'react';
import { childrenType } from '../../types';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Ops... Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: childrenType.isRequired
};

export default ErrorBoundary;
