// randomly pick favorite kinks, yes kinks and no kinks
// for usersPublic/{userId}/kinks/kinksTree
import shuffle from 'lodash/shuffle';
import getRandomInt from './getRandomInt';
import transformKinksList from './transformKinksList';

// pick the maximum number of kinks for each type: favorite, yes and no
const maxFavoriteKinks = 5;
const maxYesKinks = 15;
const maxNoKinks = 0;

export default () => {
  const listOfKinksArray = Object.values(transformKinksList());
  // console.log({ listOfKinksArray });
  const listOfKinksArrayFlatten = listOfKinksArray.reduce(
    (allKinksAcc, category) => {
      const categoryArray = Object.keys(category).reduce(
        (categoryKinksAcc, kinkName) => {
          return [...categoryKinksAcc, kinkName];
        },
        []
      );
      return [...allKinksAcc, ...categoryArray];
    },
    []
  );
  // console.log({ listOfKinksArrayFlatten });
  const listOfKinksArrayFiltered = listOfKinksArrayFlatten.filter(kinkName => {
    return kinkName !== 'pov';
  });
  const shuffledListOfKinksArray = shuffle(listOfKinksArrayFiltered);
  // console.log({ shuffledListOfKinksArray });

  const numberOfFavoriteKinks = getRandomInt(0, maxFavoriteKinks);
  const numberOfYesKinks = getRandomInt(0, maxYesKinks);
  const numberOfNoKinks = getRandomInt(0, maxNoKinks);

  // console.log('numberOfFavoriteKinks', numberOfFavoriteKinks);
  // console.log('numberOfYesKinks', numberOfYesKinks);
  // console.log('numberOfNoKinks', numberOfNoKinks);

  const favoriteKinks = [];
  const yesKinks = [];
  const noKinks = [];

  let i = 0;
  while (i < numberOfFavoriteKinks) {
    const kink = shuffledListOfKinksArray.pop();
    favoriteKinks.push(kink);
    i += 1;
  }

  i = 0;
  while (i < numberOfYesKinks) {
    const kink = shuffledListOfKinksArray.pop();
    yesKinks.push(kink);
    i += 1;
  }

  i = 0;
  while (i < numberOfNoKinks) {
    const kink = shuffledListOfKinksArray.pop();
    noKinks.push(kink);
    i += 1;
  }

  console.log({ favoriteKinks });
  console.log('favoriteKinks.length', favoriteKinks.length);
  console.log({ yesKinks });
  console.log('yesKinks.length', yesKinks.length);
  console.log({ noKinks });
  console.log('noKinks.length', noKinks.length);

  const newKinksTree = transformKinksList();
  Object.keys(newKinksTree).forEach(categoryName => {
    const category = newKinksTree[categoryName];
    Object.keys(category).forEach(kinkName => {
      if (favoriteKinks.includes(kinkName)) {
        newKinksTree[categoryName][kinkName].number = 3;
      } else if (yesKinks.includes(kinkName)) {
        newKinksTree[categoryName][kinkName].number = 2;
      } else if (noKinks.includes(kinkName)) {
        newKinksTree[categoryName][kinkName].number = 1;
      }
    });
  });
  // console.log({ newKinksTree });
  return newKinksTree;
};
