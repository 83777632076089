// conversations - multiple conversations
import isEmpty from 'lodash/isEmpty';
import {
  UPDATE_CONVERSATIONS_REDUX_STATE,
  UPDATE_CONVERSATIONS_MESSAGES_REDUX_STATE,
  REMOVE_CONVERSATIONS_REDUX_STATE,
  GET_GENERATED_CONVO_MSGS,
  UPDATE_GENERATED_CONVO_MSG_REDUX_STATE,
  DELETE_GENERATED_CONVO_MSG_REDUX_STATE,
  APPROVE_GENERATED_CONVO_MSG_REDUX_STATE,
  DELETE_ALL_GENERATED_CONVO_MSGS_REDUX_STATE
} from '../actions/types'; // import the action type

const initialState = {
  lastConversation: null,
  myConversations: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CONVERSATIONS_REDUX_STATE: {
      const {
        myConversations,
        lastConversation,
        moreConversations,
        allConversations
      } = action.payload;
      return {
        myConversations,
        lastConversation,
        moreConversations,
        allConversations
      };
    }
    case UPDATE_CONVERSATIONS_MESSAGES_REDUX_STATE: {
      const { conversationID, messages } = action.payload;

      // add the messages to the corresponding conversation in conversations state in redux
      const newConversationObject = {
        ...state.myConversations[conversationID]
      };

      // check if the current conversation object is empty or not
      // if it is empty just return with state or the conversation object
      // will only have messages and nothing else
      // this fixes a race condition with removeConversationsInState()
      // without this when you go back and forth to conversations page,
      // the page will crash because it would have conversation objects
      // with only messages and nothing else
      if (isEmpty(newConversationObject)) {
        return state;
      }

      newConversationObject.messages = messages;
      return {
        ...state,
        myConversations: {
          ...state.myConversations,
          [conversationID]: newConversationObject
        }
      };
    }
    case REMOVE_CONVERSATIONS_REDUX_STATE: {
      return initialState;
    }
    case GET_GENERATED_CONVO_MSGS: {
      const { generatedConvoMsgs } = action.payload;
      return {
        ...state,
        generatedConvoMsgs
      };
    }
    // update the text (generated_text_mod) of a response in a generatedConvoMsg
    case UPDATE_GENERATED_CONVO_MSG_REDUX_STATE: {
      const {
        generatedConvoMsgId,
        responseCount,
        updatedText
      } = action.payload;
      const { generatedConvoMsgs } = state;
      const generatedConvoMsg = generatedConvoMsgs[generatedConvoMsgId];
      const { responseData } = generatedConvoMsg;
      const response = responseData[responseCount];

      const newState = {
        ...state,
        generatedConvoMsgs: {
          ...generatedConvoMsgs,
          [generatedConvoMsgId]: {
            ...generatedConvoMsg,
            responseData: {
              ...responseData,
              [responseCount]: {
                ...response,
                generated_text_mod: updatedText
              }
            }
          }
        }
      };
      return newState;
    }
    // approve a specific response in the generatedConvoMsg to be posted
    case APPROVE_GENERATED_CONVO_MSG_REDUX_STATE: {
      const { generatedConvoMsgId, responseCount } = action.payload;
      const { generatedConvoMsgs } = state;
      const generatedConvoMsg = generatedConvoMsgs[generatedConvoMsgId];

      const newState = {
        ...state,
        generatedConvoMsgs: {
          ...generatedConvoMsgs,
          [generatedConvoMsgId]: {
            ...generatedConvoMsg,
            approvedResponseCount: responseCount
          }
        }
      };
      return newState;
    }
    // delete the specific generatedConvoMsg object in redux state
    case DELETE_GENERATED_CONVO_MSG_REDUX_STATE: {
      const { generatedConvoMsgId } = action.payload;
      const { generatedConvoMsgs } = state;
      delete generatedConvoMsgs[generatedConvoMsgId];
      const newState = {
        ...state,
        generatedConvoMsgs: {
          ...generatedConvoMsgs
        }
      };
      return newState;
    }
    case DELETE_ALL_GENERATED_CONVO_MSGS_REDUX_STATE: {
      const newState = state;
      delete newState.generatedConvoMsgs;
      return newState;
    }
    default:
      return state;
  }
}
