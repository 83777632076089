// expand or retract each kink type tab in mykinks/MyKinks.js
// save the outcome to the redux state and the localStorage but
// does Not save to the firestore
// the corresponding redux state is retractKinks
import {
  EXPAND_KINK,
  RETRACT_KINK,
  EXPAND_KINK_ALL,
  RETRACT_KINK_ALL
} from './types';

export const expandKinkType = kinkType => async dispatch => {
  dispatch({ type: EXPAND_KINK, payload: { kinkType } });
};

export const retractKinkType = kinkType => async dispatch => {
  dispatch({ type: RETRACT_KINK, payload: { kinkType } });
};

export const expandAllKinkTypes = () => async dispatch => {
  dispatch({ type: EXPAND_KINK_ALL });
};

export const retractAllKinkTypes = () => async dispatch => {
  dispatch({ type: RETRACT_KINK_ALL });
};
