// fresh filter for filtering feedbacks

const freshFeedbacksFilter = {
  // the last feedback getFeedbacks fetched
  lastFeedback: null,
  // if next, get the next set of feedbacks
  // if previous, get the previous set of feedbacks
  previousOrNext: null,
  // pageNumber decides if we should show Previous button or not, and
  // shows what part of feedbacks list we are in i.e. (11 - 20 for pageNumber of 2)
  pageNumber: 1,
  // if there are no more prompts after this page then lastPage is true
  // it controls the rendering of Next button
  lastPage: false
};

export default freshFeedbacksFilter;
