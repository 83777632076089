// expand or retract the message text tab or the conversation text tab
// save the outcome to the redux state and the localStorage but
// does Not save to the firestore
// the corresponding redux state is retracted
import { EXPAND, RETRACT, EXPAND_ALL, RETRACT_ALL } from './types';

export const expandMessage = messageID => async dispatch => {
  dispatch({ type: EXPAND, payload: { messageID } });
};

export const retractMessage = messageID => async dispatch => {
  dispatch({ type: RETRACT, payload: { messageID } });
};

export const expandAllMessages = messages => async dispatch => {
  const messageIDs = Object.keys(messages);
  dispatch({ type: EXPAND_ALL, payload: { messageIDs } });
};

export const retractAllMessages = messages => async dispatch => {
  const messageIDs = Object.keys(messages);
  dispatch({ type: RETRACT_ALL, payload: { messageIDs } });
};
