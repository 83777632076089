// reducer for bookmarkListener and removeBookmarkListener in bookmarkPrompt.js action creators
import {
  LOVED,
  NOT_LOVED,
  REMOVE_LOVE_LISTENER,
  LOVE_BUTTON_CLICK_PENDING_START,
  LOVE_BUTTON_CLICK_PENDING_FINISH
} from '../actions/types';

const initialState = {
  listenerLoaded: false,
  loved: false,
  unsubscribe: null,
  loveButtonClickPending: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOVED: {
      return {
        ...state,
        listenerLoaded: true,
        loved: true,
        unsubscribe: action.payload.unsubscribe
      };
    }
    case NOT_LOVED:
      return {
        ...state,
        listenerLoaded: true,
        loved: false,
        unsubscribe: action.payload.unsubscribe
      };
    case REMOVE_LOVE_LISTENER:
      return {
        ...state,
        listenerLoaded: false,
        loved: false,
        unsubscribe: null
      };
    case LOVE_BUTTON_CLICK_PENDING_START:
      return { ...state, loveButtonClickPending: true };
    case LOVE_BUTTON_CLICK_PENDING_FINISH:
      return { ...state, loveButtonClickPending: false };
    default:
      return state;
  }
};
