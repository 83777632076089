import { IS_SMALL_SCREEN, NOT_SMALL_SCREEN } from '../actions/types';

const initialState = false;

export default (state = initialState, action) => {
  switch (action.type) {
    case IS_SMALL_SCREEN: {
      return true;
    }
    case NOT_SMALL_SCREEN: {
      return false;
    }
    default:
      return state;
  }
};
