// Rendering layer control (React Router)
import React, { Component, Suspense, lazy } from 'react';
// BrowserRouter tells React Router how to behave
// Route is a React Component that sets up a rule and displays the right component
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// connect helper allows the App component to access the redux store
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline'; // CSS Baseline from material-ui
import { MuiThemeProvider } from '@material-ui/core/styles';
import { fetchAuthUser } from '../actions'; // import all the action creators
import { customMuiTheme } from './appjscomponents/customMuiTheme';
import ScrollToTop from './appjscomponents/ScrollToTop';
import PrivateRoute from './appjscomponents/PrivateRoute';
import SmallScreenCheck from './appjscomponents/SmallScreenCheck';
import ErrorBoundary from './appjscomponents/ErrorBoundary';
import { fetchAuthUserType, unsubscribeFromAuthType } from '../types';

import './App.css';

// import all the components in
import Header from './header/Header';
import Footer from './footer/Footer';
import ModalManager from './modals/ModalManager';
import SnackbarManager from './snackbars/SnackbarManager';
import CircularLoading from './commoncomponents/CircularLoading';
// VERY IMPORTANT: DO NOT lazy load EditPromptPage or PromptPage will not display
// the prompt correctly. Probably because draft-js and/or TextEditorReadOnly does not load
import EditPromptPage from './editpromptpage/EditPromptPage'; // DO NOT LAZY LOAD EditPromptPage because it would mess with prompt's play on PromptPage!
// VERY IMPORTANT: DO NOT lazy load FrontPage or google bot will not be able to see the prompts
import FrontPage from './frontpage/FrontPage';
// Removed PromptPage from lazyloading / code splitting because it might be causing
// Google to NOT index prompt pages. Note that TagPage and UserPage are still lazy loaded!
// We are NOT sure if it'll impact it!
import PromptPage from './promptpage/PromptPage';

// const FrontPage = lazy(() => import('./frontpage/FrontPage'));
// const PromptPage = lazy(() => import('./promptpage/PromptPage'));
const UserPage = lazy(() => import('./userpage/UserPage'));
const TagPage = lazy(() => import('./tagpage/TagPage'));
const TagsPage = lazy(() => import('./tagspage/TagsPage'));
// const CategoryPage = lazy(() => import('./categorypage/CategoryPage'));
// const SearchPage = lazy(() => import('./searchpage/SearchPage'));
const SettingsPage = lazy(() => import('./settingspage/SettingsPage'));
const ProfilePage = lazy(() => import('./profilepage/ProfilePage'));
const ConversationsPage = lazy(() =>
  import('./conversationspage/ConversationsPage')
);
const ConversationPage = lazy(() =>
  import('./conversationpage/ConversationPage')
);
const InboxPage = lazy(() => import('./inboxpage/InboxPage'));
const GuidePage = lazy(() => import('./footerpages/GuidePage'));
const RulesPage = lazy(() => import('./footerpages/RulesPage'));
const ContactPage = lazy(() => import('./footerpages/ContactPage'));
const PrivacyPolicyPage = lazy(() => import('./footerpages/PrivacyPolicyPage'));
// const TermsAndConditionsPage = lazy(() =>
//   import('./footerpages/TermsAndConditionsPage')
// );
// const SearchConversationsPage = lazy(() =>
//   import('./searchconversationspage/SearchConversationsPage')
// );
const LoginPage = lazy(() => import('./loginpage/LoginPage'));
const SignUpPage = lazy(() => import('./signuppage/SignUpPage'));
const ForgotPasswordPage = lazy(() =>
  import('./forgotpasswordpage/ForgotPasswordPage')
);
// const TestPage = lazy(() => import('./testpage/TestPage'));
const AdminPage = lazy(() => import('./adminpage/AdminPage'));
const NotFoundPage = lazy(() => import('./notfoundpage/NotFoundPage'));

class App extends Component {
  componentDidMount = () => {
    // this.unsubscribeFromAuth = await this.props.fetchAuthUser();
    this.props.fetchAuthUser();
  };

  // NOTE: pretty sure unmount is not even called
  componentWillUnmount = () => {
    console.log('unmounting');
    this.props.unsubscribeFromAuth();
  };

  render() {
    // exact is equivalent to exact={true}, makes the path match exactly
    // because <Header /> is above all the routes, it will always be visible
    return (
      <ErrorBoundary>
        <BrowserRouter>
          <ScrollToTop>
            <MuiThemeProvider theme={customMuiTheme}>
              <div className="App">
                <CssBaseline />
                <ModalManager />
                <SnackbarManager />
                <Header />
                <SmallScreenCheck />
                <Suspense fallback={<CircularLoading />}>
                  <Switch>
                    <Route exact path="/" component={FrontPage} />
                    <Route
                      path="/prompt/:promptID/:promptTitleUri"
                      component={PromptPage}
                    />
                    <Route path="/user/:username" component={UserPage} />
                    <PrivateRoute path="/settings" component={SettingsPage} />
                    <PrivateRoute path="/profile" component={ProfilePage} />
                    <Route path="/tag/:tagID" component={TagPage} />
                    <Route path="/tags" component={TagsPage} />
                    {/* <Route
                    path="/category/:categoryID"
                    component={CategoryPage}
                  /> */}
                    {/* <Route path="/type/:typeID" component={TypePage} /> */}
                    {/* <Route path="/search/:searchID" component={SearchPage} /> */}
                    <Route
                      path="/conversations"
                      component={ConversationsPage}
                    />
                    <Route
                      path="/conversation/:conversationID"
                      component={ConversationPage}
                    />
                    <Route path="/inbox" component={InboxPage} />
                    <Route path="/create" component={EditPromptPage} />
                    <Route path="/edit/:promptID" component={EditPromptPage} />

                    <Route path="/guide" component={GuidePage} />
                    <Route path="/rules" component={RulesPage} />
                    <Route path="/contact" component={ContactPage} />
                    <Route
                      path="/privacypolicy"
                      component={PrivacyPolicyPage}
                    />
                    {/*
                    <Route
                      path="/termsandconditions"
                      component={TermsAndConditionsPage}
                    /> */}
                    {/* <Route
                    path="/search_conversations/:searchID"
                    component={SearchConversationsPage}
                  /> */}
                    <Route path="/login" component={LoginPage} />
                    <Route path="/signup" component={SignUpPage} />
                    <Route
                      path="/forgotpassword"
                      component={ForgotPasswordPage}
                    />
                    {/* <Route path="/test" component={TestPage} /> */}
                    <Route path="/admin" component={AdminPage} />
                    <Route component={NotFoundPage} status={404} />
                  </Switch>
                </Suspense>
                <Footer />
              </div>
            </MuiThemeProvider>
          </ScrollToTop>
        </BrowserRouter>
      </ErrorBoundary>
    );
  }
}

App.propTypes = {
  fetchAuthUser: fetchAuthUserType.isRequired,
  unsubscribeFromAuth: unsubscribeFromAuthType
};

App.defaultProps = {
  unsubscribeFromAuth: null
};

const mapStateToProps = ({ auth: { unsubscribeFromAuth } }) => ({
  unsubscribeFromAuth
});

// use connect to allow App to access Action Creators
// the 2nd parameter for connect is mapDispatchToProps
// actions are assigned to App as props
export default connect(mapStateToProps, { fetchAuthUser })(App);
