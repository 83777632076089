// Share Button is removed because it would break PromptSidebar's position: sticky
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import GoToTopBottomButton from '../commoncomponents/GoToTopBottomButton';
import PromptHeaderButton from './PromptHeaderButton';
import {
  classesType,
  promptButtomMenuElementType,
  promptType,
  promptHeaderButtonVisibilityType,
  isSmallScreenBoolType,
  headerVisibilityType
} from '../../types';

const styles = {
  root: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  emptyElement: {
    // the height of PromptHeaderButton. We gave it some height so
    // the space doesn't suddenly expand when the PromptHeaderButton is rendered
    height: '3.75rem' // 60px, the height of PromptHeaderButton
  },
  upDownButtons: {}
};

const PromptSidebarBottom = ({
  classes,
  promptButtomMenuElement,
  prompt,
  promptHeaderButtonVisibility,
  headerVisibility,
  isSmallScreen
}) => {
  // we render an empty element when PromptHeadButton is not rendered so
  // the up and down doesn't doesn't go to left
  // we give it some extra height so the space on the left doesn't suddenly expand
  // when the PromptHeaderButton is rendered
  return (
    <div className={classes.root}>
      {!promptHeaderButtonVisibility && !headerVisibility ? (
        <PromptHeaderButton prompt={prompt} />
      ) : (
        <div className={classes.emptyElement} />
      )}
      {!isSmallScreen && (
        <div className={classes.upDownButtons}>
          <GoToTopBottomButton
            goToElement={promptButtomMenuElement}
            pageName="PromptPage"
            buttonText={<ArrowDownwardIcon />}
            buttonVariant="text"
          />
          <GoToTopBottomButton
            scrollToTop
            pageName="PromptPage"
            buttonText={<ArrowUpwardIcon />}
            buttonVariant="text"
          />
        </div>
      )}
    </div>
  );
};

PromptSidebarBottom.propTypes = {
  classes: classesType.isRequired,
  promptButtomMenuElement: promptButtomMenuElementType,
  prompt: promptType.isRequired,
  promptHeaderButtonVisibility: promptHeaderButtonVisibilityType.isRequired,
  isSmallScreen: isSmallScreenBoolType.isRequired,
  headerVisibility: headerVisibilityType.isRequired
};

PromptSidebarBottom.defaultProps = {
  promptButtomMenuElement: null
};

export default withStyles(styles)(PromptSidebarBottom);
