import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux'; // compose from lodash also works
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';
import {
  openModal,
  logout,
  isHeaderMobileVisible,
  isHeaderDesktopVisible
} from '../../actions';
import numberOfConvosWithNewMsg from '../../utils/numberOfConvosWithNewMsg';
import {
  openModalType,
  logoutType,
  authType,
  newStuffAlertsType,
  isHeaderMobileVisibleType,
  isHeaderDesktopVisibleType
} from '../../types';

class Header extends Component {
  state = { mobileMenuAnchor: null, desktopMenuAnchor: null };

  handleLogIn = () => {
    this.props.openModal('LogInModal');
  };

  handleSignUp = () => {
    this.props.openModal('SignUpModal');
  };

  handleLogOut = () => {
    this.setState(
      { mobileMenuAnchor: null, desktopMenuAnchor: null },
      // goes to the new uri through a callback function (calls after state is set)
      // callback is not really needed, just wanted to try out setState callbacks
      () => this.props.logout()
    );
  };

  handleCloseMenu = () => {
    // closes the mobile menu and the desktop menu
    this.setState({ mobileMenuAnchor: null, desktopMenuAnchor: null });
  };

  toggleDesktopMenu = event => {
    const { desktopMenuAnchor } = this.state;
    if (desktopMenuAnchor === null) {
      this.setState({ desktopMenuAnchor: event.currentTarget });
    } else if (desktopMenuAnchor !== null) {
      this.setState({ desktopMenuAnchor: null });
    }
  };

  // opens the mobile menu by changing the mobileMenuAnchor from null to something else
  // toggles the mobile menu by changing this.state.mobileMenuAnchor
  // if mobileMenuAnchor is null (currently menu is closed) then open the menu
  // if mobileMenuAnchor is Not null (currently menu is open) then close the menu by changing the mobileMenuAnchor to null
  toggleMobileMenu = event => {
    const { mobileMenuAnchor } = this.state;
    if (mobileMenuAnchor === null) {
      this.setState({ mobileMenuAnchor: event.currentTarget });
    } else if (mobileMenuAnchor !== null) {
      this.setState({ mobileMenuAnchor: null });
    }
  };

  // renders content depending on rather the user is logged in or not
  // also depending on rather the screen size (window.innerWidth) of user's device
  renderContent() {
    const { mobileMenuAnchor, desktopMenuAnchor } = this.state;
    const { auth, newStuffAlerts } = this.props;

    switch (auth.authenticated) {
      // when the login status is still pending return nothing
      case null:
        return null;

      // when the user is not logged in, show them a logged Out menu
      case false:
        // if the screen size is greater than 600px (37.5rem), show them the desktop menu
        if (window.innerWidth > 600) {
          return (
            <HeaderDesktop
              loggedIn={false}
              handleCloseMenu={this.handleCloseMenu}
              handleSignUp={this.handleSignUp}
              handleLogIn={this.handleLogIn}
              toggleDesktopMenu={this.toggleDesktopMenu}
              desktopMenuAnchor={desktopMenuAnchor}
              isHeaderDesktopVisible={this.props.isHeaderDesktopVisible}
            />
          );
        }
        // if the screen size is less or equal to 600px (37.5rem) show them the mobile menu
        return (
          <HeaderMobile
            loggedIn={false}
            toggleMobileMenu={this.toggleMobileMenu}
            mobileMenuAnchor={mobileMenuAnchor}
            handleCloseMenu={this.handleCloseMenu}
            handleSignUp={this.handleSignUp}
            handleLogIn={this.handleLogIn}
            isHeaderMobileVisible={this.props.isHeaderMobileVisible}
          />
        );

      // when the user is logged in, show them a logged In menu
      default: {
        // get the number of conversations with new messages:
        let newConvoMsgCount = 0;
        if (newStuffAlerts && newStuffAlerts.newConversationMessageAlert) {
          newConvoMsgCount = numberOfConvosWithNewMsg(
            newStuffAlerts.newConversationMessageAlert
          );
        }
        // if the screen size is greater than 600px (37.5rem), show them the desktop menu
        if (window.innerWidth > 600) {
          return (
            <HeaderDesktop
              loggedIn
              handleCloseMenu={this.handleCloseMenu}
              handleLogOut={this.handleLogOut}
              auth={auth}
              toggleDesktopMenu={this.toggleDesktopMenu}
              desktopMenuAnchor={desktopMenuAnchor}
              newConvoMsgCount={newConvoMsgCount}
              isHeaderDesktopVisible={this.props.isHeaderDesktopVisible}
            />
          );
        }
        // if the screen size is less or equal to 600px (37.5rem) show them the mobile menu
        return (
          <HeaderMobile
            loggedIn
            toggleMobileMenu={this.toggleMobileMenu}
            mobileMenuAnchor={mobileMenuAnchor}
            handleCloseMenu={this.handleCloseMenu}
            handleLogOut={this.handleLogOut}
            newConvoMsgCount={newConvoMsgCount}
            isHeaderMobileVisible={this.props.isHeaderMobileVisible}
            auth={auth}
          />
        );
      }
    }
  }

  render() {
    return <div className="MainHeader">{this.renderContent()}</div>;
  }
}

Header.propTypes = {
  openModal: openModalType.isRequired,
  logout: logoutType.isRequired,
  auth: authType.isRequired,
  newStuffAlerts: newStuffAlertsType.isRequired,
  isHeaderMobileVisible: isHeaderMobileVisibleType.isRequired,
  isHeaderDesktopVisible: isHeaderDesktopVisibleType.isRequired
};

function mapStateToProps({ auth, newStuffAlerts }) {
  return { auth, newStuffAlerts };
}

const actions = {
  openModal,
  logout,
  isHeaderMobileVisible,
  isHeaderDesktopVisible
};

// creating enhance with compose is the same as putting them together
// with on a single line (last commented line). Reference: https://reactjs.org/docs/higher-order-components.html
const enhance = compose(connect(mapStateToProps, actions));
export default enhance(Header);
