// transform listOfKinks.js into an object with points that is usable
// transforms it into something like exampleKinks.OLD.js, see example output below
import camelCase from 'lodash/camelCase';
import listOfKinks from './listOfKinks';

// this function get extracts info from a single kink category
const getInfoFromKinkCategory = kinkCategory => {
  const { pov, kinks } = kinkCategory;
  const result = {};
  kinks.forEach(kink => {
    if (pov.length === 2) {
      const selfKey = camelCase(`${kink} ${pov[0]}`);
      const partnerKey = camelCase(`${kink} ${pov[1]}`);
      result[selfKey] = { kink, number: 0, to: pov[0] };
      result[partnerKey] = { kink, number: 0, to: pov[1] };
    } else if (pov.length === 1) {
      const generalKey = camelCase(`${kink} ${pov[0]}`);
      result[generalKey] = { kink, number: 0, to: pov[0] };
    }
  });
  result.pov = pov;
  return result;
};

// exported script that creates the state tree object of all kink categories
export default () => {
  const kinksListTree = {};
  Object.values(listOfKinks).forEach(kinkCategory => {
    kinksListTree[kinkCategory.category] = getInfoFromKinkCategory(
      kinkCategory
    );
  });
  return kinksListTree;
};

// example input:
// restrictive: {
//   category: 'restrictive',
//   pov: ['self', 'partner'],
//   kinks: [
//     'gag',
//     'collar',
//     'leash',
//     'chastity',
//     'light bondage',
//     'heavy bondage',
//     'encasement'
//   ]
// }

// example output:
// restrictive: {
//   pov: ['self', 'partner'],
//   gagSelf: { number: 0, to: 'self', kink: 'gag' },
//   gagPartner: { number: 0, to: 'partner', kink: 'gag' },
//   collarSelf: { number: 0, to: 'self', kink: 'collar' },
//   collarPartner: { number: 0, to: 'partner', kink: 'collar' },
//   leashSelf: { number: 0, to: 'self', kink: 'leash' },
//   leashPartner: { number: 0, to: 'partner', kink: 'leash' },
//   chastitySelf: { number: 0, to: 'self', kink: 'chastity' },
//   chastityPartner: { number: 0, to: 'partner', kink: 'chastity' },
//   bondageSelf: { number: 0, to: 'self', kink: 'bondage' },
//   bondagePartner: { number: 0, to: 'partner', kink: 'bondage' },
//   encasementSelf: { number: 0, to: 'self', kink: 'encasement' },
//   encasementPartner: { number: 0, to: 'partner', kink: 'encasement' }
// }
