// opened by handleBlockNavigation() of editpromptpage/EditPromptPage.js
// 3 buttons: cancel the navigation, discard the changes and continue to next location
// or save the changes and continue to next location
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { closeModal } from '../../actions';
import {
  closeModalType,
  handleGoToNextLocationType,
  handleSaveDraftType,
  handleUpdatePromptType,
  publishedType,
  classesType
} from '../../types';

const styles = {
  buttons: {
    paddingBottom: '1rem'
  }
};

class UnsavedChangesModal extends Component {
  handleDiscardChanges = () => {
    this.props.handleGoToNextLocation();
    this.props.closeModal();
  };

  handleSaveButton = () => {
    const { published, handleSaveDraft, handleUpdatePrompt } = this.props;
    if (published) {
      // save prompt
      handleUpdatePrompt();
    } else {
      // save draft
      handleSaveDraft('Save Draft')();
    }
    this.props.handleGoToNextLocation();
    this.props.closeModal();
  };

  render() {
    const { published, classes } = this.props;
    return (
      <Dialog
        open
        onClose={this.props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="form-dialog-title">Unsaved Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to save these changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button
            onClick={this.props.closeModal}
            // color="primary"
            // variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={this.handleDiscardChanges}
            // color="primary"
            variant="outlined"
          >
            Discard Changes
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleSaveButton}
          >
            {published ? 'Save Update' : 'Save Draft'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

UnsavedChangesModal.propTypes = {
  closeModal: closeModalType.isRequired,
  handleGoToNextLocation: handleGoToNextLocationType.isRequired,
  handleSaveDraft: handleSaveDraftType.isRequired,
  handleUpdatePrompt: handleUpdatePromptType.isRequired,
  published: publishedType.isRequired,
  classes: classesType.isRequired
};

const actions = { closeModal };

const enhance = compose(connect(null, actions), withStyles(styles));

export default enhance(UnsavedChangesModal);
