// this modal is used to flag the user. used in userpage/FlagUser.js
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { flagUser, closeModal } from '../../actions';
import {
  flagUserType,
  closeModalType,
  userIDType,
  classesType
} from '../../types';

const styles = {
  comments: {
    '& label.Mui-focused': {
      color: 'black' // keep the field label black after you've focused on the field
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black' // keep the field underline black after you've focused on the field
    }
  }
};

class FlagUserModal extends Component {
  state = {
    reason: '',
    comment: ''
  };

  handleSelection = event => {
    this.setState({ reason: event.target.value });
  };

  handleComment = event => {
    this.setState({ comment: event.target.value });
  };

  // submits the selected reason from radio button and the comments
  handleSubmit = () => {
    const { reason, comment } = this.state;
    const { userID } = this.props;
    const flagObject = { userID, reason, comment };
    this.props.flagUser(flagObject);
    this.props.closeModal();
  };

  render() {
    return (
      <>
        <Dialog
          open
          onClose={this.props.closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="form-dialog-title">Report User</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {"We're sorry something's wrong. How can we help?"}
              {/* that dialogue is from reddit report, change it to something original */}
            </DialogContentText>
            <RadioGroup
              aria-label="reasons"
              name="reasons"
              value={this.state.reason}
              onChange={this.handleSelection}
            >
              <FormControlLabel
                value="spam"
                control={<Radio color="default" />}
                label="Spam"
              />
              <FormControlLabel
                value="harrassment"
                control={<Radio color="default" />}
                label="Harrassment or abusive content"
              />
              <FormControlLabel
                value="underage"
                control={<Radio color="default" />}
                label="Underage content or role"
              />
              <FormControlLabel
                value="money"
                control={<Radio color="default" />}
                label="Offering or soliciting money"
              />
              <FormControlLabel
                value="image/link"
                control={<Radio color="default" />}
                label="Inappropriate image or link"
              />
              <FormControlLabel
                value="copying"
                control={<Radio color="default" />}
                label="Copying someone else's content"
              />
              <FormControlLabel
                value="other"
                control={<Radio color="default" />}
                label="Other reason"
              />
            </RadioGroup>
            <TextField
              id="Additional Comments"
              label="Additional Comments"
              type="text"
              multiline
              rows={3}
              fullWidth
              value={this.state.comment}
              onChange={this.handleComment}
              className={this.props.classes.comments}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.closeModal}
              // color="primary"
              // variant="contained"
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleSubmit}
              color="primary"
              variant="contained"
              disabled={!this.state.reason}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

FlagUserModal.propTypes = {
  flagUser: flagUserType.isRequired,
  closeModal: closeModalType.isRequired,
  userID: userIDType.isRequired,
  classes: classesType.isRequired
};

const actions = { flagUser, closeModal };

export default withStyles(styles)(connect(null, actions)(FlagUserModal));
