import firebase, { firestore } from '../firebase';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';
import {
  GET_INBOX_SENT_MESSAGES,
  GET_INBOX_RECEIVED_MESSAGES,
  UPDATE_CONVERSATIONS_MESSAGES_REDUX_STATE,
  UPDATE_CONVERSATION_MESSAGES_REDUX_STATE
} from './types';
import { openSnackbar } from './snackbarActions';
import {
  prepareEditorStateForStorage,
  reviveEditorStateAfterStorage
} from '../utils/convertEditorState';

// get the last 10 messages the auth'd user has received
// getNewMessages is true when the page is newly loaded or refreshed or when refresh button is pressed
// getNewMessages is false when the page is at the bottom
// we want to place the new messages at the top of the receivedMessages object
// and place the old messages at the bottom of the receivedMessages object
export const getInboxReceivedMessages = getNewMessages => async (
  dispatch,
  getState
) => {
  try {
    const user = getState().auth.currentUser;
    const userID = user.uid;
    const currentReceivedMessages = getState().inbox.receivedMessages;

    const receivedMessagesQuery = firestore
      .collection('usersPrivate')
      .doc(userID)
      .collection('receivedMessages')
      .orderBy('createdAt', 'desc')
      .limit(10);

    // get the lastReceivedMessage from state, note that this is
    // last fetched receivedMessage and NOT the last message received
    const currentLastReceivedMessage = getState().inbox.lastReceivedMessage;

    let modReceivedMessagesQuery;
    // if we are grabbing the new messages, do not modify the query
    // if we are getting old messages, modify the query to start after the last message fetched
    if (getNewMessages) {
      modReceivedMessagesQuery = receivedMessagesQuery;
    } else if (currentLastReceivedMessage) {
      modReceivedMessagesQuery = receivedMessagesQuery.startAfter(
        currentLastReceivedMessage
      );
    }

    const receivedMessagesSnapshot = await modReceivedMessagesQuery.get();
    const receivedMessages = {};
    receivedMessagesSnapshot.docs.forEach(doc => {
      const data = doc.data();
      const { body } = data;
      const revivedEditorState = reviveEditorStateAfterStorage(body);
      receivedMessages[doc.id] = { ...data, body: revivedEditorState };
    });

    console.log({ receivedMessages });

    // merge the receivedMessages already in state (currentReceivedMessages) with
    // the receivedMessages (receivedMessages) just fetched
    // if the fetched messages are newly created, put them in front of current messages
    // if the fetched messages are old (previously created), put them after the current messages
    let modReceivedMessages;
    if (getNewMessages) {
      modReceivedMessages = { ...receivedMessages, ...currentReceivedMessages };
    } else {
      modReceivedMessages = {
        ...currentReceivedMessages,
        ...receivedMessages
      };
    }

    let lastReceivedMessage;
    let moreReceivedMessages;
    // if getNewMessages is true and currentLastReceivedMessage already exists
    // then DO NOT update lastReceivedMessage and moreReceivedMessages variables
    // it means that we are fetching the latest receivedMessages by hitting the
    // Refresh button or visiting the page again
    if (getNewMessages && currentLastReceivedMessage) {
      lastReceivedMessage = currentLastReceivedMessage;
      const currentMoreReceivedMessages = getState().inbox.moreReceivedMessages;
      moreReceivedMessages = currentMoreReceivedMessages;
    } else {
      // get the lastReceivedMessage from the recently fetched receivedMessages
      // this means that we just loaded the page or has reached the bottom of page
      lastReceivedMessage =
        receivedMessagesSnapshot.docs[receivedMessagesSnapshot.docs.length - 1];

      // see if there are any more receivedMessages left in the firestore
      if (receivedMessagesSnapshot.docs.length === 10) {
        moreReceivedMessages = true;
      } else {
        moreReceivedMessages = false;
      }
    }

    dispatch({
      type: GET_INBOX_RECEIVED_MESSAGES,
      payload: {
        receivedMessages: modReceivedMessages,
        lastReceivedMessage,
        moreReceivedMessages
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// get the last 10 messages the auth'd user has sent out
// getNewMessages is true when the page is newly loaded or refreshed or when refresh button is pressed
// getNewMessages is false when the page is at the bottom
// we want to place the new messages at the top of the sentMessages object
// and place the old messages at the bottom of the sentMessages object
export const getInboxSentMessages = getNewMessages => async (
  dispatch,
  getState
) => {
  try {
    const user = getState().auth.currentUser;
    const userID = user.uid;
    const currentSentMessages = getState().inbox.sentMessages;

    const sentMessagesQuery = firestore
      .collection('usersPrivate')
      .doc(userID)
      .collection('sentMessages')
      .orderBy('createdAt', 'desc')
      .limit(10);

    // get the lastSentMessage from state, note that this is
    // last fetched sentMessage and NOT the last message sent
    const currentLastSentMessage = getState().inbox.lastSentMessage;
    let modSentMessagesQuery;
    // if we are grabbing the new messages, do not modify the query
    // if we are getting old messages, modify the query to start after the last message fetched
    if (getNewMessages) {
      modSentMessagesQuery = sentMessagesQuery;
    } else if (currentLastSentMessage) {
      modSentMessagesQuery = sentMessagesQuery.startAfter(
        currentLastSentMessage
      );
    }

    const sentMessagesSnapshot = await modSentMessagesQuery.get();
    const sentMessages = {};
    sentMessagesSnapshot.docs.forEach(doc => {
      const data = doc.data();
      const { body } = data;
      const revivedEditorState = reviveEditorStateAfterStorage(body);
      sentMessages[doc.id] = { ...data, body: revivedEditorState };
    });

    console.log({ sentMessages });

    // merge the sentMessages already in state (currentSentMessages) with
    // the sentMessages (sentMessages) just fetched
    // if the fetched messages are newly created, put them in front of current messages
    // if the fetched messages are old (previously created), put them after the current messages
    let modSentMessages;
    if (getNewMessages) {
      modSentMessages = { ...sentMessages, ...currentSentMessages };
    } else {
      modSentMessages = {
        ...currentSentMessages,
        ...sentMessages
      };
    }

    let lastSentMessage;
    let moreSentMessages;
    // if getNewMessages is true and currentLastSentMessage already exists
    // then DO NOT update lastSentMessage and moreSentMessages variables
    if (getNewMessages && currentLastSentMessage) {
      lastSentMessage = currentLastSentMessage;
      const currentMoreSentMessages = getState().inbox.moreSentMessages;
      moreSentMessages = currentMoreSentMessages;
    } else {
      // get the lastSentMessage from the recently fetched sentMessages
      lastSentMessage =
        sentMessagesSnapshot.docs[sentMessagesSnapshot.docs.length - 1];

      // see if there are any more sentMessages left in the firestore
      if (sentMessagesSnapshot.docs.length === 10) {
        moreSentMessages = true;
      } else {
        moreSentMessages = false;
      }
    }

    dispatch({
      type: GET_INBOX_SENT_MESSAGES,
      payload: {
        sentMessages: modSentMessages,
        lastSentMessage,
        moreSentMessages
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// this function will add the message to the sending user's sentMessages collection
// handleSendInboxMessage() cloud function will add the message to the receiving user's
// recievedMessages collection
export const createInboxMessage = inboxMessageObject => async (
  dispatch,
  getState
) => {
  dispatch(asyncActionStart());
  try {
    const user = getState().auth.currentUser;
    const username = user.displayName;
    const usernameLower = username.toLowerCase();
    const userID = user.uid;

    const { to, subject, body } = inboxMessageObject;
    const preparedBody = prepareEditorStateForStorage(body);
    const toLower = to.toLowerCase();
    // get the ID of the receiver
    const receiverQuery = firestore
      .collection('usersPublic')
      .where('usernameLower', '==', toLower);
    const receiverSnapshot = await receiverQuery.get();
    if (receiverSnapshot.empty) {
      const notFoundError = new Error(
        'Unable to find an user with that username!'
      );
      notFoundError.name = 'not found';
      throw notFoundError;
    }
    const receiverID = receiverSnapshot.docs[0].id;

    const sentMessageRef = firestore
      .collection('usersPrivate')
      .doc(userID)
      .collection('sentMessages');

    const message = {
      sender: username,
      senderLower: usernameLower,
      senderID: userID,
      receiver: to,
      receiverLower: toLower,
      receiverID,
      subject,
      body: preparedBody,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      unread: true
    };
    const result = await sentMessageRef.add(message);

    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: 'successfully sent the message'
      })
    );
    dispatch(asyncActionFinish());
    return result;
  } catch (error) {
    const { name, message } = error;
    dispatch(asyncActionError({ name, message }));
    dispatch(openSnackbar('ErrorSnackbar', { message }));
    return null;
  }
};

// create a new message for that conversation
export const createConversationMessage = (conversationID, text) => async (
  dispatch,
  getState
) => {
  dispatch(asyncActionStart());
  try {
    const preparedText = prepareEditorStateForStorage(text);
    const { currentUser } = getState().auth;

    const messageRef = firestore
      .collection('conversations')
      .doc(conversationID)
      .collection('messages');
    const result = await messageRef.add({
      conversationID,
      creator: currentUser.displayName,
      creatorID: currentUser.uid,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      messageText: preparedText
    });
    const conversationMessageID = result.id;

    // reset streakTimeLeft field in conversation and check if the conversation
    // gains a steak or not

    dispatch(asyncActionFinish());
    return conversationMessageID;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    return null;
  }
};

// getConversationMessages() is used to fetch one newest message for each conversation in ConversationsPage.js
// parameters:
// messageOrder is the order of message fetched by creation date (desc or asc)
// messageNumber is the number of messages fetched
export const getConversationsMessages = (
  conversationID,
  messageOrder,
  messageNumber
) => async dispatch => {
  try {
    const messagesRef = firestore
      .collection('conversations')
      .doc(conversationID)
      .collection('messages')
      .orderBy('createdAt', messageOrder) // get the message by creation date (desc or asc)
      .limit(messageNumber); // get a specific number of messages (such as 1)
    const messagesSnapshot = await messagesRef.get();
    const messages = {};
    messagesSnapshot.docs.forEach(doc => {
      const messageID = doc.id;
      messages[messageID] = { ...doc.data() };
    });

    dispatch({
      type: UPDATE_CONVERSATIONS_MESSAGES_REDUX_STATE,
      payload: { conversationID, messages }
    });
  } catch (error) {
    console.log(error);
  }
};

// //CURRENT NOT USED ANYWHERE! we use conversationMessagesListener instead!
// // used by LoadOlderMessagesButton.js to get next set of messages for the conversation
// // after getting the next set of messages, add them to the the messages object in conversation redux state
// export const getConversationMessages = convoMessagesFilter => async (
//   dispatch,
//   getState
// ) => {
//   const {
//     conversationID,
//     messageOrder,
//     messageNumber,
//     lastMessage
//   } = convoMessagesFilter;
//   try {
//     const preMessagesRef = firestore
//       .collection('conversations')
//       .doc(conversationID)
//       .collection('messages')
//       .orderBy('createdAt', messageOrder) // get the message by creation date (desc or asc)
//       .limit(messageNumber); // get a specific number of messages (such as 1)
//
//     let messagesRef;
//     if (lastMessage) {
//       messagesRef = preMessagesRef.startAfter(lastMessage);
//     } else {
//       messagesRef = preMessagesRef;
//     }
//     const messagesSnapshot = await messagesRef.get();
//     const messages = {};
//     messagesSnapshot.docs.forEach(doc => {
//       const messageID = doc.id;
//       messages[messageID] = { ...doc.data(), messageID };
//     });
//     const newLastMessage =
//       messagesSnapshot.docs[messagesSnapshot.docs.length - 1];
//
//     const {
//       messages: oldMessages,
//       unsubscribeToMessagesListener,
//       messagesListenerLoaded
//     } = getState().conversation;
//     const newMessages = { ...oldMessages, ...messages };
//
//     dispatch({
//       type: UPDATE_CONVERSATION_MESSAGES_REDUX_STATE,
//       payload: {
//         messages: newMessages,
//         unsubscribeToMessagesListener,
//         messagesListenerLoaded,
//         convoMessagesFilter: {
//           ...convoMessagesFilter,
//           lastMessage: newLastMessage
//         }
//       }
//     });
//   } catch (error) {
//     console.log(error);
//   }
// };

// this is used to listen for new messages on ConversationPage.js
export const conversationMessagesListener = convoMessagesFilter => async (
  dispatch,
  getState
) => {
  const {
    conversationID,
    messageOrder,
    messageNumber,
    lastMessage
  } = convoMessagesFilter;
  try {
    const conversationRef = firestore
      .collection('conversations')
      .doc(conversationID)
      .collection('messages')
      .orderBy('createdAt', messageOrder) // get the message by creation date (desc or asc)
      .limit(messageNumber); // get a specific number of messages (such as 1)

    // if lastMessage from convoMessagesFilter exists then add the lastMessage
    // to the end of the query
    let modConversationRef;
    if (lastMessage) {
      modConversationRef = conversationRef.startAfter(lastMessage);
    } else {
      modConversationRef = conversationRef;
    }

    const newMessages = {};
    const unsubscribeToMessagesListener = await modConversationRef.onSnapshot(
      snapshot => {
        // if the number of docs equal the max number of messages fetched then
        // there might still be more messages in the firestore for this convo
        let moreOlderMessages = false;
        if (snapshot.docs.length === messageNumber) {
          moreOlderMessages = true;
        }

        // check if lastMessage exists in conversation.convoMessagesFilter
        // if lastMessage exists then assign it to oldLastMessage
        let oldLastMessage = null;
        const { conversation } = getState();
        if (conversation.convoMessagesFilter) {
          if (conversation.convoMessagesFilter.lastMessage) {
            oldLastMessage = conversation.convoMessagesFilter.lastMessage;
          }
        }
        // if oldLastMessage does Not exist and lastMessage from from convoMessagesFilter exists,
        // it means that we are calling conversationMessagesListener()
        // for the first time, i.e. when ConversationPage mounts for the first time!
        // if oldLastMessage does exist and lastMessage from convoMessagesFilter exists,
        // it means that we are calling conversationMessagesListener() from LoadOlderMessagesButton.js
        // this also means we need to fetch the new lastMessage
        // otherwise, it means that the chat partner has submitted a new message and
        // we are just responding to the change, so we Don't update the lastMessage
        let newLastMessage;
        if (!oldLastMessage || lastMessage) {
          newLastMessage = snapshot.docs[snapshot.docs.length - 1];
          // console.log('getting new lastMessage', newLastMessage);
        } else {
          newLastMessage = oldLastMessage;
          // console.log('using old lastMessage', newLastMessage);
        }
        snapshot.docChanges().forEach(change => {
          // recently submitted message (hasPendingWrites === true) has no createdAt field
          // so we don't add it to the newMessages or it would break
          // we can also use hasPendingWrites to see if the sender of message is the
          // client itself or from the server
          if (change.doc.metadata.hasPendingWrites === false) {
            const messageID = change.doc.id;
            newMessages[messageID] = { ...change.doc.data(), messageID };
          }
        });

        // get the old messages from redux
        const { messages } = getState().conversation;

        dispatch({
          type: UPDATE_CONVERSATION_MESSAGES_REDUX_STATE,
          payload: {
            messages: { ...messages, ...newMessages },
            unsubscribeToMessagesListener,
            messagesListenerLoaded: true,
            convoMessagesFilter: {
              ...convoMessagesFilter,
              lastMessage: newLastMessage,
              moreOlderMessages
            }
          }
        });
      }
    );
  } catch (error) {
    console.log(error);
  }
};

// export the testMessages in test_content/exampleConversations to firestore
// This works only when corresponding conversations are already in firestore
// used in testpage/TestPage.js
// it adds all the messages to each conversation
export const exportMessages = messageObjects => async dispatch => {
  dispatch(asyncActionStart());
  try {
    const conversationSnapshots = await firestore
      .collection('conversations')
      .get();
    console.log({ conversationSnapshots });
    await conversationSnapshots.forEach(async snapshot => {
      const convoData = snapshot.data();
      const { id: convoID, ref: convoRef } = snapshot;
      console.log({ convoData });
      console.log({ convoID });
      await Object.values(messageObjects).forEach(async value => {
        const userQuery = firestore
          .collection('usersPublic')
          .where('usernameLower', '==', value.creator.toLowerCase());
        const userSnapshot = await userQuery.get();
        const userID = userSnapshot.docs[0].id;
        const newMessage = {
          ...value,
          conversationID: convoID,
          creatorID: userID,
          createdAt: firebase.firestore.FieldValue.serverTimestamp()
        };
        console.log({ newMessage });
        await convoRef.collection('messages').add(newMessage);
      });
    });

    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};
