// shows ongoing conversations for this prompt if you're the creator of the prompt
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { getMyConversations, removeConversationsInState } from '../../actions';
import ConversationSection from '../conversationspage/ConversationSection';
import CircularLoading from '../commoncomponents/CircularLoading';
import {
  promptIDType,
  promptTitleType,
  getMyConversationsType,
  removeConversationsInStateType,
  authType,
  conversationsType,
  asyncType,
  historyType,
  classesType
} from '../../types';

const styles = {
  root: {},
  title: {
    padding: '0 1rem',
    paddingTop: '1.25rem'
  },
  noConvoText: {
    padding: '0 1rem',
    paddingBottom: '1.25rem'
  },
  // when it is 600px or less
  '@media (max-width: 37.5rem)': {
    title: {
      // fontSize: '18px'
    }
    // noConvoText: { fontSize: '16px' }
  }
};

class ConvosForMyPrompt extends Component {
  // we use debounce so it doesn't fire too many times
  checkIfAtBottom = debounce(() => {
    const { moreConversations } = this.props.conversations;
    // Checks that the page has scrolled to the bottom
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      if (moreConversations) {
        this.getMyConvos();
      }
    }
  }, 500);

  componentDidMount() {
    this.getMyConvos();
    window.addEventListener('scroll', this.checkIfAtBottom);
  }

  componentWillUnmount() {
    this.props.removeConversationsInState();
    window.removeEventListener('scroll', this.checkIfAtBottom);
  }

  getMyConvos = () => {
    const { promptID } = this.props;
    this.props.getMyConversations({ promptID });
  };

  renderEmptyConversationsMessage = () => {
    const { classes } = this.props;
    return (
      <Typography variant="subtitle1" className={classes.noConvoText}>
        Currently no one has started a conversation with this prompt yet!
      </Typography>
    );
  };

  handleGoToConvo = conversationID => () => {
    this.props.history.push(`/conversation/${conversationID}`);
  };

  renderConvoSections() {
    const {
      conversations: { myConversations },
      auth
    } = this.props;

    return Object.values(myConversations).map(conversation => {
      const {
        conversationID,
        promptTitle,
        promptID,
        promptCreatorID,
        promptCreator,
        conversationStarter,
        conversationStarterID,
        createdAt,
        messages
      } = conversation;
      // isItMyPrompt returns true or false depends on if you made the prompt or not
      const isItMyPrompt = auth.currentUser.uid === promptCreatorID;
      // if isItMyPrompt is true then you can talking to conversationStarter otherwise you're talking to promptCreator
      let talkingTo;
      let talkingToID;
      if (isItMyPrompt) {
        talkingTo = conversationStarter;
        talkingToID = conversationStarterID;
      } else {
        talkingTo = promptCreator;
        talkingToID = promptCreatorID;
      }
      return (
        <ConversationSection
          key={conversationID}
          conversationID={conversationID}
          promptTitle={promptTitle}
          promptID={promptID}
          isItMyPrompt={isItMyPrompt}
          talkingTo={talkingTo}
          talkingToID={talkingToID}
          createdAt={createdAt}
          messages={messages}
          auth={auth}
          handleGoToConvo={this.handleGoToConvo}
        />
      );
    });
  }

  render() {
    const {
      conversations: { myConversations },
      async,
      promptTitle,
      classes
    } = this.props;
    return (
      <Paper className={classes.root} elevation={0}>
        <div>
          <Typography variant="h6" className={classes.title}>
            Conversations about {promptTitle}
          </Typography>
        </div>
        {async.loading && <CircularLoading />}
        {!async.loading && isEmpty(myConversations) ? (
          this.renderEmptyConversationsMessage()
        ) : (
          <List>{this.renderConvoSections()}</List>
        )}
      </Paper>
    );
  }
}

ConvosForMyPrompt.propTypes = {
  promptID: promptIDType.isRequired,
  promptTitle: promptTitleType.isRequired,
  getMyConversations: getMyConversationsType.isRequired,
  removeConversationsInState: removeConversationsInStateType.isRequired,
  auth: authType.isRequired,
  conversations: conversationsType.isRequired,
  async: asyncType.isRequired,
  history: historyType.isRequired,
  classes: classesType.isRequired
};

const mapStateToProps = ({ conversations, async }) => ({
  conversations,
  async
});

const actions = { getMyConversations, removeConversationsInState };

const enhance = compose(connect(mapStateToProps, actions), withStyles(styles));

export default enhance(ConvosForMyPrompt);
