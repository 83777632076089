// use this to confirmed that you want to delete a conversation,
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { deleteConversation, closeModal } from '../../actions';
import CircularLoadingButton from '../commoncomponents/CircularLoadingButton';
import {
  deleteConversationType,
  closeModalType,
  conversationIdType,
  promptTitleType,
  talkingToType,
  loadingType,
  classesType,
  historyType
} from '../../types';

const styles = {
  wrapper: {
    position: 'relative'
  },
  dialogActions: {
    padding: '1rem'
  }
};

class DeleteConvoModal extends Component {
  handleDelete = async () => {
    const { conversationId } = this.props;
    await this.props.deleteConversation(conversationId);
    this.props.closeModal();
    // note that after sucessfully deleting the conversation, we go back to our list of conversations
    this.props.history.push('/conversations');
  };

  render() {
    const { promptTitle, talkingTo, loading, classes } = this.props;
    return (
      <Dialog
        open
        onClose={this.props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="form-dialog-title">Delete Conversation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Do you want to permantly delete your conversation with ${talkingTo} about ${promptTitle}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={this.props.closeModal}>Cancel</Button>
          <div className={classes.wrapper}>
            <Button
              onClick={this.handleDelete}
              color="primary"
              variant="contained"
            >
              Delete
            </Button>
            {loading && <CircularLoadingButton />}
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

DeleteConvoModal.propTypes = {
  deleteConversation: deleteConversationType.isRequired,
  closeModal: closeModalType.isRequired,
  conversationId: conversationIdType.isRequired,
  promptTitle: promptTitleType.isRequired,
  talkingTo: talkingToType.isRequired,
  loading: loadingType.isRequired,
  classes: classesType.isRequired,
  history: historyType.isRequired
};

const mapStateToProps = ({ async: { loading } }) => {
  return { loading };
};

const actions = { deleteConversation, closeModal };

const enhance = compose(
  connect(mapStateToProps, actions),
  withStyles(styles),
  withRouter
);

export default enhance(DeleteConvoModal);
