// names of website admins
// checks if the user is one of the admins
// used by AdminPage.js
// IF YOU ADD OR REMOVE ADMINS HERE DON'T FORGET TO CHANGE THE RELEVANT INFO
// IN FIRESTORE RULES AND VICE VERSA. This is the line in firestore rules that need change:
// allow read, delete: if request.auth.token.email in []
// there is also a copy of adminUsers in cloud functions utils
const admins = ['admin', 'person_1'];

export const checkIfAdmin = username => {
  const isAdmin = admins.includes(username.toLowerCase());
  return isAdmin;
};
