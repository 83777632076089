// used in SignUpModal and SignUpPage
// used for check if all the information is right when signing up
// else send out an error
// when you make changes here, be sure to make the same changes in
// signUpValidation() in Firestore Security Rules
import { firestore } from '../firebase';

// when signning up a new account, check if the age is valid
// NOTE that age is saved as a string!
const checkAge = age => {
  const errors = {};
  if (age < 18) {
    errors.age =
      'You have to be 18 years or older to participate in EroPenPal.';
  }
  return errors;
};

// when signining up a new account,
// checks if the username entered by the user is valid or not
// also returns the error if it is Not valid
const checkUsername = async username => {
  const errors = {};
  if (
    username.toLowerCase().includes('admin') ||
    username.toLowerCase().includes('eropenpal')
  ) {
    errors.username =
      'Username cannot contain the words "Admin" or "EroPenPal".';
  } else if (username.length > 15) {
    errors.username = 'Username cannot be longer than 15 characters.';
  } else if (username.length < 3) {
    errors.username = 'Username cannot be shorter than 3 characters.';
  } else if (!username.match(/^[0-9a-zA-Z_]+$/i)) {
    errors.username =
      'Username can only contain letters, numbers and underscore. Make sure it does not contain any symbols, dashes, or spaces.';
  } else {
    const usernameLower = username.toLowerCase();
    const userRef = firestore
      .collection('usersPublic')
      .where('usernameLower', '==', usernameLower);
    const querySnapshot = await userRef.get();
    if (querySnapshot.exists || querySnapshot.empty === false) {
      errors.username = 'This username is taken. Pick something else.';
    }
  }
  return errors;
};

export default async ({ username, age }) => {
  const ageErrors = checkAge(age);
  const usernameErrors = await checkUsername(username);
  const errors = { ...ageErrors, ...usernameErrors };
  if (Object.keys(errors).length === 0) {
    return null;
  }
  return errors;
};
