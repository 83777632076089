import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import CircularLoading from '../commoncomponents/CircularLoading';
import { closeModal } from '../../actions';
import { closeModalType } from '../../types';

const CircularLoadingModal = props => {
  return (
    <Dialog
      open
      onClose={props.closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }
      }}
    >
      <CircularLoading />
    </Dialog>
  );
};

CircularLoadingModal.propTypes = {
  closeModal: closeModalType.isRequired
};

const actions = { closeModal };

export default connect(null, actions)(CircularLoadingModal);
