// feedbacks
import { GET_FEEDBACKS, DELETE_FEEDBACK } from '../actions/types';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_FEEDBACKS: {
      const { feedbacks } = action.payload;
      return feedbacks;
    }
    case DELETE_FEEDBACK: {
      const { feedbackID } = action.payload;
      const newState = { ...state };
      delete newState[feedbackID];
      return newState;
    }
    default:
      return state;
  }
}
