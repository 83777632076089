// Fab stands for Floating Action Button
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {
  pageNameType,
  classesType,
  isSmallScreenBoolType,
  goToElementType,
  editMessageFormVisibilityType,
  footerVisibilityType,
  promptBottomMenuContainerVisibilityType,
  headerMobileVisibilityType
} from '../../types';

const styles = () => ({
  root: {},
  // when it is 600px or less
  '@media (max-width: 37.5rem)': {
    root: {
      position: 'fixed',
      bottom: '2rem',
      right: '1rem',
      zIndex: 99
    }
  }
});

const GoToTopBottomFab = ({
  classes,
  isSmallScreen,
  pageName,
  goToElement,
  editMessageFormVisibility,
  footerVisibility,
  promptBottomMenuContainerVisibility,
  headerMobileVisibility
}) => {
  const scrollToElement = () => {
    goToElement.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  const { root, scrollToeditMessageFormButton, footerButton } = classes;
  const scrollDownFab = () => {
    return (
      <Fab
        onClick={scrollToElement}
        className={classNames(root, scrollToeditMessageFormButton)}
        color="primary"
      >
        <ArrowDownwardIcon />
      </Fab>
    );
  };

  const scrollUpFab = () => {
    return (
      <Fab
        onClick={scrollToTop}
        className={classNames(root, footerButton)}
        color="primary"
      >
        <ArrowUpwardIcon />
      </Fab>
    );
  };

  // for PromptPage.js
  if (isSmallScreen && pageName === 'PromptPage') {
    if (!promptBottomMenuContainerVisibility && headerMobileVisibility) {
      return scrollDownFab();
    }
    if (footerVisibility && !headerMobileVisibility) {
      return scrollUpFab();
    }
  }

  // for ConversationPage.js
  if (isSmallScreen && pageName === 'ConversationPage') {
    if (!editMessageFormVisibility && headerMobileVisibility) {
      return scrollDownFab();
    }
    if (footerVisibility && !headerMobileVisibility) {
      return scrollUpFab();
    }
  }
  return null;
};

GoToTopBottomFab.propTypes = {
  classes: classesType.isRequired,
  isSmallScreen: isSmallScreenBoolType.isRequired,
  goToElement: goToElementType,
  editMessageFormVisibility: editMessageFormVisibilityType.isRequired,
  footerVisibility: footerVisibilityType.isRequired,
  pageName: pageNameType.isRequired,
  promptBottomMenuContainerVisibility:
    promptBottomMenuContainerVisibilityType.isRequired,
  headerMobileVisibility: headerMobileVisibilityType.isRequired
};

GoToTopBottomFab.defaultProps = {
  goToElement: null
};

const mapStateToProps = ({ isSmallScreen, componentVisibility }) => {
  const {
    editMessageFormVisibility,
    footerVisibility,
    promptBottomMenuContainerVisibility,
    headerMobileVisibility
  } = componentVisibility;
  return {
    isSmallScreen,
    editMessageFormVisibility,
    footerVisibility,
    promptBottomMenuContainerVisibility,
    headerMobileVisibility
  };
};

const actions = {};

const enhance = compose(connect(mapStateToProps, actions), withStyles(styles));

export default enhance(GoToTopBottomFab);
