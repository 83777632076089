// use social media like google and facebook to login
// used in loginpage/LoginPage.js
// used in modals/LogInModal.js and modals/SignUpModal.js
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { handleSocialLoginType, classesType, isSignUpType } from '../../types';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  googleButton: {
    // marginBottom: '0.5rem'
  },
  twitterButton: {
    // marginBottom: '0.5rem'
  },
  facebookButton: {
    // marginBottom: '1rem'
  }
};

const SocialLogin = ({ handleSocialLogin, classes, isSignUp }) => (
  <div className={classes.root}>
    <Button
      onClick={handleSocialLogin('google')}
      variant="contained"
      color="primary"
      fullWidth
      className={classes.googleButton}
    >
      {isSignUp ? 'Sign up with Google' : 'Log in with Google'}
    </Button>
    {/*
    <Button
      onClick={handleSocialLogin('twitter')}
      variant="contained"
      color="primary"
      fullWidth
      className={classes.twitterButton}
    >
      {isSignUp ? 'Sign up with Twitter' : 'Log in with Twitter'}
    </Button>
    <Button
      onClick={handleSocialLogin('facebook')}
      variant="contained"
      color="primary"
      fullWidth
      className={classes.facebookButton}
    >
      {isSignUp ? 'Sign up with Facebook' : 'Log in with Facebook'}
    </Button> */}
  </div>
);

SocialLogin.propTypes = {
  handleSocialLogin: handleSocialLoginType.isRequired,
  classes: classesType.isRequired,
  isSignUp: isSignUpType
};

SocialLogin.defaultProps = {
  isSignUp: false
};

export default withStyles(styles)(SocialLogin);
