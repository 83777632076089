// opens or closes a snackbar, used by the snackbars/SnackbarManager.js

import { SNACKBAR_OPEN, SNACKBAR_CLOSE } from './types';

export const openSnackbar = (snackbarType, snackbarProps) => async dispatch => {
  dispatch({ type: SNACKBAR_OPEN, payload: { snackbarType, snackbarProps } });
};

export const closeSnackbar = () => async dispatch => {
  dispatch({ type: SNACKBAR_CLOSE });
};
