// filter prompts shown
// import merge from 'lodash/merge'; // used for deep merge
import { FILTER_PROMPTS } from '../actions/types'; // import the action type
import freshFilter from '../utils/freshFilter';

const initialState = freshFilter;

export default function(state = initialState, action) {
  switch (action.type) {
    case FILTER_PROMPTS: {
      return { ...state, ...action.payload.filter };
    }
    default:
      return state;
  }
}
