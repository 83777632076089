// update the user information in usersPrivate and usersPublic collections in firestore
import { firestore } from '../firebase';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';
import { openSnackbar } from './snackbarActions';
import { updateMyUserReduxState } from './updateMyUserReduxState';

export const updateMyUser = (myUserObject, updateType) => async (
  dispatch,
  getState
) => {
  dispatch(asyncActionStart());
  try {
    const { currentUser } = getState().auth;
    const currentUserPrivateRef = firestore
      .collection('usersPrivate')
      .doc(currentUser.uid);
    const currentUserPublicRef = firestore
      .collection('usersPublic')
      .doc(currentUser.uid);
    // update specific part of the usersPrivate doc
    if (updateType === 'notifications') {
      const newNotifications = myUserObject.notifications;
      // update the doc in firestore
      await currentUserPrivateRef.update({
        notifications: { ...newNotifications }
      });
      // update the myUser redux state
      dispatch(
        updateMyUserReduxState({
          ...getState().myUser,
          notifications: {
            ...newNotifications
          }
        })
      );
      dispatch(
        openSnackbar('SuccessSnackbar', {
          message: 'successfully updated the notifications settings!'
        })
      );
    } else if (updateType === 'lookingforPreferences') {
      const newLookingforPreferences = myUserObject.lookingforPreferences;
      // update the doc in firestore
      await currentUserPrivateRef.update({
        lookingforPreferences: { ...newLookingforPreferences }
      });
      // update the myUser redux state
      dispatch(
        updateMyUserReduxState({
          ...getState().myUser,
          lookingforPreferences: {
            ...newLookingforPreferences
          }
        })
      );
    } else if (updateType === 'about') {
      const newAbout = myUserObject.about;
      // update the doc in firestore
      await currentUserPublicRef.update({
        about: newAbout
      });
      // update the myUser redux state
      dispatch(
        updateMyUserReduxState({
          ...getState().myUser,
          about: newAbout
        })
      );
      dispatch(
        openSnackbar('SuccessSnackbar', {
          message: 'Successfully updated about me!'
        })
      );
    }
    // else {
    //   await currentUserRef.update(myUserObject);
    // }
    dispatch(asyncActionFinish());
  } catch (error) {
    const { code, message } = error;
    console.log(error);
    dispatch(asyncActionError({ code, message }));
    dispatch(openSnackbar('ErrorSnackbar', { message }));
  }
};
