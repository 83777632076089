import {
  CONVOSFORMYPROMPT_VISIBILITY,
  EDITMESSAGEFORM_VISIBILITY,
  FOOTER_VISIBILITY,
  HEADERDESKTOP_VISIBILITY,
  HEADERMOBILE_VISIBILITY,
  HEADER_VISIBILITY,
  PROMPTBOTTOMMENUCONTAINER_VISIBILITY,
  PROMPTHEADERBUTTON_VISIBILITY
} from '../actions/types';

const initialState = {
  convosForMyPromptVisibility: false,
  editMessageFormVisibility: false,
  footerVisibility: false,
  headerDesktopVisibility: false,
  headerMobileVisibility: false,
  headerVisibility: false,
  promptBottomMenuContainerVisibility: false,
  promptHeaderButtonVisibility: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CONVOSFORMYPROMPT_VISIBILITY: {
      return { ...state, convosForMyPromptVisibility: action.payload };
    }
    case EDITMESSAGEFORM_VISIBILITY: {
      return { ...state, editMessageFormVisibility: action.payload };
    }
    case FOOTER_VISIBILITY: {
      return { ...state, footerVisibility: action.payload };
    }
    case PROMPTBOTTOMMENUCONTAINER_VISIBILITY: {
      return { ...state, promptBottomMenuContainerVisibility: action.payload };
    }
    case HEADERDESKTOP_VISIBILITY: {
      return { ...state, headerDesktopVisibility: action.payload };
    }
    case HEADERMOBILE_VISIBILITY: {
      return { ...state, headerMobileVisibility: action.payload };
    }
    case HEADER_VISIBILITY: {
      return { ...state, headerVisibility: action.payload };
    }
    case PROMPTHEADERBUTTON_VISIBILITY: {
      return { ...state, promptHeaderButtonVisibility: action.payload };
    }
    default:
      return { ...state };
  }
}
