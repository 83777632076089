// Show that the page is loading
// Used in userpage/UserPage.js, frontpage/FrontPage.js, conversationspage/ConversationsPage.js, promptpage/ConvosForMyPrompt.js
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { classesType, sizeType } from '../../types';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  progress: {
    margin: theme.spacing(2),
    // display: 'flex',
    // justifyContent: 'center',
    color: lightBlue[300]
  }
});

function CircularLoading(props) {
  const { classes, size } = props;
  return (
    <div className={classes.root}>
      <CircularProgress size={size || 40} className={classes.progress} />
    </div>
  );
}

CircularLoading.propTypes = {
  classes: classesType.isRequired,
  size: sizeType
};

CircularLoading.defaultProps = {
  size: null
};

export default withStyles(styles)(CircularLoading);
