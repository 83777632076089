/* eslint no-console: "off" */
// admin actions
// currently can create new users and create new prompts
import firebase, { firestore } from '../firebase';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';
import { openSnackbar } from './snackbarActions';
import {
  prepareEditorStateForStorage,
  reviveEditorStateAfterStorage,
  editorStateToPlainText,
  preparePlainTextForStorage
} from '../utils/convertEditorState';
import checkPromptObjectBefore from '../utils/checkPromptObjectBefore';
import { dispatchManmadeErrors, resetErrors } from './errorsActions';
import {
  GET_MY_PROMPTS,
  GET_GENERATED_CONVO_MSGS,
  UPDATE_GENERATED_CONVO_MSG_REDUX_STATE,
  DELETE_GENERATED_CONVO_MSG_REDUX_STATE,
  APPROVE_GENERATED_CONVO_MSG_REDUX_STATE,
  DELETE_ALL_GENERATED_CONVO_MSGS_REDUX_STATE,
  UPDATE_CONVERSATIONS_REDUX_STATE,
  GET_OUR_USERS,
  GET_USERS_PRIVATE_DOCS
} from './types';
import sanitizeTagsInput from '../utils/sanitizeTagsInput';
import sortConvoMsgsByCreatedAt from '../utils/sortConvoMsgsByCreatedAt';
import randomlySelectMyKinks from '../utils/randomlySelectMyKinks';

// get usersPrivate docs of users
// used by AdminGeneratedMessages.js to display the iam and lookingfor genders of the user
export const getUsersPrivateDocs = usernames => async dispatch => {
  try {
    const usersPrivateRef = firestore.collection('usersPrivate');
    const promises = usernames.map(username => {
      const userPrivateQuery = usersPrivateRef.where(
        'usernameLower',
        '==',
        username.toLowerCase()
      );
      return userPrivateQuery.get();
      // console.log('userPrivateSnapshot.docs', userPrivateSnapshot.docs);
      // return userPublicSnapshot;
    });
    const snapshots = await Promise.all(promises);
    const usersPrivateDocsArray = snapshots.map(snapshot => {
      return snapshot.docs[0].data();
    });

    const usersPrivateDocs = {};
    usersPrivateDocsArray.forEach(userPrivateDoc => {
      const { usernameLower } = userPrivateDoc;
      usersPrivateDocs[usernameLower] = userPrivateDoc;
    });
    dispatch({
      type: GET_USERS_PRIVATE_DOCS,
      payload: {
        usersPrivateDocs
      }
    });
  } catch (error) {
    console.log({ error });
  }
};

// bump a prompt without any limitations
export const adminBumpPrompt = promptId => async dispatch => {
  try {
    const promptRef = firestore.collection('prompts').doc(promptId);
    await promptRef.update({
      bumpedAt: firebase.firestore.FieldValue.serverTimestamp()
    });
    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: `Successfully bumped prompt: ${promptId}`
      })
    );
  } catch (error) {
    console.log({ error });
  }
};

// get all of the conversations sorted by lastUpdated date
// used in AdminLatestConversations.js
export const getAllConversations = () => async (dispatch, getState) => {
  dispatch(asyncActionStart());
  try {
    const { lastConversation, allConversations } = getState().conversations;
    let conversationsQuery = firestore
      .collection('conversations')
      .orderBy('lastUpdated', 'desc')
      .limit(20);
    if (lastConversation) {
      conversationsQuery = conversationsQuery.startAfter(lastConversation);
    }
    const conversationsSnapshot = await conversationsQuery.get();
    const conversationsDocs = conversationsSnapshot.docs;
    const conversationsObject = {};
    conversationsDocs.forEach(conversationDoc => {
      const conversationData = conversationDoc.data();
      const conversationID = conversationDoc.id;
      conversationsObject[conversationID] = {
        ...conversationData,
        conversationID
      };
    });

    // get the lastConversation from the recently fetched conversations
    const lastConversationMod =
      conversationsSnapshot.docs[conversationsSnapshot.docs.length - 1];

    // see if there are any more conversations left in the firestore
    let moreConversations;
    if (conversationsSnapshot.docs.length === 20) {
      moreConversations = true;
    } else {
      moreConversations = false;
    }

    dispatch({
      type: UPDATE_CONVERSATIONS_REDUX_STATE,
      payload: {
        allConversations: { ...allConversations, ...conversationsObject },
        lastConversation: lastConversationMod,
        moreConversations
      }
    });

    dispatch(asyncActionFinish());
  } catch (error) {
    console.log({ error });
    dispatch(asyncActionError());
  }
};

// randomly generate kinks for the user
export const rollKinksForUser = userId => async dispatch => {
  dispatch(asyncActionStart());
  try {
    const newKinksTree = randomlySelectMyKinks();
    const userPublicRef = firestore.collection('usersPublic').doc(userId);

    await userPublicRef
      .collection('kinks')
      .doc('kinksTree')
      .set({ ...newKinksTree });

    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: "Successfully rerolled the user' kinks"
      })
    );
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log({ error });
    dispatch(asyncActionError());
  }
};

// get ourUsers doc from misc collection
// used by AdminPrompts.js to highlight prompts made by our users
export const getOurUsers = () => async dispatch => {
  try {
    const ourUsersRef = firestore.collection('misc').doc('ourUsers');
    const ourUsers = await ourUsersRef.get();
    const ourUsersData = ourUsers.data();
    dispatch({
      type: GET_OUR_USERS,
      payload: { ourUsers: ourUsersData }
    });
  } catch (error) {
    console.log({ error });
  }
};

// get all of the conversations the user has participated in
export const getConversationsOfUser = username => async dispatch => {
  dispatch(asyncActionStart());
  try {
    const usernameLower = username.toLowerCase();
    const userPublicQuery = firestore
      .collection('usersPublic')
      .where('usernameLower', '==', usernameLower)
      .limit(1);
    const userPublicSnapshot = await userPublicQuery.get();

    if (!userPublicSnapshot.empty) {
      const userPublicDoc = userPublicSnapshot.docs[0];
      const userId = userPublicDoc.id;
      const userConversationsQuery = firestore
        .collection('conversations')
        .where('participantsIDs', 'array-contains', userId)
        .orderBy('lastUpdated', 'desc');
      const userConversationsSnapshot = await userConversationsQuery.get();

      const userConversationsDocs = userConversationsSnapshot.docs;
      const userConversationsObject = {};
      userConversationsDocs.forEach(userConversationDoc => {
        const userConversationData = userConversationDoc.data();
        const conversationID = userConversationDoc.id;
        userConversationsObject[conversationID] = {
          ...userConversationData,
          conversationID
        };
      });
      dispatch({
        type: UPDATE_CONVERSATIONS_REDUX_STATE,
        payload: { myConversations: userConversationsObject }
      });
    }
    dispatch(asyncActionFinish());
  } catch (error) {
    dispatch(asyncActionError());
  }
};

// prepare the plain text chosen response text to be saved in the firestore
const prepareChosenResponseTextForFirestore = text => {
  const textMod = text.replace(/\n\n/g, '\n'); // remove extra newlines
  return preparePlainTextForStorage(textMod);
};

// add the generated text from the chosen response of the generatedConvoMsg
// to the corresponding conversation messages collection
export const postGeneratedConvoMsgResponse = generatedConvoMsgId => async (
  dispatch,
  getState
) => {
  dispatch(asyncActionStart());
  // try {
  const generatedConvoMsgRef = firestore
    .collection('generatedConvoMsgs')
    .doc(generatedConvoMsgId);
  const generatedConvoMsg = getState().conversations.generatedConvoMsgs[
    generatedConvoMsgId
  ];
  const {
    promptId,
    conversationID,
    creator,
    creatorID,
    approvedResponseCount,
    responseData
  } = generatedConvoMsg;
  const chosenResponse = responseData[approvedResponseCount];
  const chosenResponseText = chosenResponse.generated_text_mod;
  // if conversationID exists that means the conversation exists and we are
  // replying to a conversation message in that conversation
  if (conversationID) {
    const conversationMessageRef = firestore
      .collection('conversations')
      .doc(conversationID)
      .collection('messages')
      .doc();
    await firestore.runTransaction(async transaction => {
      await transaction.set(conversationMessageRef, {
        conversationID,
        creator,
        creatorID,
        messageText: prepareChosenResponseTextForFirestore(chosenResponseText),
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      });
      await transaction.delete(generatedConvoMsgRef);
    });
    // if conversationID does NOT exist that means the conversation does not exist
    // and we are replying to the prompt, we need to create a conversation first
  } else {
    const promptRef = firestore.collection('prompts').doc(promptId);
    const conversationRef = firestore.collection('conversations').doc();
    // const conversationsRef = firestore.collection('conversations');
    await firestore.runTransaction(async transaction => {
      const promptSnapshot = await transaction.get(promptRef);
      const promptData = promptSnapshot.data();
      const {
        creator: promptCreator,
        creatorID: promptCreatorID,
        text: promptText,
        title: promptTitle
      } = promptData;
      const conversationObject = {
        conversationStarter: creator,
        conversationStarterID: creatorID,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        participants: [creator, promptCreator],
        participantsIDs: [creatorID, promptCreatorID],
        promptCreator,
        promptCreatorID,
        promptID: promptId,
        promptText,
        promptTitle,
        streak: 0,
        streakWaitingForUser: null
      };
      console.log({ conversationObject });
      await transaction.set(conversationRef, conversationObject);

      const messageObject = {
        conversationID: conversationRef.id,
        creator,
        creatorID,
        messageText: prepareChosenResponseTextForFirestore(chosenResponseText),
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      };
      const conversationMessageRef = conversationRef
        .collection('messages')
        .doc();
      await transaction.set(conversationMessageRef, messageObject);
      await transaction.delete(generatedConvoMsgRef);
    });
  }
  dispatch(
    openSnackbar('SuccessSnackbar', {
      message:
        'successfully posted the generated conversation message response!'
    })
  );
  dispatch(asyncActionFinish());
  // } catch (error) {
  //   const { code, message } = error;
  //   console.log(code, message);
  //   dispatch(openSnackbar('ErrorSnackbar', { message }));
  //   dispatch(asyncActionError());
  // }
};

// delete the generated texts for a convo message from the generatedConvoMsg doc
export const deleteGeneratedConvoMsg = generatedConvoMsgId => async dispatch => {
  dispatch(asyncActionStart());
  try {
    const generatedConvoMsgRef = firestore
      .collection('generatedConvoMsgs')
      .doc(generatedConvoMsgId);

    await generatedConvoMsgRef.delete();
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log({ error });
    dispatch(asyncActionError());
  }
};

// get a new set of generated text for a convo message, replacing the current set
// re-place the info from generatedConvoMsg into generateConvoMsgsQueue
// and delete that generatedConvoMsg
export const rerollGeneratedConvoMsg = generatedConvoMsgId => async (
  dispatch,
  getState
) => {
  // dispatch(asyncActionStart());
  const generatedConvoMsgRef = firestore
    .collection('generatedConvoMsgs')
    .doc(generatedConvoMsgId);
  const generateConvoMsgQueueRef = firestore
    .collection('generateConvoMsgsQueue')
    .doc(generatedConvoMsgId);
  const { generatedConvoMsgs } = getState().conversations;
  const generatedConvoMsg = generatedConvoMsgs[generatedConvoMsgId];
  const {
    body,
    conversationID,
    creator,
    creatorID,
    promptId,
    talkingTo
  } = generatedConvoMsg;
  await firestore.runTransaction(async transaction => {
    // add the generatedConvoMsg doc back to generateConvoMsgs collection
    await transaction.set(generateConvoMsgQueueRef, {
      promptId,
      body,
      conversationID,
      creator,
      creatorID,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      talkingTo
    });
    // delete the generatedConvoMsg doc from the generatedConvoMsgs collection
    await transaction.delete(generatedConvoMsgRef);
  });

  // try {
  //   dispatch(asyncActionFinish());
  // } catch (error) {
  //   console.log({ error });
  //   dispatch(asyncActionError());
  // }
};

// delete the generatedConvoMsg object in redux state
export const deleteGeneratedConvoMsgReduxState = generatedConvoMsgId => async dispatch => {
  dispatch({
    type: DELETE_GENERATED_CONVO_MSG_REDUX_STATE,
    payload: { generatedConvoMsgId }
  });
};

// delete all of the generatedConvoMsgs in redux state
export const deleteAllGeneratedConvoMsgsReduxState = () => async dispatch => {
  dispatch({
    type: DELETE_ALL_GENERATED_CONVO_MSGS_REDUX_STATE
  });
};

// update the generatedConvoMsg's generated_text_mod in redux state
export const updateGeneratedConvoMsgReduxState = (
  generatedConvoMsgId,
  responseCount,
  updatedText,
  approve = false
) => async dispatch => {
  if (approve) {
    dispatch({
      type: APPROVE_GENERATED_CONVO_MSG_REDUX_STATE,
      payload: { generatedConvoMsgId, responseCount }
    });
  } else {
    dispatch({
      type: UPDATE_GENERATED_CONVO_MSG_REDUX_STATE,
      payload: { generatedConvoMsgId, responseCount, updatedText }
    });
  }
};

// update the doc in generatedConvoMsgs collection with the latest text in redux state
// and/or approving it to be posted or not
export const updateGeneratedConvoMsg = generatedConvoMsgId => async (
  dispatch,
  getState
) => {
  try {
    const generatedConvoMsgRef = firestore
      .collection('generatedConvoMsgs')
      .doc(generatedConvoMsgId);
    const { conversations } = getState();
    const generatedConvoMsg =
      conversations.generatedConvoMsgs[generatedConvoMsgId];

    await generatedConvoMsgRef.set(generatedConvoMsg);
    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: 'successfully updated the prompt!'
      })
    );
    dispatch(asyncActionFinish());
  } catch (error) {
    const { code, message } = error;
    console.log(code, message);
    dispatch(openSnackbar('ErrorSnackbar', { message }));
    dispatch(asyncActionError());
  }
};

// get generated conversation messages (docs from generatedConvoMsgs)
export const getGeneratedConvoMsgs = () => async dispatch => {
  dispatch(asyncActionStart());
  try {
    const generatedConvoMsgsQuery = firestore
      .collection('generatedConvoMsgs')
      .orderBy('createdAt', 'asc');
    const generatedConvoMsgsSnapshot = await generatedConvoMsgsQuery.get();
    const generatedConvoMsgsArray = generatedConvoMsgsSnapshot.docs;
    // make generatedConvoMsgsArray into an object with its id as its key
    const generatedConvoMsgs = {};
    generatedConvoMsgsArray.forEach(generatedConvoMsg => {
      const { id } = generatedConvoMsg;
      const generatedConvoMsgData = generatedConvoMsg.data();
      generatedConvoMsgs[id] = {
        generatedConvoMsgId: id,
        ...generatedConvoMsgData
      };
    });
    dispatch({
      type: GET_GENERATED_CONVO_MSGS,
      payload: { generatedConvoMsgs }
    });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log({ error });
    dispatch(asyncActionError());
  }
};

// generate conversation message by creating a doc in generateConvoMsgsQueue collection
export const generateConvoMsg = (
  conversation,
  responseTextLength = 100
) => async dispatch => {
  dispatch(asyncActionStart());
  try {
    const {
      promptID,
      messages,
      promptCreator,
      promptCreatorID,
      conversationStarter,
      conversationStarterID,
      promptText,
      conversationID
    } = conversation;
    const generateConvoMsgsQueueRef = firestore.collection(
      'generateConvoMsgsQueue'
    );
    const promptRef = firestore.collection('prompts').doc(promptID);
    const sortedMessages = sortConvoMsgsByCreatedAt(messages);
    const promptSnapshot = await promptRef.get();
    if (!promptSnapshot.exists) {
      throw Error('prompt does not exist!');
    }
    const promptData = promptSnapshot.data();
    const { iam, lookingfor } = promptData;
    const userIdToGender = {
      [promptCreatorID]: {
        userId: promptCreatorID,
        username: promptCreator,
        gender: iam
      },
      [conversationStarterID]: {
        userId: conversationStarterID,
        username: conversationStarter,
        gender: lookingfor
      }
    };
    // get the last message (newest); if there are no messages, use the promptText
    let lastMessageText;
    let lastMessageCreatorId;
    let lastMessageCreator;
    let plainPromptText = ''; // this is the final text of the body that we will send to the ML model
    const sortedMessagesLength = sortedMessages.length;
    if (sortedMessagesLength !== 0) {
      const lastMessage = sortedMessages[sortedMessagesLength - 1];
      lastMessageText = lastMessage.messageText;
      lastMessageCreatorId = lastMessage.creatorID;
      lastMessageCreator = lastMessage.creator;
      // if the messages before lastMessage are also written by the creator of lastMessage
      // then we combine the messages until we reach ~ 800 words
      // Note that we choose 800 as cut off because gpt2 max tokens are 1024 and we still need
      // to generate a maximum of 200 tokens from it
      for (let i = 1; i <= sortedMessagesLength; i++) {
        console.log({ i });
        // check if plainPromptText has 800 or more words, if so we are stopping
        const plainPromptTextArray = plainPromptText.split(' ');
        console.log('plainPromptTextArray.length', plainPromptTextArray.length);
        if (plainPromptTextArray.length >= 800) {
          console.log(
            'plainPromptTextArray has reached 800 or more words, we are Stopping!'
          );
          break;
        }
        const previousMessage = sortedMessages[sortedMessagesLength - i];
        // check if the previousMessage is the same as the current message creator,
        // if they are different, we are stopping
        if (previousMessage.creatorID !== lastMessageCreatorId) {
          console.log(
            "previous message's creator is different from that of lastMessage, we are Stopping!"
          );
          break;
        }
        // make the previousMessage's messageText into plain text
        const previousMessagePlainText = editorStateToPlainText(
          reviveEditorStateAfterStorage(previousMessage.messageText)
        );
        // add previousMessage's plain text message to plainPromptText
        plainPromptText = `\n\n${previousMessagePlainText}${plainPromptText}`;
      }
      plainPromptText = plainPromptText.trim('\n\n'); // reduce \n\n from start of the string
    } else {
      lastMessageText = promptText;
      lastMessageCreatorId = promptCreatorID;
      lastMessageCreator = promptCreator;
      plainPromptText = editorStateToPlainText(
        reviveEditorStateAfterStorage(lastMessageText)
      );
    }
    console.log({ plainPromptText });
    const lastMessageIam = userIdToGender[lastMessageCreatorId].gender;
    const newMessageCreator = Object.values(userIdToGender).filter(
      idGenderUsername => {
        return idGenderUsername.userId !== lastMessageCreatorId;
      }
    )[0];
    const body = {
      text: plainPromptText,
      response_length: responseTextLength
    };
    console.log('lastMessageIam', lastMessageIam);
    console.log('newMessageCreator.gender', newMessageCreator.gender);
    if (lastMessageIam === 'male') {
      if (
        newMessageCreator.gender === 'female' ||
        newMessageCreator.gender === 'all'
      ) {
        body.pov = 'f_pov';
      }
    } else if (lastMessageIam === 'female') {
      if (
        newMessageCreator.gender === 'male' ||
        newMessageCreator.gender === 'all'
      ) {
        body.pov = 'm_pov';
      }
    } else if (lastMessageIam === 'all') {
      if (newMessageCreator.gender === 'female') {
        body.pov = 'f_pov';
      } else if (newMessageCreator.gender === 'male') {
        body.pov = 'm_pov';
      }
    }

    const generateConvoMsgQueueObject = {
      promptId: promptID,
      conversationID,
      creator: newMessageCreator.username,
      creatorID: newMessageCreator.userId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      body,
      talkingTo: lastMessageCreator
    };
    console.log({ generateConvoMsgQueueObject });
    await generateConvoMsgsQueueRef.add(generateConvoMsgQueueObject);

    dispatch(asyncActionFinish());
    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: 'successfully uploaded the message to the generation queue!'
      })
    );
  } catch (error) {
    console.log({ error });
    const { code, message } = error;
    dispatch(openSnackbar('ErrorSnackbar', { message }));
    dispatch(asyncActionError({ code, message }));
  }
};

// update the prompt in queuedPrompts collection, including approving it to be posted
export const updateQueuedPrompts = promptId => async (dispatch, getState) => {
  dispatch(asyncActionStart());
  try {
    const queuedPromptRef = firestore.collection('queuedPrompts').doc(promptId);
    const { myPrompts } = getState();
    const prompt = myPrompts[promptId];
    const tags = sanitizeTagsInput(prompt.tags);

    await queuedPromptRef.set({
      ...prompt,
      tags
    });
    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: 'successfully updated the prompt!'
      })
    );
    dispatch(asyncActionFinish());
  } catch (error) {
    const { code, message } = error;
    console.log(code, message);
    dispatch(openSnackbar('ErrorSnackbar', { message }));
    dispatch(asyncActionError());
  }
};

// delete the prompt from queuedPrompts collection
export const deleteQueuedPrompt = promptId => async dispatch => {
  dispatch(asyncActionStart());
  try {
    const queuedPromptRef = firestore.collection('queuedPrompts').doc(promptId);
    await queuedPromptRef.delete();
    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: 'successfully deleted the prompt!'
      })
    );
    dispatch(asyncActionFinish());
  } catch (error) {
    const { code, message } = error;
    console.log(code, message);
    dispatch(openSnackbar('ErrorSnackbar', { message }));
    dispatch(asyncActionError());
  }
};

// get the prompts in the queuedPrompts collection
// put the prompts in myPrompts redux state
export const getQueuedPrompts = () => async dispatch => {
  dispatch(asyncActionStart());
  try {
    const queuedPromptsRef = firestore.collection('queuedPrompts');
    const queuedPromptsSnapshot = await queuedPromptsRef.get();
    const queuedPromptsDocs = queuedPromptsSnapshot.docs;
    const prompts = {};
    queuedPromptsDocs.forEach(doc => {
      const { id } = doc;
      const data = doc.data();
      prompts[id] = { ...data, id };
    });
    dispatch({ type: GET_MY_PROMPTS, payload: prompts });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

// allows admin to change lovesCount of any prompt
export const changeLovesCount = (promptId, newLovesCount) => async dispatch => {
  dispatch(asyncActionStart());
  try {
    const promptRef = firestore.collection('prompts').doc(promptId);
    await promptRef.update({ lovesCount: newLovesCount });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log({ error });
    dispatch(asyncActionError());
  }
};

// upload the generated prompts to the generatedPrompts collection of firestore
// argument is an array of generated prompts
export const uploadPrompts = prompts => async dispatch => {
  dispatch(asyncActionStart());
  try {
    console.log('prompts length', prompts.length);
    // check each prompt to make sure it fits the criteria
    // keep only the ones that fit
    const filteredPrompts = prompts.filter(prompt => {
      try {
        checkPromptObjectBefore(prompt);
        return true;
      } catch (error) {
        console.log({ error });
        return false;
      }
    });
    console.log('filtered prompts length', filteredPrompts.length);

    const generatedPromptsRef = firestore.collection('generatedPrompts');
    console.log(`We are uploading ${filteredPrompts.length} prompts!`);
    // we cannot use batch write because batch can only do 500 docs at a time
    // so we write to firestore one by one
    const promises = filteredPrompts.map(prompt => {
      const generatedPromptsDocRef = generatedPromptsRef.doc();
      return generatedPromptsDocRef.set({
        ...prompt,
        uploadedAt: firebase.firestore.FieldValue.serverTimestamp()
      });
    });
    console.log({ promises });
    // we wait until all of the prompts are created in firestore
    await Promise.all(promises);
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log({ error });
    dispatch(asyncActionError());
  }
};

// writes to the createNewPrompts collection then it triggers the handleCreateNewPrompt
// cloud function and it creates the new prompt in prompts collection
export const createNewPrompt = newPromptObject => async dispatch => {
  dispatch(asyncActionStart());
  try {
    // checks the promptObject to make sure it does not have empty title or text or other deficiencies
    // throws an error when it does not pass
    checkPromptObjectBefore(newPromptObject);

    const { username, title, text, tags, iam, lookingfor } = newPromptObject;
    const tagsLower = tags.map(tag => tag.toLowerCase());
    const usernameLower = username.toLowerCase();
    // get the userID
    const userPublicQuery = firestore
      .collection('usersPublic')
      .where('usernameLower', '==', usernameLower);
    const userPublicSnapshot = await userPublicQuery.get();
    if (userPublicSnapshot.docs.length === 0) {
      throw new Error(
        'Cannot find user! username is either missing or incorrect!'
      );
    }
    const userID = userPublicSnapshot.docs[0].id;
    // create the prompt in createNewPrompt collection
    const createNewPromptsRef = firestore.collection('createNewPrompts');
    const createdNewPromptRef = await createNewPromptsRef.add({
      bumpedAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      creator: username,
      creatorID: userID,
      creatorLower: usernameLower,
      hide: false,
      iam,
      lookingfor,
      lovesCount: 0,
      published: true,
      publishedAt: firebase.firestore.FieldValue.serverTimestamp(),
      tags,
      text: prepareEditorStateForStorage(text),
      title,
      tagsLower
    });

    // update the newly created prompt with it's generated id
    const createdNewPromptID = createdNewPromptRef.id;
    await createdNewPromptRef.update({ id: createdNewPromptID });

    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: 'Successfully created the prompt!'
      })
    );
    dispatch(resetErrors());
    dispatch(asyncActionFinish());

    const success = true;
    return success;
  } catch (error) {
    console.log({ error });
    // if the error is manmade, made in checkPromptObjectBefore.js
    if (error.type === 'manmade') {
      dispatch(dispatchManmadeErrors(error));
    } else {
      // if the error is generated by firebase
      const { code, message } = error;
      dispatch(asyncActionError({ code, message }));
      dispatch(openSnackbar('ErrorSnackbar', { message }));
    }
    return null;
  }
};

// writes to the createNewUsers collection then it triggers the handleCreateNewUser
// cloud function and it creates the new user in auth and in usersPublic and usersPrivate
export const createNewUser = newUserObject => async dispatch => {
  dispatch(asyncActionStart());
  try {
    const { username, password, age, email } = newUserObject;
    const newKinksTree = randomlySelectMyKinks();
    const createNewUsersRef = firestore.collection('createNewUsers');
    await createNewUsersRef.add({
      username,
      password,
      age,
      email,
      kinks: newKinksTree
    });

    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: 'successfully created the user!'
      })
    );
    dispatch(asyncActionFinish());
    const success = true;
    return success;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
  return null;
};
