// conversation - single conversation
import {
  GET_CONVERSATION,
  UPDATE_CONVERSATION_MESSAGES_REDUX_STATE,
  REMOVE_CONVERSATION_REDUX_STATE
} from '../actions/types'; // import the action type

const initialState = {
  unsubscribeToConversationListener: null,
  conversationListenerLoaded: false,
  messages: {},
  unsubscribeToMessagesListener: null,
  messagesListenerLoaded: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONVERSATION: {
      return { ...state, ...action.payload.conversationObject };
    }
    case UPDATE_CONVERSATION_MESSAGES_REDUX_STATE: {
      const {
        messages,
        unsubscribeToMessagesListener,
        messagesListenerLoaded,
        convoMessagesFilter
      } = action.payload;

      return {
        ...state,
        messages,
        unsubscribeToMessagesListener,
        messagesListenerLoaded,
        convoMessagesFilter
      };
    }
    case REMOVE_CONVERSATION_REDUX_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};
