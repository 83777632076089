// meta descriptions for various pages

export default {
  FrontPage: `EroPenPal is an erotic roleplay platform where you can connect with
  others through the exchange of written words and erotic tales. Look
  around, find a penpal and fulfill your fantasy.`,
  GuidePage: `Getting Started Guide and Frequently Asked Questions (FAQs) on EroPenPal.
  Click on Create Prompt button to post a prompt or Start Conversation button to start
  talking to others`,
  RulesPage: `Rules for EroPenPal, the erotic roleplay platform: Be respectful,
  Adults Only, No Monetary Compensation, No Inappropriate Images or Links, Do Not
  Plagarize`,
  ContactPage: `EroPenPal is brand new and always improving. We&#39;d love any
  feedback, suggestions, bug reports or anything else.`,
  CreatePage: `Create and post a brand new prompt to start your erotic roleplay adventure.`,
  TagsPage: `All of the popular tags used by prompts are listed here in alphabetical order`,
  PrivacyPolicyPage: `Privacy policy of Eropenpal.com`
};
