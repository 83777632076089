// reducer for newStuffAlertsActions
import {
  UPDATE_NEW_STUFF_ALERTS,
  GET_ALL_NEW_CONVERSATION_MESSAGE_ALERTS
} from '../actions/types';

const initialState = {
  newConversationAlert: {},
  newConversationMessageAlert: {},
  newInboxMessageAlert: {},
  newPromptAlert: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NEW_STUFF_ALERTS: {
      const {
        unsubscribeNewStuffAlertsListener,
        newStuffAlerts
      } = action.payload;

      return { ...state, ...newStuffAlerts, unsubscribeNewStuffAlertsListener };
    }
    // note that this is for admin use only, to get newConversationMessageAlert from all users
    case GET_ALL_NEW_CONVERSATION_MESSAGE_ALERTS: {
      const { newConversationMessageAlerts } = action.payload;
      return {
        ...state,
        newConversationMessageAlerts: [...newConversationMessageAlerts]
      };
    }
    default:
      return state;
  }
};
