// reducer for
import {
  GET_INBOX_SENT_MESSAGES,
  GET_INBOX_RECEIVED_MESSAGES
} from '../actions/types';

const initialState = {
  lastSentMessage: null,
  lastReceivedMessage: null,
  sentMessages: {},
  receivedMessages: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INBOX_SENT_MESSAGES: {
      const {
        sentMessages,
        lastSentMessage,
        moreSentMessages
      } = action.payload;
      return {
        ...state,
        lastSentMessage,
        moreSentMessages,
        sentMessages
      };
    }
    case GET_INBOX_RECEIVED_MESSAGES: {
      const {
        receivedMessages,
        lastReceivedMessage,
        moreReceivedMessages
      } = action.payload;
      return {
        ...state,
        receivedMessages,
        lastReceivedMessage,
        moreReceivedMessages
      };
    }
    default:
      return state;
  }
};
