// change username, email and password in SettingsPage.js in accountpage
import firebase, { firestore } from '../firebase';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';
import { openSnackbar } from './snackbarActions';
import { reAuthenticateUser, fetchAuthUserOnce } from './authActions';
import { dispatchFirebaseError } from './errorsActions';
import { openModal, closeModal } from './modalActions';

export const changeEmail = newEmail => async dispatch => {
  dispatch(asyncActionStart());
  dispatch(openModal('CircularLoadingModal'));
  try {
    const user = firebase.auth().currentUser;
    const userID = user.uid;
    // change the email in user auth profile
    await user.updateEmail(newEmail);

    // change the email in usersPrivate and usersPublic docs using batch
    const batch = firestore.batch();
    const userRefPrivate = firestore.collection('usersPrivate').doc(userID);
    const userRefPublic = firestore.collection('usersPublic').doc(userID);
    await batch.update(userRefPrivate, { email: newEmail });
    await batch.update(userRefPublic, { email: newEmail });
    await batch.commit();

    dispatch(fetchAuthUserOnce()); // get the latest currentUser and providerData
    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: 'successfully changed email!'
      })
    );
    dispatch(asyncActionFinish());
    dispatch(closeModal());
    const success = true;
    return success;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    // might need to re-authenticate
    if (error.code === 'auth/requires-recent-login') {
      dispatch(reAuthenticateUser(error));
      // if it's not a reauth error but another type of firebase error
    } else {
      dispatch(dispatchFirebaseError(error));
      dispatch(closeModal());
    }
  }
  return null;
};

export const changePassword = (
  newPassword,
  changedOrAdded
) => async dispatch => {
  dispatch(asyncActionStart());
  dispatch(openModal('CircularLoadingModal'));
  try {
    const user = firebase.auth().currentUser;
    await user.updatePassword(newPassword);

    dispatch(fetchAuthUserOnce()); // get the latest currentUser and providerData
    let message;
    if (changedOrAdded === 'changed') {
      message = 'Successfully changed password!';
    } else if (changedOrAdded === 'added') {
      message = 'Successfully added password!';
    }
    dispatch(
      openSnackbar('SuccessSnackbar', {
        message
      })
    );
    dispatch(asyncActionFinish());
    dispatch(closeModal());
    const success = true;
    return success;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    // might need to re-authenticate
    if (error.code === 'auth/requires-recent-login') {
      dispatch(reAuthenticateUser(error));
      // if it's not a reauth error but another type of firebase error
    } else {
      dispatch(dispatchFirebaseError(error));
      dispatch(closeModal());
    }
  }
  return null;
};
