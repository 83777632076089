// start the button to start a new conversation with that prompt or go back
// to a previous conversation with that prompt
// currently used in promptslist/PromptsList.js, promptpage/PromptPage.js
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { checkConversation, openModal } from '../../actions';
import CircularLoadingButton from './CircularLoadingButton';
import {
  promptIDType,
  creatorIDType,
  buttonStyleType,
  checkConversationType,
  openModalType,
  authType,
  historyType,
  classesType,
  loadingType,
  isSmallScreenBoolType
} from '../../types';

const styles = {
  desktopButton: {
    // we need both minWidth and width to make it work!!! Do Not change it!
    minWidth: '9rem', // prevent the button from getting too small if the title text is really long
    width: '9rem' // make each button the same size (square / 2 rows)
  },
  // when it is 600px or less / on mobile
  '@media (max-width: 37.5rem)': {
    promptPageButton: {
      width: '100%', // make it fullWidth
      borderRadius: '10rem' // make the corners round
    }
  }
};

class StartConversationButton extends Component {
  // handles button click when the user is logged in
  handleReplyButtonClick = async () => {
    const { promptID } = this.props;
    const conversationID = await this.props.checkConversation(promptID);
    if (conversationID) {
      this.props.history.push(`/conversation/${conversationID}`);
    }
  };

  // handles button click when the user is logged out
  handleLoggedOutReplyButtonClick = () => {
    this.props.openModal('SignUpModal');
  };

  // logged in and prompt page
  renderRegularButton = () => {
    const { classes, loading } = this.props;
    return (
      <Button
        className={classNames(
          classes.desktopButton,
          classes.mobileButton,
          classes.promptPageButton
        )}
        onClick={this.handleReplyButtonClick}
        variant="contained"
        color="primary"
      >
        Start Conversation
        {loading && <CircularLoadingButton />}
      </Button>
    );
  };

  // logged in and promptslist/Promptslist.js
  renderPromptsListButton = () => {
    const { classes, loading, isSmallScreen } = this.props;
    // if screen width is greater than 600px (sm)
    if (!isSmallScreen) {
      return (
        <Button
          className={classes.desktopButton}
          onClick={this.handleReplyButtonClick}
          variant="contained"
          color="primary"
        >
          Start Conversation
          {loading && <CircularLoadingButton />}
        </Button>
      );
    }
    // if screen width is less than 600px (sm)
    return (
      <Button
        className={classes.mobileButton}
        onClick={this.handleReplyButtonClick}
        variant="contained"
        color="primary"
        size="small"
      >
        Start Conversation
        {loading && <CircularLoadingButton />}
      </Button>
    );
  };

  // logged out and prompt page
  renderLoggedOutButton = () => {
    const { classes } = this.props;
    return (
      <Button
        className={classNames(
          classes.desktopButton,
          classes.mobileButton,
          classes.promptPageButton
        )}
        onClick={this.handleLoggedOutReplyButtonClick}
        variant="contained"
        color="primary"
      >
        Start Conversation
      </Button>
    );
  };

  // logged out and promptslist/Promptslist.js
  renderPromptsListLoggedOutButton = () => {
    const { classes, isSmallScreen } = this.props;
    // if screen width is greater than 600px (sm)
    if (!isSmallScreen) {
      return (
        <Button
          className={classes.desktopButton}
          onClick={this.handleLoggedOutReplyButtonClick}
          variant="contained"
          color="primary"
        >
          Start Conversation
        </Button>
      );
    }
    // if screen width is less than 600px (sm)
    return (
      <Button
        className={classes.mobileButton}
        onClick={this.handleLoggedOutReplyButtonClick}
        variant="contained"
        color="primary"
        size="small"
      >
        Start Conversation
      </Button>
    );
  };

  render() {
    const { auth, creatorID, buttonStyle } = this.props;
    // logged in
    if (auth && auth.authenticated) {
      // same user or not
      // if the users are different then NO button is rendered
      if (auth.currentUser.uid !== creatorID) {
        // buttonStyle
        if (buttonStyle === 'promptslist') {
          return this.renderPromptsListButton();
        }
        return this.renderRegularButton();
      }
      // logged out
    } else {
      // buttonStyle
      if (buttonStyle === 'promptslist') {
        return this.renderPromptsListLoggedOutButton();
      }
      return this.renderLoggedOutButton();
    }
    return null;
  }
}

StartConversationButton.propTypes = {
  promptID: promptIDType.isRequired,
  creatorID: creatorIDType.isRequired,
  buttonStyle: buttonStyleType,
  checkConversation: checkConversationType.isRequired,
  openModal: openModalType.isRequired,
  auth: authType.isRequired,
  history: historyType.isRequired,
  classes: classesType.isRequired,
  loading: loadingType.isRequired,
  isSmallScreen: isSmallScreenBoolType.isRequired
};

StartConversationButton.defaultProps = {
  buttonStyle: null
};

const mapStateToProps = ({ auth, async: { loading }, isSmallScreen }) => ({
  auth,
  loading,
  isSmallScreen
});

const actions = { checkConversation, openModal };

const enhance = compose(
  connect(mapStateToProps, actions),
  withRouter,
  withStyles(styles)
);

export default enhance(StartConversationButton);
