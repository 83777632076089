// used in FollowButton.js,
// add each followingUser as a new doc in
// the following subcollection of the auth'd user doc in usersPrivate
// And add the follwingUser as a field in
// the followingCount doc in following subcollection of the auth'd user doc in usersPrivate
// we do both of those things because docs are needed by the cloud functions to figure out newly following users
// And followingCount doc is needed to easily query all of the users I'm following (profilepage/ProfileFollowing.js)
import firebase, { firestore } from '../firebase';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';
import { openSnackbar } from './snackbarActions';
import {
  FOLLOWING_USER,
  NOT_FOLLOWING_USER,
  REMOVE_FOLLOW_USER_LISTENER
} from './types';

// add the user as a doc to the following subcollection of currently auth'd user
// add the user as a field to the followingCount doc of the following subcollection
export const followUser = user => async (dispatch, getState) => {
  dispatch(asyncActionStart());
  try {
    const { currentUser } = getState().auth;
    const { id, username, usernameLower } = user;
    const userObject = {
      username,
      usernameLower,
      createdAt: firebase.firestore.FieldValue.serverTimestamp() // only this works. firestore.FieldValue.serverTimestamp() does Not work!
    };

    // ref to the following subcollection
    const currentUserRef = firestore
      .collection('usersPrivate')
      .doc(currentUser.uid)
      .collection('following');

    const batch = firestore.batch();
    // create the doc in the following subcollection
    await batch.set(currentUserRef.doc(id), userObject);

    // increase the count in followingCount doc
    await batch.set(
      currentUserRef.doc('followingCount'),
      {
        count: firebase.firestore.FieldValue.increment(1)
      },
      { merge: true }
    );
    await batch.commit();

    // a cloud function will add the currentUser to the user's followers subcollection

    dispatch(asyncActionFinish());
    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: `Successfully following ${username}`
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

// remove the user doc from following subcollection of currently auth'd user
// remove the user field from followingCount doc of the following subcollection
export const unfollowUser = user => async (dispatch, getState) => {
  dispatch(asyncActionStart());
  try {
    const { currentUser } = getState().auth;
    const currentUserRef = firestore
      .collection('usersPrivate')
      .doc(currentUser.uid)
      .collection('following');

    const batch = firestore.batch();

    // remove the doc
    await batch.delete(currentUserRef.doc(user.id));

    // decrease the count in followingCount
    await batch.update(currentUserRef.doc('followingCount'), {
      count: firebase.firestore.FieldValue.increment(-1)
    });

    await batch.commit();

    // a cloud function will remove the currentUser from the user's followers subcollection

    dispatch(asyncActionFinish());
    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: `Successfully unfollowed ${user.username}`
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

// listens to the currentUser's followers subcollection's follower doc for changes
export const followUserListener = userID => async (dispatch, getState) => {
  try {
    const { currentUser } = getState().auth;
    const currentUserRef = firestore
      .collection('usersPrivate')
      .doc(currentUser.uid)
      .collection('following')
      .doc(userID);
    const unsubscribe = await currentUserRef.onSnapshot(doc => {
      if (doc.exists) {
        dispatch({ type: FOLLOWING_USER, payload: { unsubscribe } });
      } else {
        // not that NOT_FOLLOWING_USER is used by 2 reducers:
        // followReducer to control follow redux state and
        // myPenpalsReducer to remove userID from myFollowingList in myPenpals redux state
        dispatch({
          type: NOT_FOLLOWING_USER,
          payload: {
            unsubscribe,
            userID
          }
        });
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const removeFollowUserListener = () => async (dispatch, getState) => {
  try {
    if (getState().follow.listenerLoaded) {
      const { unsubscribe } = getState().follow;
      unsubscribe();
      dispatch({ type: REMOVE_FOLLOW_USER_LISTENER });
    }
  } catch (error) {
    console.log(error);
  }
};
