// user clicks on this to flag the prompt
// used in promptpage/PromptBottomMenu.js
import React from 'react';
import { connect } from 'react-redux';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import { openModal } from '../../actions';
import {
  openModalType,
  promptIDType,
  promptTitleType,
  promptCreatorIDType,
  promptCreatorType
} from '../../types';

const FlagPrompt = ({
  promptID,
  promptTitle,
  promptCreatorID,
  promptCreator,
  ...props
}) => {
  const promptObject = {
    promptID,
    promptTitle,
    promptCreatorID,
    promptCreator
  };
  return (
    <BottomNavigationAction
      label="Flag"
      showLabel
      icon={<FlagOutlinedIcon />}
      onClick={() => props.openModal('FlagPromptModal', promptObject)}
    />
  );
};

FlagPrompt.propTypes = {
  openModal: openModalType.isRequired,
  promptID: promptIDType.isRequired,
  promptTitle: promptTitleType.isRequired,
  promptCreatorID: promptCreatorIDType.isRequired,
  promptCreator: promptCreatorType.isRequired
};

export default connect(null, { openModal })(FlagPrompt);
