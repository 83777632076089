// go to the an element on the page (usually go down) or go to the top of the page
// used by conversationpage/ConversationMessages.js and promptpage/PromptSidebarBottom.js
// this component is created to use isSmallScreen function which only works in functional components
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import ButtonBase from '@material-ui/core/ButtonBase';
import {
  classesType,
  isSmallScreenBoolType,
  goToElementType,
  buttonTextType,
  buttonVariantType,
  buttonClassType,
  buttonSizeType,
  scrollToTopType,
  pageNameType,
  promptBottomMenuContainerVisibilityType,
  convosForMyPromptVisibilityType,
  footerVisibilityType
} from '../../types';

const styles = {
  root: {},
  // when it is 600px or less
  '@media (max-width: 37.5rem)': {
    root: {}
  }
};

const GoToTopBottomButton = ({
  classes,
  pageName,
  isSmallScreen,
  goToElement,
  buttonText,
  buttonVariant,
  buttonClass,
  buttonSize,
  scrollToTop,
  promptBottomMenuContainerVisibility,
  convosForMyPromptVisibility,
  footerVisibility
}) => {
  const scrollToElement = () => {
    if (pageName === 'PromptPage') {
      if (scrollToTop) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      } else if (
        promptBottomMenuContainerVisibility ||
        convosForMyPromptVisibility ||
        footerVisibility
      ) {
        window.scrollTo({
          top: document.body.scrollHeight,
          left: 0,
          behavior: 'smooth'
        });
      } else {
        goToElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (pageName === 'ConversationPage') {
      goToElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  let size = isSmallScreen ? 'small' : 'medium';
  if (buttonSize) {
    size = buttonSize;
  }

  return (
    <Button
      size={size}
      variant={buttonVariant}
      color={buttonVariant === 'contained' ? 'primary' : 'default'}
      onClick={scrollToElement}
      className={classNames(classes.root, buttonClass)}
    >
      {buttonText}
    </Button>
  );
};

GoToTopBottomButton.propTypes = {
  classes: classesType.isRequired,
  isSmallScreen: isSmallScreenBoolType.isRequired,
  goToElement: goToElementType,
  buttonText: buttonTextType.isRequired,
  buttonVariant: buttonVariantType.isRequired,
  buttonClass: buttonClassType,
  buttonSize: buttonSizeType,
  scrollToTop: scrollToTopType,
  pageName: pageNameType.isRequired,
  promptBottomMenuContainerVisibility:
    promptBottomMenuContainerVisibilityType.isRequired,
  convosForMyPromptVisibility: convosForMyPromptVisibilityType.isRequired,
  footerVisibility: footerVisibilityType.isRequired
};

GoToTopBottomButton.defaultProps = {
  goToElement: null,
  scrollToTop: false,
  buttonClass: null,
  buttonSize: null
};

const mapStateToProps = ({
  isSmallScreen,
  componentVisibility: {
    promptBottomMenuContainerVisibility,
    convosForMyPromptVisibility,
    footerVisibility
  }
}) => ({
  isSmallScreen,
  promptBottomMenuContainerVisibility,
  convosForMyPromptVisibility,
  footerVisibility
});

const actions = {};

const enhance = compose(connect(mapStateToProps, actions), withStyles(styles));

export default enhance(GoToTopBottomButton);
