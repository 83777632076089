// reducer for getMyBookmarks in bookmarkPrompt.js action creators
// updates the redux state for myBookmarks for profilepage/ProfileBookmarks.js
import { GET_MY_BOOKMARKS, DELETE_MY_BOOKMARK } from '../actions/types';

const initialState = {
  lastMyBookmark: null,
  myBookmarksList: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_BOOKMARKS: {
      const {
        myBookmarksList,
        lastMyBookmark,
        moreMyBookmarks
      } = action.payload;
      return { ...state, myBookmarksList, lastMyBookmark, moreMyBookmarks };
    }
    case DELETE_MY_BOOKMARK: {
      const { promptID } = action.payload;
      const { myBookmarksList } = state;
      const filteredMyBookmarksList = {};
      Object.keys(myBookmarksList).forEach(bookmarkKey => {
        if (bookmarkKey !== promptID) {
          const prompt = myBookmarksList[bookmarkKey];
          filteredMyBookmarksList[bookmarkKey] = prompt;
        }
      });
      return { ...state, myBookmarksList: { ...filteredMyBookmarksList } };
    }
    default:
      return state;
  }
};
