import {
  UPDATE_MY_USER_REDUX_STATE,
  RESET_MY_USER_REDUX_STATE,
  GET_DPP_MESSAGES,
  GET_OTHER_DPP_SUBMISSIONS,
  DELETE_OTHER_DPP_SUBMISSION
} from '../actions/types';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MY_USER_REDUX_STATE: {
      return {
        ...state,
        ...action.payload
      };
    }
    case RESET_MY_USER_REDUX_STATE: {
      return initialState;
    }
    case GET_DPP_MESSAGES: {
      const { dppMessages } = action.payload;
      return {
        ...state,
        dppMessages
      };
    }
    case GET_OTHER_DPP_SUBMISSIONS: {
      const { submissions } = action.payload;
      return {
        ...state,
        submissions
      };
    }
    case DELETE_OTHER_DPP_SUBMISSION: {
      const { submissionId } = action.payload;
      const { submissions } = state;
      const filteredSubmissions = submissions.filter(submission => {
        const { id } = submission;
        if (id === submissionId) {
          return false;
        }
        return true;
      });
      return {
        ...state,
        submissions: filteredSubmissions
      };
    }
    default:
      return state;
  }
};
