import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import CircularLoading from '../commoncomponents/CircularLoading';
import { historyType, isSmallScreenBoolType } from '../../types';

const FrontPageDesktop = lazy(() => import('./FrontPageDesktop'));
const FrontPageMobile = lazy(() => import('./FrontPageMobile'));

const FrontPage = ({ history, isSmallScreen }) => (
  <Suspense fallback={<CircularLoading />}>
    {isSmallScreen ? (
      <FrontPageMobile history={history} />
    ) : (
      <FrontPageDesktop history={history} />
    )}
  </Suspense>
);

FrontPage.propTypes = {
  history: historyType.isRequired,
  isSmallScreen: isSmallScreenBoolType.isRequired
};

const mapStateToProps = ({ isSmallScreen }) => {
  return { isSmallScreen };
};

const actions = {};

export default connect(mapStateToProps, actions)(FrontPage);
