import { GET_FLAGGED_USERS } from '../actions/types';

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_FLAGGED_USERS: {
      return [...action.payload.usersFlagged];
    }
    default:
      return state;
  }
}
