// reducer for followUserListener and removeFollowUserListener in bookmarkPrompt.js action creators
// this is used in userpage/UserPage.js
import {
  FOLLOWING_USER,
  NOT_FOLLOWING_USER,
  REMOVE_FOLLOW_USER_LISTENER
} from '../actions/types';

const initialState = {
  listenerLoaded: false,
  followingUser: false,
  unsubscribe: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FOLLOWING_USER: {
      return {
        ...state,
        listenerLoaded: true,
        followingUser: true,
        unsubscribe: action.payload.unsubscribe
      };
    }
    case NOT_FOLLOWING_USER:
      return {
        ...state,
        listenerLoaded: true,
        followingUser: false,
        unsubscribe: action.payload.unsubscribe
      };
    case REMOVE_FOLLOW_USER_LISTENER:
      return {
        ...state,
        listenerLoaded: false,
        followingUser: false,
        unsubscribe: null
      };
    default:
      return state;
  }
};
