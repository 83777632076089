import PropTypes from 'prop-types';

const {
  // array,
  arrayOf,
  bool,
  element,
  elementType,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string
} = PropTypes;

const objectType = object;

export const accountOrNotificationsType = string;

export const addDppMessageToGenerationQueueType = func;

export const adminBumpPromptType = func;

export const allConversationsType = objectOf(object);

export const allTagsType = objectOf(object);

export const alreadyBookmarkedType = bool;

export const alreadyLovedType = bool;

export const anchorElType = objectOf(object);

export const asyncActionFinishType = func;

export const asyncActionStartType = func;

export const asyncType = shape({ loading: bool });

export const authType = shape({
  authenticated: bool,
  currentUser: objectOf(string)
});

export const autoHideDurationType = number;

export const bookmarkType = shape({
  bookmarked: bool,
  listenerLoaded: bool,
  bookmarkButtonClickPending: bool
});

export const bookmarkListenerType = func;

export const bookmarkPromptType = func;

export const bumpPromptType = func;

export const buttonClassType = string;

export const buttonSizeType = string;

export const buttonStyleType = string;

export const buttonTextType = oneOfType([string, element]);

export const buttonVariantType = string;

export const categoryType = string;

export const changeEmailType = func;

export const changeLovesCountType = func;

export const changePasswordType = func;

export const checkConversationType = func;

export const childrenType = oneOfType([element, arrayOf(element)]);

export const classesType = objectOf(string);

export const classNameType = string;

export const clickOrTapType = string;

export const closeModalType = func;

export const closeSnackbarType = func;

export const componentType = elementType;

export const contentStateType = objectOf(object);

export const convoMessagesFilterType = shape({
  conversationID: string,
  messageOrder: string,
  messageNumber: number
});

export const conversationIDType = string;

export const conversationIdType = string;

export const conversationListenerType = func;

export const conversationMessagesListenerType = func;

export const conversationStarterIDType = string;

export const conversationStarterType = string;

export const conversationsType = shape({
  myConversations: objectOf(objectType),
  lastConversation: oneOfType([objectType, bool]),
  moreConversations: bool
});

export const conversationType = shape({
  conversationID: string,
  promptID: string,
  unsubscribeToConversationListener: func,
  conversationStarter: string,
  conversationStarterID: string,
  promptCreator: string,
  promptCreatorID: string,
  promptText: string,
  messages: objectOf(objectType),
  convoMessagesFilter: shape({
    conversationID: string,
    messageNumber: number,
    messageOrder: string,
    moreOlderMessages: bool
  })
});

export const convosForMyPromptVisibilityType = bool;

export const countType = number;

export const createConversationMessageType = func;

export const createdAtType = shape({
  seconds: number,
  nanaoseconds: number,
  toMillis: func
});

export const createInboxMessageType = func;

export const createNewPromptType = func;

export const createNewUserType = func;

export const createPromptType = func;

export const createOrEditType = string;

export const creatorIDType = string;

export const creatorType = string;

export const crossPostedPromptsIdsType = objectOf(string);

export const crossPostPromptType = func;

export const currentModalType = shape({
  modalType: string,
  modalProps: shape({
    handleGoToNextLocation: func,
    handleSaveDraft: func,
    handleUpdatePromp: func,
    published: bool,
    promptID: string,
    promptTitle: string,
    userID: string,
    promptCreator: string,
    promptCreatorID: string
  })
});

export const currentSnackbarType = shape({
  snackbarType: string,
  snackbarProps: shape({
    autoHideDuration: number,
    message: string
  })
});

export const currentUserType = objectOf(string);

// export const desktopMenuAnchorType = oneOfType([objectType]);
export const desktopMenuAnchorType = objectOf(object);

export const deleteAllGeneratedConvoMsgsReduxStateType = func;

export const deleteConversationType = func;

export const deleteDppMessageInFirestoreType = func;

export const deleteGeneratedConvoMsgType = func;

export const deleteGeneratedConvoMsgReduxStateType = func;

export const deleteNewInboxMessageAlertType = func;

export const deleteOtherDppSubmissionType = func;

export const deletePromptType = func;

export const deleteQueuedPromptType = func;

export const deleteSpecificNewConversationMessageAlertType = func;

export const dispatchManmadeErrorsType = func;

export const dppMessagesType = arrayOf(object);

export const editMessageFormVisibilityType = bool;

export const emailType = string;

export const entityKeyType = objectOf(object);

export const errorsType = objectOf(string);

export const errorTypeType = string;

export const expandType = bool;

export const expandAllKinkTypesType = func;

export const expandAllMessagesType = func;

export const expandKinkTypeType = func;

export const expandMessageType = func;

export const expandOrRetractAllMessagesType = func;

export const expandOrRetractConvoPromptType = func;

export const expandOrRetractFuncType = func;

export const expandOrRetractStringType = string;

export const favoriteType = objectOf(object);

export const feedbacksFilterType = shape({
  pageNumber: number,
  lastPage: bool
});

export const feedbacksType = objectOf(object);

export const fetchAuthUserType = func;

export const filterPromptsType = func;

export const filterType = shape({
  iam: string,
  lookingfor: string,
  category: string,
  tag: string,
  creator: string,
  published: bool,
  sort: string,
  pageNumber: number
});

export const flaggedUsersType = arrayOf(object);

export const flagPromptType = func;

export const flagUserType = func;

export const followingUserType = bool;

export const followType = shape({
  listenerLoaded: bool,
  followingUser: bool
});

export const followUserListenerType = func;

export const followUserType = func;

export const footerVisibilityType = bool;

export const generatedConvoMsgIdType = string;

export const generateConvoMsgType = func;

export const generatedConvoMsgsType = objectOf(object);

export const getAllConversationsType = func;

export const getAllDppMessagesType = func;

export const getAllTagsType = func;

export const getConversationsOfUserType = func;

export const getCrossPostedPromptsIdsType = func;

export const getFeedbacksType = func;

export const getFlaggedUsersType = func;

export const getGeneratedConvoMsgsType = func;

export const getInboxReceivedMessagesType = func;

export const getInboxSentMessagesType = func;

export const getMostPopularTagsType = func;

export const getMyBookmarksType = func;

export const getMyConversationsType = func;

export const getMyFollowersType = func;

export const getMyFollowingType = func;

export const getMyLovesType = func;

export const getMyStatsType = func;

export const getMyUserInfoPublicType = func;

export const getOtherDppSubmissionsType = func;

export const getOurUsersType = func;

export const getPromptsType = func;

export const getPromptType = func;

export const getQueuedPromptsType = func;

export const getUserInfoType = func;

export const getUserKinksType = func;

export const getUsersPrivateDocsType = func;

export const goToElementType = instanceOf(Element);

export const handleBookmarkPromptType = func;

export const handleBumpPromptType = func;

export const handleCancelUpdateType = func;

export const handleChangeLovesCountType = func;

export const handleChangeType = func;

export const handleClickType = func;

export const handleClickTagType = func;

export const handleCloseMenuType = func;

export const handleCreatePromptType = func;

export const handleCrossPostPromptType = func;

export const handleDeleteMessagesType = func;

export const handleDeletePromptType = func;

export const handleDotClickType = func;

export const handleEditPromptType = func;

export const handleExpandOrRetractType = func;

export const handleFollowButtonClickType = func;

export const handleGoToConvoType = func;

export const handleGoToNextLocationType = func;

export const handleGoToPageType = func;

export const handleLoggedOutButtonClickType = func;

export const handleLoggedOutLoveAndBookmarkType = func;

export const handleLogInType = func;

export const handleLogOutType = func;

export const handleLoveButtonType = func;

export const handleRedirectType = func;

export const handleRemoveType = func;

export const handleSaveDraftOrUnpublishType = func;

export const handleSaveDraftType = func;

export const handleSelectionType = func;

export const handleShareButtonType = func;

export const handleShareMenuCloseType = func;

export const handleSignUpType = func;

export const handleSocialLoginType = func;

export const handleTagsTextFieldType = func;

export const handleTextfieldType = func;

export const handleUnfollowButtonClickType = func;

export const handleUpdatePromptType = func;

export const headerDesktopVisibilityType = bool;

export const headerMobileVisibilityType = bool;

export const headerVisibilityType = bool;

export const hideType = bool;

export const historyType = shape({
  location: objectOf(string),
  push: func,
  goBack: func
});

export const inboxType = shape({
  moreReceivedMessages: bool,
  lastReceivedMessage: objectOf(string),
  receivedMessages: objectOf(string)
});

export const initialStateType = objectOf(object);

export const isConvoPromptRetractedType = bool;

export const isConvosForMyPromptVisibleType = func;

export const isEditMessageFormVisibleType = func;

export const isExpandedType = bool;

export const isFooterVisibleType = func;

export const isHeaderDesktopVisibleType = func;

export const isHeaderMobileVisibleType = func;

export const isPromptBottomMenuContainerVisibleType = func;

export const isPromptHeaderButtonVisibleType = func;

export const isSameUserType = bool;

export const isSignUpType = bool;

export const isSmallScreenFuncType = func;

export const isSmallScreenBoolType = bool;

export const isItMyPromptType = bool;

export const kinksStringType = shape({
  name: string,
  self: string,
  partner: string
});

export const kinksObjectType = objectOf(object);

export const kinksTypeObjectType = shape({ pov: arrayOf(string) });

export const kinkTypeType = string;

export const lastUpdatedType = shape({
  seconds: number,
  nanaoseconds: number,
  toMillis: func
});

export const likenessTypeType = string;

export const listenerLoadedType = bool;

export const loadingType = bool;

export const locationType = shape({
  pathname: string,
  state: objectOf(string)
});

export const loggedInType = bool;

export const loggedInOrNotType = bool;

export const loginType = func;

export const logoutType = func;

export const loveListenerType = func;

export const lovePromptType = func;

export const lovesCountType = number;

export const loveType = shape({
  loved: bool,
  listenerLoaded: bool,
  loveButtonClickPending: bool
});

export const marginTopType = bool;

export const matchType = shape({
  params: objectOf(string),
  path: string,
  url: string
});

export const messagesEndElementType = instanceOf(Element);

export const messagesType = objectOf(object);

export const messageType = string;

export const metaDescriptionType = string;

// export const mobileMenuAnchorType = oneOfType([objectType]);
export const mobileMenuAnchorType = objectOf(object);

export const mostPopularTagsType = arrayOf(string);

export const myBookmarksType = shape({
  myBookmarksList: objectOf(objectType),
  lastMyBookmark: shape({
    id: string,
    exists: bool
  }),
  moreMyBookmarks: bool
});

export const myFollowersListType = objectOf(object);

export const myFollowingListType = objectOf(object);

export const myKinkGroupsType = objectOf(object);

export const myKinksType = objectOf(object);

export const myLovesType = shape({
  myLovesList: objectOf(objectType),
  lastMyLove: shape({
    id: string,
    exists: bool
  }),
  moreMyLoves: bool
});

export const myPenpalsType = shape({
  moreMyFollowing: bool,
  moreMyFollowers: bool
});

export const myPromptsType = objectOf(object);

export const myStatsType = objectOf(number);

export const myUserType = shape({
  lookingforPreferences: objectOf(string),
  about: string,
  points: number,
  notifications: objectOf(bool)
});

export const moreConversationsType = bool;

// export const newConversationMessageAlertType = shape({ count: number });

export const newConversationMessageAlertType = objectOf(objectOf);

export const newConversationMessageAlertsType = arrayOf(object);

export const newConvoMsgCountType = number;

export const newMessageAlertCountType = number;

export const newStuffAlertsType = shape({
  newConversationMessageAlert: shape({
    documentId: string
  }),
  newPromptAlert: shape({
    count: number,
    documentId: string
  })
});

export const noType = objectOf(object);

export const onChangeType = func;

export const onCloseType = func;

export const openModalType = func;

export const openSnackbarType = func;

export const ourUsersType = object;

export const pageNameType = string;

export const pageNumberType = number;

export const pageTitleType = string;

export const participantDeletedType = bool;

export const placeholderType = string;

export const postGeneratedConvoMsgResponseType = func;

export const promptBottomMenuContainerVisibilityType = bool;

export const promptButtomMenuElementType = instanceOf(Element);

export const promptCreatorIDType = string;

export const promptCreatorType = string;

export const promptHeaderButtonVisibilityType = bool;

export const promptIDType = string;

export const promptLookingforType = string;

export const promptsType = objectOf(object);

export const promptTagsType = arrayOf(string);

export const promptTextType = string;

export const promptTitleType = string;

export const promptType = shape({
  id: string,
  title: string,
  text: oneOfType([string, object]),
  // text: shape({
  //   getCurrentContent: func,
  //   getSelection: func
  // }),
  creator: string,
  createdAt: createdAtType,
  published: bool,
  creatorID: string,
  iam: string,
  lookingfor: string,
  tags: arrayOf(string),
  lovesCount: number
});

export const providerDataType = arrayOf(object);

export const providerIdsIncludeGoogleType = bool;

export const providerIdsIncludePasswordType = bool;

export const publishedType = bool;

export const registerUserType = func;

export const removeBookmarkListenerType = func;

export const removeConversationInStateType = func;

export const removeConversationsInStateType = func;

export const removeFeedbackType = func;

export const removeFollowUserListenerType = func;

export const removeLoveListenerType = func;

export const removeMyPromptsInStateType = func;

export const removePromptInStateType = func;

export const removePromptsInStateType = func;

export const removeUserPageInStateType = func;

export const removeUserKinksInStateType = func;

export const renderCreateAPromptButtonType = func;

export const renderPromptMetaDescriptionType = func;

export const replyDppMessageType = func;

export const rerollGeneratedConvoMsgType = func;

export const resetErrorsType = func;

export const retractAllKinkTypesType = func;

export const retractAllMessagesType = func;

export const retractedKinksType = arrayOf(string);

export const retractedType = arrayOf(string);

export const retractKinkTypeType = func;

export const retractMessageType = func;

export const rollKinksForUserType = func;

export const sameUserType = bool;

export const scrollToTopType = bool;

export const selectedType = string;

export const sendDppMessageType = func;

export const senderType = string;

export const sendPasswordResetEmailType = func;

export const sizeType = number;

export const socialLoginType = func;

export const streakType = number;

export const streakWaitingForUserType = string;

export const submissionsType = arrayOf(object);

export const submitFeedbackType = func;

export const submitMyKinksType = func;

export const tagDescriptionType = string;

export const tagIDType = string;

export const tagIdType = string;

export const tagsType = arrayOf(string);

export const talkingToType = string;

export const textObjectType = objectOf(object);

export const textStringType = string;

export const toggleDesktopMenuType = func;

export const toggleMobileMenuType = func;

export const unbookmarkPromptType = func;

export const unfollowUserType = func;

export const unlovePromptType = func;

export const unsubscribeFromAuthType = func;

export const unsureType = objectOf(object);

export const updateEntirePromptReduxStateType = func;

export const updateGeneratedConvoMsgType = func;

export const updateGeneratedConvoMsgReduxStateType = func;

export const updateMyKinksReduxStateType = func;

export const updateMyPromptsReduxStateType = func;

export const updateMyUserType = func;

export const updatePromptReduxStateType = func;

export const updatePromptType = func;

export const updateQueuedPromptsType = func;

export const uploadPromptsType = func;

export const userConversationsType = objectOf(object);

export const userIDType = string;

export const userKinksType = objectOf(object);

export const userKinkGroupsType = objectOf(object);

export const usernameType = string;

export const userPageType = shape({
  loaded: bool,
  username: string,
  usernameLower: string,
  id: string,
  createdAt: createdAtType,
  points: number,
  about: string
});

export const usersPrivateDocsType = objectOf(object);

export const valueType = number;

export const variantType = oneOf(['success', 'warning', 'error', 'info']);

export const yesType = objectOf(object);
