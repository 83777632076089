import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import Badge from '@material-ui/core/Badge';
// import SearchBar from './SearchBar';
import { checkIfAdmin } from '../../utils/adminUsers';
import logoIcon from '../../images/logoIconDesktop.png';
import logoText from '../../images/logoText.png';
import {
  loggedInType,
  classesType,
  handleLogInType,
  handleSignUpType,
  handleLogOutType,
  authType,
  toggleDesktopMenuType,
  desktopMenuAnchorType,
  newConvoMsgCountType,
  isHeaderDesktopVisibleType,
  handleCloseMenuType
} from '../../types';

const styles = () => ({
  root: {
    flexGrow: 1
  },
  appbar: {
    backgroundColor: 'white' // changes the background color of app bar
    // the header is a flexbox with flex-direction of column
    // backgroundColor: theme.palette.primary.main
  },
  toolbar: {
    // allows the different elements on the header to wrap on smaller screens
    // without this the desktop header gets cut off if it is accidentally rendered on a mobile screen
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  logo: {
    flex: 1,
    padding: '0 1rem',
    '&:hover': {
      cursor: 'pointer'
    },
    '&:active': {
      cursor: 'pointer'
    },
    display: 'flex', // make the icon and the text into a single row
    alignItems: 'center'
  },
  logoIcon: { height: '1.5rem' },
  logoText: { height: '2rem' },
  desktopMenuButton: {},
  loginButton: { marginRight: '0.5rem' },
  signupButton: {
    marginRight: '2rem' // add some space to the right of logged out buttons
  }
});

const HeaderDesktop = props => {
  const {
    classes,
    loggedIn,
    handleCloseMenu,
    handleLogIn,
    handleSignUp,
    handleLogOut,
    auth,
    toggleDesktopMenu,
    desktopMenuAnchor,
    newConvoMsgCount,
    isHeaderDesktopVisible
  } = props;

  const renderNewConvoMsgCount = buttonText => {
    if (newConvoMsgCount && newConvoMsgCount !== 0) {
      return (
        <Badge badgeContent={newConvoMsgCount} color="primary">
          {buttonText}
        </Badge>
      );
    }
    return buttonText;
  };

  // if the user is logged In then render Profile, Conversations, Logout
  // if the user is logged Out then render Log In, Sign Up
  const renderButtons = () => {
    if (loggedIn) {
      return (
        <div className="LoggedInDesktopMenuItems">
          <Button
            component={Link}
            to="/conversations"
            onClick={handleCloseMenu}
            className={classes.desktopMenuButton}
          >
            {renderNewConvoMsgCount('Conversations')}
          </Button>
          <Button
            onClick={toggleDesktopMenu}
            aria-label="Menu"
            aria-owns={desktopMenuAnchor ? 'simple-menu' : null}
            aria-haspopup="true"
            className={classes.desktopMenuButton}
          >
            <AccountCircleIcon />
            {auth.currentUser.displayName}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={desktopMenuAnchor}
            open={Boolean(desktopMenuAnchor)}
            onClose={toggleDesktopMenu}
            disableAutoFocusItem
          >
            <MenuItem component={Link} to="/profile" onClick={handleCloseMenu}>
              Profile
            </MenuItem>
            <MenuItem component={Link} to="/settings" onClick={handleCloseMenu}>
              Settings
            </MenuItem>
            {auth &&
              auth.currentUser &&
              auth.currentUser.displayName &&
              checkIfAdmin(auth.currentUser.displayName) && (
                <MenuItem
                  component={Link}
                  to="/admin"
                  onClick={handleCloseMenu}
                >
                  Admin
                </MenuItem>
              )}
            <MenuItem onClick={handleLogOut}>Logout</MenuItem>
          </Menu>
        </div>
      );
    }
    return (
      <div className="LoggedOutDesktopMenuItems">
        <Button
          onClick={handleLogIn}
          className={classNames(classes.desktopMenuButton, classes.loginButton)}
        >
          Log In
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSignUp}
          className={classNames(
            classes.desktopMenuButton,
            classes.signupButton
          )}
        >
          Sign Up
        </Button>
      </div>
    );
  };

  return (
    <VisibilitySensor
      classNAme={classes.root}
      partialVisibility
      onChange={isVisible => {
        isHeaderDesktopVisible(isVisible);
      }}
    >
      <AppBar position="static" className={classes.appbar} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <Link to="/" className={classes.logo}>
            <img
              alt="EroPenPal Logo Icon"
              src={logoIcon}
              className={classes.logoIcon}
              width="26.838px"
              height="24px"
            />
            <img
              alt="EroPenPal Logo Text"
              src={logoText}
              className={classes.logoText}
              width="135.838px"
              height="32px"
            />
          </Link>
          <Button
            component={Link}
            to="/create"
            onClick={handleCloseMenu}
            className={classes.desktopMenuButton}
          >
            Create A Prompt
          </Button>
          {renderButtons()}
        </Toolbar>
      </AppBar>
    </VisibilitySensor>
  );
};

HeaderDesktop.propTypes = {
  loggedIn: loggedInType.isRequired,
  classes: classesType.isRequired,
  handleLogIn: handleLogInType,
  handleSignUp: handleSignUpType,
  handleLogOut: handleLogOutType,
  auth: authType,
  toggleDesktopMenu: toggleDesktopMenuType.isRequired,
  desktopMenuAnchor: desktopMenuAnchorType,
  newConvoMsgCount: newConvoMsgCountType,
  isHeaderDesktopVisible: isHeaderDesktopVisibleType.isRequired,
  handleCloseMenu: handleCloseMenuType.isRequired
};

HeaderDesktop.defaultProps = {
  handleLogIn: null,
  handleSignUp: null,
  handleLogOut: null,
  auth: null,
  desktopMenuAnchor: null,
  newConvoMsgCount: 0
};

export default withStyles(styles)(HeaderDesktop);
