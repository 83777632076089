// note that this is also used in promptpage/ConvosForMyPrompt.js
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { formatDistance } from 'date-fns';
// import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import CommentIcon from '@material-ui/icons/CommentOutlined';
import MessageIcon from '@material-ui/icons/MessageOutlined';
import Badge from '@material-ui/core/Badge';
import ConversationMessage from './ConversationMessage';
import {
  conversationIDType,
  promptTitleType,
  promptIDType,
  isItMyPromptType,
  talkingToType,
  createdAtType,
  messagesType,
  authType,
  classesType,
  handleGoToConvoType,
  isSmallScreenBoolType,
  newConversationMessageAlertType,
  participantDeletedType
} from '../../types';

const styles = theme => ({
  listItem: {
    display: 'flex',
    flexDirection: 'row'
    // '&:hover': { border: '1px solid black' } // adds a border when you hover over a conversation
  },
  pink: {
    color: theme.palette.primary.dark
  },
  conversationTitle: {
    color: theme.palette.text.meta
  },
  link: {
    color: theme.palette.text.linkDark,
    // color: grey[800],
    fontWeight: 'bold',
    textDecoration: 'none' // remove the underline of the link
  },
  // when it is 600px or less
  '@media (max-width: 37.5rem)': {
    conversationTitle: {
      fontSize: '14px'
    }
  }
});

const renderTalkingAbout = (newMessageAlertCount, isSmallScreen) => {
  if (!isSmallScreen) {
    return 'Talking about';
  }
  return (
    <Badge
      badgeContent={newMessageAlertCount}
      color="primary"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      Talking about
    </Badge>
  );
};

const ConversationSection = ({
  conversationID,
  promptTitle,
  promptID,
  isItMyPrompt,
  talkingTo,
  // talkingToID,
  createdAt,
  messages,
  auth,
  classes,
  handleGoToConvo,
  isSmallScreen,
  newConversationMessageAlert,
  participantDeleted
}) => {
  let newMessageAlertCount = 0;
  if (
    newConversationMessageAlert &&
    newConversationMessageAlert[conversationID]
  ) {
    newMessageAlertCount = newConversationMessageAlert[conversationID].count;
  }

  return (
    <ListItem
      className={classes.listItem}
      divider
      button
      onClick={handleGoToConvo(conversationID)}
    >
      {!isSmallScreen && (
        <ListItemIcon>
          {isItMyPrompt ? (
            <Badge badgeContent={newMessageAlertCount} color="primary">
              <CommentIcon className={classes.pink} fontSize="large" />
            </Badge>
          ) : (
            <Badge badgeContent={newMessageAlertCount} color="primary">
              <MessageIcon className={classes.pink} fontSize="large" />
            </Badge>
          )}
        </ListItemIcon>
      )}
      <ListItemText>
        <Typography variant="subtitle1" className={classes.conversationTitle}>
          {renderTalkingAbout(newMessageAlertCount, isSmallScreen)}
          <Link to={`/prompt/${promptID}`} className={classes.link}>
            {' '}
            {promptTitle}
          </Link>{' '}
          with{' '}
          {participantDeleted ? (
            '[deleted]'
          ) : (
            <Link to={`/user/${talkingTo}`} className={classes.link}>
              {talkingTo}
            </Link>
          )}
          {` started ${formatDistance(createdAt.toDate(), new Date()).replace(
            'about ',
            ''
          )} ago`}
        </Typography>
        <ConversationMessage
          messages={messages}
          auth={auth}
          talkingTo={talkingTo}
          participantDeleted={participantDeleted}
        />
      </ListItemText>
    </ListItem>
  );
};

ConversationSection.propTypes = {
  conversationID: conversationIDType.isRequired,
  promptTitle: promptTitleType.isRequired,
  promptID: promptIDType.isRequired,
  isItMyPrompt: isItMyPromptType.isRequired,
  talkingTo: talkingToType.isRequired,
  // talkingToID: PropTypes.string.isRequired,
  createdAt: createdAtType.isRequired,
  messages: messagesType,
  auth: authType.isRequired,
  classes: classesType.isRequired,
  handleGoToConvo: handleGoToConvoType.isRequired,
  isSmallScreen: isSmallScreenBoolType.isRequired,
  newConversationMessageAlert: newConversationMessageAlertType,
  participantDeleted: participantDeletedType
};

ConversationSection.defaultProps = {
  messages: {},
  newConversationMessageAlert: {},
  participantDeleted: null
};

const mapStateToProps = ({
  isSmallScreen,
  newStuffAlerts: { newConversationMessageAlert }
}) => ({
  isSmallScreen,
  newConversationMessageAlert
});

const actions = {};

const enhance = compose(connect(mapStateToProps, actions), withStyles(styles));

export default enhance(ConversationSection);
