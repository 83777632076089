// opened by handleBlockNavigation() of inboxpage/InboxCompose.js
// opened by handleBlockNavigation() of conversationpage/EditMessageForm
// 2 buttons: cancel the navigation or discard the changes and continue to next location
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { closeModal } from '../../actions';
import {
  closeModalType,
  handleGoToNextLocationType,
  classesType
} from '../../types';

const styles = {
  buttons: {
    paddingBottom: '1rem'
  }
};

const UnsubmittedMessageModal = props => {
  const handleDiscardChanges = () => {
    props.handleGoToNextLocation();
    props.closeModal();
  };

  return (
    <Dialog
      open
      onClose={props.closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="form-dialog-title">Unsaved Changes</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to discard these changes?
        </DialogContentText>
      </DialogContent>
      <DialogActions className={props.classes.buttons}>
        <Button
          onClick={props.closeModal}
          // color="primary"
          // variant="contained"
        >
          Cancel
        </Button>
        <Button
          onClick={handleDiscardChanges}
          color="primary"
          variant="contained"
        >
          Discard Message
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UnsubmittedMessageModal.propTypes = {
  closeModal: closeModalType.isRequired,
  handleGoToNextLocation: handleGoToNextLocationType.isRequired,
  classes: classesType.isRequired
};

const actions = { closeModal };

const enhance = compose(connect(null, actions), withStyles(styles));

export default enhance(UnsubmittedMessageModal);
