// reducer for getPrompts.js but for the authenticated user's own prompts!
// another difference between getPrompts and getMyPrompts is that
// getMyPrompts is NOT removed from the redux state when the page unmounts!
import {
  GET_MY_PROMPTS,
  RESET_MY_PROMPTS,
  UPDATE_MY_PROMPTS_REDUX_STATE
} from '../actions/types'; // import the action type

const initalState = {};

export default function(state = initalState, action) {
  switch (action.type) {
    case GET_MY_PROMPTS: {
      const { payload: prompts } = action;
      return prompts;
    }
    case RESET_MY_PROMPTS: {
      return initalState;
    }
    // used by AdminQueuedPrompts.js to chance queued prompts in redux state
    // note that queuedPrompts are saved in myPrompts redux state
    case UPDATE_MY_PROMPTS_REDUX_STATE: {
      const { promptId, fieldType, fieldValue } = action.payload;
      if (fieldType === 'tagClick') {
        return {
          ...state,
          [promptId]: {
            ...state[promptId],
            tags: [...state[promptId].tags, fieldValue]
          }
        };
      }
      // filters the soon-to-be deleted prompt out of the redux state
      if (fieldType === 'delete') {
        const filteredPrompts = {};
        Object.values(state).forEach(prompt => {
          if (prompt.id !== promptId) {
            filteredPrompts[prompt.id] = prompt;
          }
        });
        return filteredPrompts;
      }
      return {
        ...state,
        [promptId]: { ...state[promptId], [fieldType]: fieldValue }
      };
    }
    default:
      return state;
  }
}
