import React from 'react';
import { Editor, EditorState } from 'draft-js';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import { stateToHTML } from 'draft-js-export-html';
// import createLinkifyPlugin from 'draft-js-linkify-plugin';
// import addLinkPlugin from './plugins/addLinkPlugin';
import makeDecoratorFromPlugins from './plugins/makeDecoratorFromPlugins';
import { textObjectType, classesType } from '../../types';

// const linkifyPlugin = createLinkifyPlugin();
// const plugins = [linkifyPlugin, addLinkPlugin];

const styles = {
  root: {
    // fontSize: '16px' // 16px is the recommended size for reading on mobile device
  }
};

const TextEditorReadOnly = ({ text, classes }) => {
  const decorator = makeDecoratorFromPlugins();
  const editorState = EditorState.createWithContent(
    text.getCurrentContent(),
    decorator
  );
  return (
    /* We use Typography with body1 to make the text's font-size 16px, which is the
    recommended size on mobile. We use the component="div" so that it wouldn't create
    an error of having <div> inside <p> because default component of Typography is <p>
    and there are <div> inside of Editor component */
    <Typography variant="body1" component="div" className={classes.root}>
      <Editor editorState={editorState} readOnly />
    </Typography>
  );
};

// const TextEditorReadOnly = ({ text }) => {
//   const html = stateToHTML(text.getCurrentContent());
//   const createMarkup = () => ({ __html: html });
//   return (
//     <div>
//       <div dangerouslySetInnerHTML={createMarkup()} />
//     </div>
//   );
// };

TextEditorReadOnly.propTypes = {
  text: textObjectType.isRequired,
  classes: classesType.isRequired
};

export default withStyles(styles)(TextEditorReadOnly);
