// reducer for getPrompt.js
// got the single prompt data from firestore
// import merge from 'lodash/merge';
import {
  GET_PROMPT,
  UPDATE_PROMPT_REDUX_STATE,
  UPDATE_LOVES_PROMPT_REDUX_STATE
} from '../actions/types'; // import the action type
import freshPrompt from '../utils/freshPrompt';

const initalState = freshPrompt;

export default function(state = initalState, action) {
  switch (action.type) {
    // this gets the prompt from firestore
    case GET_PROMPT: {
      return action.payload;
    }
    // this updates the prompt field in the redux state
    case UPDATE_PROMPT_REDUX_STATE: {
      const { fieldType, fieldValue } = action.payload;
      if (fieldType === 'tagClick') {
        return {
          ...state,
          tags: [...state.tags, fieldValue]
        };
      }
      return { ...state, [fieldType]: fieldValue };
    }
    // create the illusion of incrementing / decrementing loves by 1
    case UPDATE_LOVES_PROMPT_REDUX_STATE: {
      return { ...state, lovesCount: action.payload };
    }
    default:
      return state;
  }
}
