// reducer for actions/errorsActions.js
import { FIREBASE_ERROR, RESET_ERRORS, MANMADE_ERRORS } from '../actions/types';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    // firebase error is usually a single error from firebase
    case FIREBASE_ERROR: {
      const { error } = action.payload;
      const errors = { ...error };
      return errors;
    }
    // manmade errors often come in as multiple errors
    case MANMADE_ERRORS: {
      const { errors } = action.payload;
      return { ...errors };
    }
    case RESET_ERRORS: {
      return initialState;
    }
    default:
      return state;
  }
};
