import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { authType, componentType, locationType } from '../../types';

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
  const handleRenderProps = props => {
    // triedAuth checks to make sure authentication is at least attempted before redirecting you
    // it's for when you hard refresh a page
    if (auth.triedAuth && auth.authenticated) {
      return <Component {...props} />;
    }
    if (auth.triedAuth && !auth.authenticated) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      );
    }
    return null;
  };

  return <Route {...rest} render={props => handleRenderProps(props)} />;
};

PrivateRoute.propTypes = {
  auth: authType.isRequired,
  component: componentType.isRequired,
  location: locationType.isRequired
};

const mapStateToProps = ({ auth }) => {
  return { auth };
};

const enhance = compose(connect(mapStateToProps, null));

export default enhance(PrivateRoute);
