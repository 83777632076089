// used by dispatchFirebaseError of errorActions action creator
// gets the error from authentication, modifies the error depending on it's code
// like if it should be displayed as an email error or password error, etc.
// used for both login and sign up and on settings page
// errors: https://firebase.google.com/docs/auth/admin/errors

export default error => {
  const { code, message } = error;
  switch (code) {
    case 'auth/invalid-email': {
      return {
        email: message
      };
    }
    case 'auth/email-already-exists': {
      return {
        email: message
      };
    }
    case 'auth/user-not-found': {
      return {
        email: 'There is no user record corresponding to this email.'
      };
    }
    case 'auth/email-already-in-use': {
      return {
        email: message
      };
    }
    case 'auth/wrong-password': {
      return {
        password: message
      };
    }
    case 'auth/invalid-password': {
      return {
        password: message
      };
    }
    case 'auth/weak-password': {
      return {
        password: message
      };
    }
    case 'permission-denied': {
      return {
        permission: message
      };
    }
    case 'auth/requires-recent-login': {
      return {
        reauth: message
      };
    }
    default:
      return {};
  }
};
