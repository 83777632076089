// current used to update loves,
// import { UPDATE_PROMPT } from './types';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';
import firebase, { firestore } from '../firebase';
import { openSnackbar } from './snackbarActions';
import { prepareEditorStateForStorage } from '../utils/convertEditorState';
import { updateMyUser } from './updateUserActions';
import { dispatchManmadeErrors, resetErrors } from './errorsActions';
import checkPromptObjectBefore, {
  checkPromptObjectBeforeDraft
} from '../utils/checkPromptObjectBefore';
import updateLookingforPreferences from '../utils/updateLookingforPreferences';

// // mininum time you have to wait between bumping each prompt (hours)
// const minTimePerPromptBumpHours = 10;
// minimum time you have to wait between each bump for an user (hours)
const minBumpTimeForUserHours = 20;

// bump the prompt to update it's bumpedAt field with the current date
export const bumpPrompt = promptID => async (dispatch, getState) => {
  // dispatch(asyncActionStart());
  try {
    const { uid: userId } = getState().auth.currentUser;
    const promptRef = firestore.collection('prompts').doc(promptID);
    const userPrivateRef = firestore.collection('usersPrivate').doc(userId);

    // get the current time in seconds
    const timeRightNow = Math.floor(Date.now() / 1000);

    // get the lastBumpPromptAt from the user's usersPrivate doc
    const userPrivateSnapshot = await userPrivateRef.get();
    const { lastBumpPromptAt } = userPrivateSnapshot.data();

    // if lastBumpPromptAt does not exist yet in userPrivate doc yet, we set lastBumpPromptAtSeconds to 0
    let lastBumpPromptAtSeconds = 0;
    if (lastBumpPromptAt) {
      lastBumpPromptAtSeconds = lastBumpPromptAt.seconds;
    }
    // find the amount of time (in seconds) from now, that needs to be greater than
    // lastBumpPromptAtSeconds, in order to pass the if statement below
    const minBumpTimeForUserSeconds = minBumpTimeForUserHours * 60 * 60;
    const minTimeLapsedForBump = timeRightNow - minBumpTimeForUserSeconds;

    // if bump is currently unavailable,
    // calculate how much time we need to bump again
    const timeToNextBumpSeconds =
      lastBumpPromptAtSeconds + minBumpTimeForUserSeconds - timeRightNow;
    const timeToNextBumpMinutes = timeToNextBumpSeconds / 60;
    const hours = Math.floor(timeToNextBumpMinutes / 60);
    const minutes = Math.floor(timeToNextBumpMinutes % 60);
    const timeToNextBumpString = `${hours} hours and ${minutes} minutes`;
    const timeToNextBumpStringMod = timeToNextBumpString
      .replace(/^0 hours and /, '')
      .replace(/^1 hours/, '1 hour')
      .replace(' 1 minutes', ' 1 minute')
      .replace('0 minutes', 'a few seconds');

    // if minTimeLapsedForBump is greater than bumped time then we'll bump the prompt
    if (minTimeLapsedForBump >= lastBumpPromptAtSeconds) {
      await promptRef.update({
        bumpedAt: firebase.firestore.FieldValue.serverTimestamp()
      });
      await userPrivateRef.update({
        lastBumpPromptAt: firebase.firestore.FieldValue.serverTimestamp()
      });
    } else {
      // if the minTimeLapsedForBump is less than bumped time then we need to wait by throwing the error
      throw new Error(
        `Too soon! Please wait ${timeToNextBumpStringMod} to bump again!`
        // You can bump a prompt once every ${minBumpTimeForUserHours} hours.`
      );
    }
    // dispatch(asyncActionFinish());
    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: 'Successfully bumped the prompt to the top of front page!',
        autoHideDuration: 8000 // auto hide the snackbar longer than usual
      })
    );
  } catch (error) {
    // const { code, message } = error;
    const { message } = error;
    console.log(error);
    // dispatch(asyncActionError({ code, message }));
    dispatch(
      openSnackbar('ErrorSnackbar', { message, autoHideDuration: 8000 }) //  auto hide the snackbar longer than usual
    );
  }
};

export const updatePrompt = (prePromptObject, checkForError = true) => async (
  dispatch,
  getState
) => {
  dispatch(asyncActionStart());
  try {
    // creates a brand new promptObject to NOT mutate anything on original one!
    const promptObject = { ...prePromptObject };
    // creates or updates tagsLower with the latest tags,
    // tagsLower is used to fetch prompts based on tags in a case agnostic way
    promptObject.tagsLower = promptObject.tags.map(tag => tag.toLowerCase());

    // checks the promptObject to make sure it does not have empty title or text or other deficiencies
    // throws an error when it does not pass
    // checkForError is false when we are reverting the prompt back to a draft
    // so we do a mild version of error checking, checking only if the title is correct
    if (checkForError) {
      checkPromptObjectBefore(promptObject);
    } else {
      checkPromptObjectBeforeDraft(promptObject);
    }
    // prepare text (draftjs text editor state) ready for database storage
    promptObject.text = prepareEditorStateForStorage(promptObject.text);

    const promptRef = firestore.collection('prompts').doc(promptObject.id);

    // get the current promptData to check its published field
    const oldPrompt = await promptRef.get();
    const oldPromptData = oldPrompt.data();
    let newPromptObject = {};
    // check if we are publishing this prompt for the first time,
    // if so, update the publishedAt field to the current time
    if (oldPromptData.publishedAt === null && promptObject.published === true) {
      console.log('publishing for the first time!');
      newPromptObject = {
        ...promptObject,
        publishedAt: firebase.firestore.FieldValue.serverTimestamp()
      };
    } else {
      newPromptObject = {
        ...promptObject
      };
    }

    await promptRef.update({
      ...newPromptObject,
      editedAt: firebase.firestore.FieldValue.serverTimestamp()
    });

    // if the prompt's lookingfor and iam are different from myUser's lookingforPreferences
    // then update the lookingforPreferences in the user's document in usersPRivate collection
    updateLookingforPreferences({
      getState,
      promptObject,
      dispatch,
      updateMyUser
    });

    // if prompt is successfully updated, remove all errors from redux state
    dispatch(resetErrors());

    dispatch(asyncActionFinish());
    console.log('updated prompt');
    return 'success';
  } catch (error) {
    // if the error is manmade, made in checkPromptObjectBefore.js
    if (error.type === 'manmade') {
      dispatch(dispatchManmadeErrors(error));
    } else {
      // if the error is generated by firebase
      const { code, message } = error;
      console.log(error);
      dispatch(asyncActionError({ code, message }));
      dispatch(openSnackbar('ErrorSnackbar', { message }));
    }
  }
  return null;
  // dispatch({ type: UPDATE_PROMPT, payload: promptObject });
};
