/* eslint new-cap: ["error", { "newIsCap": false }] */
import sample from 'lodash/sample';
import firebase, { firestore } from '../firebase';
import {
  GET_DPP_MESSAGES,
  GET_CROSS_POSTED_PROMPTS,
  GET_OTHER_DPP_SUBMISSIONS,
  DELETE_OTHER_DPP_SUBMISSION
} from './types';
import { retreievePlainTextAfterStorage } from '../utils/convertEditorState';
import genderToAbbreviation from '../utils/genderToAbbreviation';
import { openSnackbar } from './snackbarActions';

// delete the specific doc from otherDppSubmissions collection
// note that this doesn't affect the otherDPP, it's just for our own firestore
export const deleteOtherDppSubmission = submissionId => async dispatch => {
  console.log(`Deleting ${submissionId}`);
  const otherDppSubmissionRef = firestore
    .collection('otherDppSubmissions')
    .doc(submissionId);

  await otherDppSubmissionRef.delete();
  dispatch({
    type: DELETE_OTHER_DPP_SUBMISSION,
    payload: { submissionId }
  });
};

// send a message to a DPP user, used by AdminOtherDppSubmissions.js component
// we are only using 1 user to send the messages
// after sending a message, record that DPP user and delete the otherDppSubmission
// from the otherDppSubmissions collection and redux state
export const sendDppMessage = ({
  snoowrap,
  messageReceiver,
  replyMessage,
  submissionId
}) => async dispatch => {
  console.log({ messageReceiver });
  console.log({ replyMessage });
  // const dppUser = {
  //   username: 'mistress_dpp',
  //   clientId: 'l6Tm071QR3NMJw',
  //   clientSecret: '8WGCiD2WuRarSjlT_k2aA_FoAkv0UQ',
  //   refreshToken: '475075670839-X3u1P6d3NSwCLcEiqFNi0JZ8DL4rBg'
  // };
  const dppUser = {
    username: 'beauty_and_beauty',
    clientId: 'QDzt3D0om2GTWg',
    clientSecret: 'g5ZFuLIUoJpO-MA8n3Tg5j1wqqX2Gw',
    refreshToken: '969084516938-XJGsotN_UoPIaVOLp4jpfMm-QyYQyg'
  };
  const { clientId, clientSecret, refreshToken } = dppUser;
  const r = new snoowrap({
    userAgent: 'random string here',
    clientId,
    clientSecret,
    refreshToken
  });
  const result = await r.composeMessage({
    to: messageReceiver,
    subject: 'I love your post!',
    text: replyMessage
  });
  console.log({ result });

  const messagedSubPostersRef = firestore
    .collection('misc')
    .doc('messagedSubPosters');
  const increment = firebase.firestore.FieldValue.increment(1);
  await messagedSubPostersRef.set(
    {
      [messageReceiver.toLowerCase()]: {
        username: messageReceiver,
        count: increment,
        lastMessaged: firebase.firestore.FieldValue.serverTimestamp()
      }
    },
    { merge: true }
  );
  if (submissionId) {
    await dispatch(deleteOtherDppSubmission(submissionId));
  }
};

// get all of the docs from otherDppSubmissions, which are submissions posted on
// the other DPP (official / most popular one)
export const getOtherDppSubmissions = () => async dispatch => {
  try {
    const otherDppSubmissionsRef = firestore.collection('otherDppSubmissions');
    const otherDppSubmissionsSnapshot = await otherDppSubmissionsRef
      .orderBy('createdAt', 'desc')
      .limit(10)
      .get();
    const otherDppSubmissionsDocs = otherDppSubmissionsSnapshot.docs.map(
      otherDppSubmissionSnapshot => {
        const otherDppSubmissionsDoc = otherDppSubmissionSnapshot.data();
        return otherDppSubmissionsDoc;
      }
    );

    // get the usernames of all of the users (posters) that we've messaged previously
    const messagedSubPostersRef = firestore
      .collection('misc')
      .doc('messagedSubPosters');
    const messagedSubPostersSnapshot = await messagedSubPostersRef.get();
    const messagedSubPosters = messagedSubPostersSnapshot.data() || {};
    console.log({ messagedSubPosters });

    // modify submissions based on user info from messagedSubPosters
    const submissions = otherDppSubmissionsDocs.map(otherDppSubmissionsDoc => {
      const { author } = otherDppSubmissionsDoc;
      const authorLower = author.toLowerCase();
      let previousMessagesToAuthorCount;
      let lastMessagedAuthor;
      if (messagedSubPosters[authorLower]) {
        const { count, lastMessaged } = messagedSubPosters[authorLower];
        previousMessagesToAuthorCount = count;
        lastMessagedAuthor = lastMessaged;
      }
      return {
        ...otherDppSubmissionsDoc,
        previousMessagesToAuthorCount,
        lastMessagedAuthor
      };
    });
    console.log({ submissions });
    dispatch({
      type: GET_OTHER_DPP_SUBMISSIONS,
      payload: { submissions }
    });
  } catch (error) {
    console.log({ error });
  }
};

// get dppUsers doc from misc collection
// used by replyDppMessage() and crossPostPrompt() here
const getDppUsers = async () => {
  const dppUsersRef = firestore.collection('misc').doc('dppUsers');
  const dppUsersSnapshot = await dppUsersRef.get();
  const dppUsersData = dppUsersSnapshot.data();
  return dppUsersData;
};

// get the ids of posts that have already been posted to DPP
export const getCrossPostedPromptsIds = () => async dispatch => {
  try {
    const crossPostedPromptsRef = firestore
      .collection('misc')
      .doc('crossPostedPrompts');
    const crossPostedPromptsSnapshot = await crossPostedPromptsRef.get();
    const crossPostedPromptsIds = crossPostedPromptsSnapshot.data();
    dispatch({
      type: GET_CROSS_POSTED_PROMPTS,
      payload: { crossPostedPromptsIds }
    });
  } catch (error) {
    console.log({ error });
  }
};

// get all of the messages from dppMessagesForUsers
export const getAllDppMessages = () => async dispatch => {
  // try {
  // get all of the repliedToDppUsers to see which senders of messages we've
  // already replied to and the number of message we've sent to them previously
  const repliedToDppUsersRef = firestore
    .collection('misc')
    .doc('repliedToDppUsers');
  const repliedToDppUsersDoc = await repliedToDppUsersRef.get();
  let repliedToDppUsers = repliedToDppUsersDoc.data();
  if (!repliedToDppUsers) {
    repliedToDppUsers = {};
  }
  console.log({ repliedToDppUsers });

  console.log('getting all dppMessages');
  const dppMessagesRef = firestore.collectionGroup('dppMessages');
  const dppMessagesSnapshot = await dppMessagesRef.get();
  const dppMessagesDocs = dppMessagesSnapshot.docs;
  const dppMessages = dppMessagesDocs.map(dppMessageDoc => {
    const dppMessageData = dppMessageDoc.data();
    const dppMessageSender = dppMessageData.sender;
    // number of messages we've sent to the message sender previously
    const numberOfRepliesSentToMsgSender =
      repliedToDppUsers[dppMessageSender] || 0;

    return { ...dppMessageData, numberOfRepliesSentToMsgSender };
  });
  console.log({ dppMessages });
  dispatch({
    type: GET_DPP_MESSAGES,
    payload: {
      dppMessages
    }
  });
  // } catch (error) {
  //   console.log({ error });
  // }
};

// reply to the dpp message the user has received
export const replyDppMessage = (
  dppReceiver,
  dppMessageId,
  dppMessageSender,
  dppMessageReplyText,
  snoowrap
) => async dispatch => {
  try {
    const dppUsers = await getDppUsers();

    const dppUsersFlattened = {};
    Object.values(dppUsers).forEach(dppUsersInEachCategory => {
      Object.values(dppUsersInEachCategory).forEach(dppUser => {
        dppUsersFlattened[dppUser.username] = dppUser;
      });
    });
    console.log({ dppUsersFlattened });
    const dppUser = dppUsersFlattened[dppReceiver];
    const { clientId, clientSecret, refreshToken } = dppUser;
    const r = new snoowrap({
      userAgent: 'random string here',
      clientId,
      clientSecret,
      refreshToken
    });
    const result = await r.getMessage(dppMessageId).reply(dppMessageReplyText);

    // make a record that we've replied to that user before
    // increase that count by 1
    const repliedToDppUsersRef = firestore
      .collection('misc')
      .doc('repliedToDppUsers');
    const increment = firebase.firestore.FieldValue.increment(1);
    await repliedToDppUsersRef.set(
      { [dppMessageSender]: increment },
      { merge: true }
    );

    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: 'Successfully cross-posted!'
      })
    );
    return result;
  } catch (error) {
    console.log({ error });
    dispatch(
      openSnackbar('ErrorSnackbar', {
        message: { error }
      })
    );
    return null;
  }
};

// deletes the dppMessage doc in firestore
// Does Not Delete it in DPP
export const deleteDppMessageInFirestore = (
  dppUsername,
  dppMessageId
) => async dispatch => {
  try {
    const dppMessageRef = firestore
      .collection('dppMessagesForUsers')
      .doc(dppUsername)
      .collection('dppMessages')
      .doc(dppMessageId);
    console.log({ dppUsername, dppMessageId });
    await dppMessageRef.delete();
    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: `Successfully deleted the message ${dppMessageId}`
      })
    );
  } catch (error) {
    console.log({ error });
    dispatch(
      openSnackbar('ErrorSnackbar', {
        message: { error }
      })
    );
  }
};

// posts the prompt to DPP
export const crossPostPrompt = (promptId, snoowrap) => async dispatch => {
  try {
    console.log({ promptId });
    const promptRef = firestore.collection('prompts').doc(promptId);
    const promptSnapshot = await promptRef.get();
    const promptData = promptSnapshot.data();
    const {
      title: preTitle,
      text: preprocessedText,
      iam,
      lookingfor
    } = promptData;
    const text = retreievePlainTextAfterStorage(preprocessedText);
    const iamAbbreviation = genderToAbbreviation(iam);
    const lookingforAbbreviation = genderToAbbreviation(lookingfor);
    const title = `[${iamAbbreviation}4${lookingforAbbreviation}] ${preTitle}`;
    console.log({ title, text });

    // pick the dpp user with the right iam and lookingfor
    const iamLookingforAbbreviationsLowered = `${iamAbbreviation.toLowerCase()}4${lookingforAbbreviation.toLowerCase()}`;
    const dppUsers = await getDppUsers();
    console.log({ dppUsers });
    const groupOfUsers = dppUsers[iamLookingforAbbreviationsLowered];
    const groupOfUsersArray = Object.values(groupOfUsers);
    const chosenUser = sample(groupOfUsersArray);
    console.log({ chosenUser });
    const { clientId, clientSecret, refreshToken } = chosenUser;
    const r = new snoowrap({
      userAgent: 'random string here',
      clientId,
      clientSecret,
      refreshToken
    });
    r.submitSelfpost({
      subredditName: 'dirtypenpalsus',
      title,
      text
    }).then(console.log);

    // add the id of the cross posted prompt into the doc with all of the
    // cross posted prompts
    const crossPostedPromptsRef = firestore
      .collection('misc')
      .doc('crossPostedPrompts');
    await crossPostedPromptsRef.update({ [promptId]: promptId });

    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: 'Successfully cross-posted!'
      })
    );
  } catch (error) {
    console.log({ error });
    dispatch(
      openSnackbar('ErrorSnackbar', {
        message: { error }
      })
    );
  }
};
