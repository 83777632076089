// adds a feedback document to the firestore
import { GET_FEEDBACKS, DELETE_FEEDBACK, FILTER_FEEDBACKS } from './types';
import firebase, { firestore } from '../firebase';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';
import { resetErrors, dispatchManmadeErrors } from './errorsActions';
import { openSnackbar } from './snackbarActions';
import checkEmailBefore from '../utils/checkEmailBefore';
import { createFeedbacksQuery } from '../utils/createFeedbacksQuery';

export const getFeedbacks = feedbacksFilter => async (dispatch, getState) => {
  dispatch(asyncActionStart());
  try {
    let feedbacksFilterMod = feedbacksFilter;
    if (!feedbacksFilter) {
      feedbacksFilterMod = getState().feedbacksFilter;
    }
    const feedbacksRef = firestore.collection('feedbacks');

    const query = createFeedbacksQuery(feedbacksRef, feedbacksFilterMod);
    const querySnapshots = await query.get();

    // get the lastFeedback for feedbacksFilter in redux state
    const lastFeedback = querySnapshots.docs[querySnapshots.docs.length - 1];

    const feedbacks = {};
    querySnapshots.docs.forEach(doc => {
      const { id } = doc;
      const data = doc.data();
      feedbacks[id] = { ...data, id };
    });

    dispatch({ type: GET_FEEDBACKS, payload: { feedbacks } });

    // // 10 feedbacks then we are Not on last page
    // // fewer than 10 feedbacks then we are on last page
    let lastPage;
    if (querySnapshots.docs.length === 10) {
      lastPage = false;
    } else {
      lastPage = true;
    }

    dispatch({
      type: FILTER_FEEDBACKS,
      payload: {
        feedbacksFilter: {
          ...feedbacksFilterMod,
          lastFeedback,
          previousOrNext: null,
          lastPage
        }
      }
    });

    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
  return null;
};

export const submitFeedback = feedback => async (dispatch, getState) => {
  dispatch(asyncActionStart());
  try {
    const { email, message } = feedback;
    const {
      uid: currentUserID,
      email: currentUserEmail,
      displayName
    } = getState().auth.currentUser;
    const feedbackObject = {
      message,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    };
    // currentUserID exists if the user is authenticated
    // if they are NOT logged in
    if (!currentUserID) {
      // check if email entered is valid
      const errors = checkEmailBefore(email);
      if (errors) {
        throw errors;
      }
      feedbackObject.email = email;
    } else {
      feedbackObject.email = currentUserEmail;
      feedbackObject.userID = currentUserID;
      feedbackObject.username = displayName;
    }
    const feedbackRef = firestore.collection('feedbacks');
    const createdFeedback = await feedbackRef.add(feedbackObject);
    const feedbackId = createdFeedback.id;

    // if prompt is successfully updated, remove all errors from redux state
    dispatch(resetErrors());
    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: 'Message sent successfully!'
      })
    );
    dispatch(asyncActionFinish());
    return feedbackId;
  } catch (error) {
    console.log({ error });
    // if the error is manmade, made in checkEmailBefore.js
    if (error.type === 'manmade') {
      dispatch(dispatchManmadeErrors(error));
    } else {
      // if the error is generated by firebase
      const { code, message } = error;
      dispatch(asyncActionError({ code, message }));
      dispatch(openSnackbar('ErrorSnackbar', { message }));
    }
  }
  return null;
};

// remove the feedback from the firestore's feedbacks collection
export const removeFeedback = feedbackID => async dispatch => {
  dispatch(asyncActionStart());
  try {
    const feedbackRef = firestore.collection('feedbacks').doc(feedbackID);
    // delete feedback from the firestore
    await feedbackRef.delete();

    // update the feedbacks redux state by removing the recently deleted feedback
    dispatch({ type: DELETE_FEEDBACK, payload: { feedbackID } });

    dispatch(asyncActionFinish());
    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: 'Successfully deleted the feedback!'
      })
    );
  } catch (error) {
    const { code, message } = error;
    console.log(error);
    dispatch(asyncActionError({ code, message }));
    dispatch(openSnackbar('ErrorSnackbar', { message }));
  }
};
