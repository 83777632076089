// prod.js don't commit this!

// this is eropenpal-prod
export const firebaseConfig = {
  apiKey: 'AIzaSyAYGJwOty_Tyq3cTB6R3xNDLaQZHmOw8jQ',
  authDomain: 'eropenpal-prod.firebaseapp.com',
  databaseURL: 'https://eropenpal-prod.firebaseio.com',
  projectId: 'eropenpal-prod',
  storageBucket: 'eropenpal-prod.appspot.com',
  messagingSenderId: '423345502685',
  appId: '1:423345502685:web:19767b2f230beb70fb1e34',
  measurementId: 'G-4MJ1KV82L1'
};

// this is mdp-test-433e7
// export const firebaseConfig = {
//   apiKey: 'AIzaSyBHqIo1e1rB4V2mtLVRISCpLpzP8W5EAHY',
//   authDomain: 'mdp-test-433e7.firebaseapp.com',
//   databaseURL: 'https://mdp-test-433e7.firebaseio.com',
//   projectId: 'mdp-test-433e7',
//   storageBucket: 'mdp-test-433e7.appspot.com',
//   messagingSenderId: '657721824668'
// };
