// menu at the bottom of the prompt.
// this is the container component
import React, { Component } from 'react';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import PromptBottomMenu from './PromptBottomMenu';
import {
  updatePrompt,
  bookmarkPrompt,
  unbookmarkPrompt,
  bookmarkListener,
  removeBookmarkListener,
  lovePrompt,
  unlovePrompt,
  loveListener,
  removeLoveListener,
  openModal
} from '../../actions';
import {
  bookmarkPromptType,
  unbookmarkPromptType,
  bookmarkListenerType,
  removeBookmarkListenerType,
  lovePromptType,
  unlovePromptType,
  loveListenerType,
  removeLoveListenerType,
  openModalType,
  promptType,
  authType,
  bookmarkType,
  loveType,
  historyType,
  matchType
} from '../../types';

class PromptBottomMenuContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      anchorEl: null
    };

    // there are 2 mechanisms to prevent the buttons from being spam clicked
    // Throttling and loveButtonClickPending / bookmarkButtonClickPending
    // adjust throttling time and where LOVE/BOOKMARK BUTTON_CLICK_PENDING_FINISH is dispatched to prevent spam click
    this.handleLoveButtonThrottled = throttle(this.handleLoveButton, 500);
    this.handleBookmarkPromptThrottled = throttle(
      this.handleBookmarkPrompt,
      500
    );
  }

  componentDidMount() {
    this.activateBookmarkListener();
    this.activateLoveListener();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth !== this.props.auth) {
      this.activateBookmarkListener();
      this.activateLoveListener();
    }
  }

  componentWillUnmount() {
    this.props.removeBookmarkListener();
    this.props.removeLoveListener();
  }

  // changes the currently selected button based on the value number
  handleChange = (e, value) => {
    this.setState({ value });
  };

  handleEditPrompt = () => {
    const { prompt, history } = this.props;
    history.push(`/edit/${prompt.id}`);
  };

  // calls the action creator that listens if the prompt is bookmarked
  // IF the user is logged in
  activateBookmarkListener = () => {
    const {
      auth,
      bookmark,
      match: { params }
    } = this.props;
    if (auth.authenticated && !bookmark.listenerLoaded) {
      const { promptID } = params;
      this.props.bookmarkListener(promptID);
    }
  };

  handleBookmarkPrompt = () => {
    const { bookmark, prompt } = this.props;
    const { id, creator, title } = prompt;
    // only allow the activation of the bookmark button IF bookmarkButtonClickPending is false!
    if (bookmark.bookmarkButtonClickPending === false) {
      if (bookmark.bookmarked) {
        this.props.unbookmarkPrompt(id);
      } else {
        this.props.bookmarkPrompt({ id, creator, title });
      }
    }
  };

  // calls the action creator that listens if the prompt is loved
  // IF the user is logged in
  activateLoveListener = () => {
    const {
      auth,
      love,
      match: { params }
    } = this.props;
    if (auth.authenticated && !love.listenerLoaded) {
      const { promptID } = params;
      this.props.loveListener(promptID);
    }
  };

  handleLoveButton = () => {
    const { love, prompt } = this.props;
    const { id, creator, title } = prompt;
    // only allow the activation of the love button IF loveButtonClickPending is false!
    if (love.loveButtonClickPending === false) {
      if (love.loved) {
        this.props.unlovePrompt(id);
      } else {
        this.props.lovePrompt({ id, creator, title });
      }
    }
  };

  handleShareButton = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleShareMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLoggedOutLoveAndBookmark = () => {
    this.props.openModal('SignUpModal');
  };

  render() {
    const { value, anchorEl } = this.state;
    const { bookmark, love, prompt, auth } = this.props;
    const {
      lovesCount,
      id: promptID,
      title: promptTitle,
      tags: promptTags
    } = prompt;
    return (
      <PromptBottomMenu
        sameUser={auth.currentUser.uid === prompt.creatorID}
        value={value}
        lovesCount={lovesCount || 0}
        promptID={promptID}
        handleChange={this.handleChange}
        handleEditPrompt={this.handleEditPrompt}
        handleBookmarkPrompt={this.handleBookmarkPromptThrottled}
        alreadyBookmarked={bookmark.bookmarked}
        handleLoveButton={this.handleLoveButtonThrottled}
        alreadyLoved={love.loved}
        anchorEl={anchorEl}
        handleShareButton={this.handleShareButton}
        handleShareMenuClose={this.handleShareMenuClose}
        handleLoggedOutLoveAndBookmark={this.handleLoggedOutLoveAndBookmark}
        loggedInOrNot={auth.authenticated}
        promptTitle={promptTitle}
        promptTags={promptTags}
        promptCreatorID={prompt.creatorID}
        promptCreator={prompt.creator}
      />
    );
  }
}

PromptBottomMenuContainer.propTypes = {
  bookmarkPrompt: bookmarkPromptType.isRequired,
  unbookmarkPrompt: unbookmarkPromptType.isRequired,
  bookmarkListener: bookmarkListenerType.isRequired,
  removeBookmarkListener: removeBookmarkListenerType.isRequired,
  lovePrompt: lovePromptType.isRequired,
  unlovePrompt: unlovePromptType.isRequired,
  loveListener: loveListenerType.isRequired,
  removeLoveListener: removeLoveListenerType.isRequired,
  openModal: openModalType.isRequired,
  prompt: promptType.isRequired,
  auth: authType.isRequired,
  bookmark: bookmarkType.isRequired,
  love: loveType.isRequired,
  history: historyType.isRequired,
  match: matchType.isRequired
};

const actions = {
  updatePrompt,
  bookmarkPrompt,
  unbookmarkPrompt,
  bookmarkListener,
  removeBookmarkListener,
  lovePrompt,
  unlovePrompt,
  loveListener,
  removeLoveListener,
  openModal
};

const mapStateToProps = ({ bookmark, love, auth }) => ({
  bookmark,
  love,
  auth
});

export default connect(mapStateToProps, actions)(PromptBottomMenuContainer);
