// if the prompt's lookingfor and iam are different from myUser's lookingforPreferences
// then update the lookingforPreferences in the user's document in usersPrivate collection
// takes getState, promptObject, dispatch and updateMyUser as arguments
// and outputs dispatch(updateMyUser) or nothing
// used by updatePrompt.js and createPrompt.js
export default ({ getState, promptObject, dispatch, updateMyUser }) => {
  const {
    iam: oldIam,
    lookingfor: oldLookingfor
    // category: oldCategory
  } = getState().myUser.lookingforPreferences;
  // const { iam, lookingfor, category } = promptObject;
  const { iam, lookingfor } = promptObject;
  if (
    oldIam !== iam ||
    oldLookingfor !== lookingfor
    // || oldCategory !== category
  ) {
    console.log('dispatching an update to update my user info');
    dispatch(
      updateMyUser(
        // { lookingforPreferences: { iam, lookingfor, category } },
        { lookingforPreferences: { iam, lookingfor } },
        'lookingforPreferences'
      )
    );
  }
};
