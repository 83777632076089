/* eslint global-require: "off" */
/* eslint import/no-unresolved: [2, { ignore: ['./prod'] }] */
// figure out what set of credentials to return
// Notes:
// development runs on your computer and uses development database (development firebase project: eropenpal-dev)
// staging is a live website but uses the database from development (development firebase project: eropenpal-dev)
// production is a live website that uses it's own database (production firebase project: eropenpal-prod)

if (
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_STAGING === 'true'
) {
  // this is for staging, REACT_APP_STAGING=true is used when you run "yarn deploy-staging"
  module.exports = require('./staging');
} else if (process.env.NODE_ENV === 'production') {
  // this is for production, when you run "yarn deploy"
  module.exports = require('./prod');
} else {
  // we are in development, return the dev keys
  // when you run "yarn start"
  module.exports = require('./dev');
}
