/* eslint no-restricted-syntax: ["error", "FunctionExpression", "WithStatement", "BinaryExpression[operator='in']"] */
// above code removes an error: https://eslint.org/docs/rules/no-restricted-syntax
// THIS SCRIPT IS IMPORTANT! - NOT A PLUGIN
// make the decorator from plugins to pass into the EditorState.createEmpty or EditorState.createWithContent()
// if we don't do this links and other decorators would not show up correctly in
// both the editor view and the readonly view, you'll need to hard reload the page for it to show up in editor view
// From https://github.com/draft-js-plugins/draft-js-plugins/issues/401
// input is an array of plugins and output is the decorator constant
// defaultPlugins are used if no plugins array arguement is supplied
// currently used by utils/freshPrompt.js for editor view (currently not using it!)
// and texteditor/TextEditorReadOnly.js for prompt view
import { CompositeDecorator } from 'draft-js';
import MultiDecorator from 'draft-js-plugins-editor/lib/Editor/MultiDecorator';

// import createLinkifyPlugin from 'draft-js-linkify-plugin';
// import createEmojiPlugin from 'draft-js-emoji-plugin';
import addLinkPlugin from './addLinkPlugin';

// const linkifyPlugin = createLinkifyPlugin();
// const emojiPlugin = createEmojiPlugin();

// const defaultPlugins = [linkifyPlugin, addLinkPlugin];
const defaultPlugins = [addLinkPlugin];
// const defaultPlugins = [];

const makeDecoratorFromPlugins = (plugins = defaultPlugins) => {
  function getPluginDecoratorArray() {
    let decorators = [];
    let plugin;
    // check each plugin that will be used in the editor for decorators
    // (retrieve listOfPlugins however makes sense in your code)
    for (plugin of plugins) {
      if (plugin.decorators !== null && plugin.decorators !== undefined) {
        // if the plugin has any decorators, add them to a list of all decorators from all plugins
        decorators = decorators.concat(plugin.decorators);
      }
    }
    return decorators;
  }

  function myFunctionForGrabbingAllPluginDecorators() {
    // I can't quite remember why I had to wrap things in this exact way, but found that I ran into
    // errors if I did not both have a MultiDecorator and a CompositeDecorator wrapping
    // This MultiDecorator can now be used as shown in my previous post.
    return new MultiDecorator([
      new CompositeDecorator(getPluginDecoratorArray())
    ]);
  }

  const decorator = myFunctionForGrabbingAllPluginDecorators();

  return decorator;
};

export default makeDecoratorFromPlugins;
