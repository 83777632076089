// reducer for getMyLoves in lovePromptActions.js action creators
// updates the redux state for myLoves for profilepage/ProfileLoves.js
import { GET_MY_LOVES, DELETE_MY_LOVE } from '../actions/types';

const initialState = {
  lastMyLove: null,
  myLovesList: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_LOVES: {
      const { myLovesList, lastMyLove, moreMyLoves } = action.payload;
      return { ...state, myLovesList, lastMyLove, moreMyLoves };
    }
    case DELETE_MY_LOVE: {
      const { promptID } = action.payload;
      const { myLovesList } = state;
      const filteredMyLovesList = {};
      Object.keys(myLovesList).forEach(loveKey => {
        if (loveKey !== promptID) {
          const prompt = myLovesList[loveKey];
          filteredMyLovesList[loveKey] = prompt;
        }
      });
      return { ...state, myLovesList: { ...filteredMyLovesList } };
    }
    default:
      return state;
  }
};
