// transforms input gender into it's corresponding icon
// currently we are not using icons, we just change gender to 1 capitalized letter
export default gender => {
  switch (gender) {
    case 'male':
      return 'M';
    case 'female':
      return 'F';
    case 'transman':
      return 'TM';
    case 'transwoman':
      return 'TF';
    case 'non-binary':
      return 'NB';
    case 'all':
      return 'A';
    default:
      return '??';
  }
};
