// listener that listens for changes in newConversationAlert, newConversationMessageAlert,
// newInboxMessageAlert and newPromptAlert.
import firebase, { firestore } from '../firebase';
import { UPDATE_NEW_STUFF_ALERTS } from './types';

export const newStuffAlertsListener = () => async (dispatch, getState) => {
  const userID = getState().auth.currentUser.uid;
  try {
    const newStuffAlertsRef = firestore
      .collection('usersPrivate')
      .doc(userID)
      .collection('newStuffAlerts');

    const unsubscribeNewStuffAlertsListener = await newStuffAlertsRef.onSnapshot(
      snapshot => {
        const newStuffAlerts = {};
        snapshot.docs.forEach(doc => {
          const newStuffAlertId = doc.id;
          const newStuffAlertData = doc.data();
          newStuffAlerts[newStuffAlertId] = {
            ...newStuffAlertData
            // id: newStuffAlertId
          };
        });

        dispatch({
          type: UPDATE_NEW_STUFF_ALERTS,
          payload: {
            unsubscribeNewStuffAlertsListener,
            newStuffAlerts
          }
        });
      }
    );
  } catch (error) {
    console.log(error);
  }
};

// delete the conversation object in newConversationMessageAlert matching the conversationId
export const deleteSpecificNewConversationMessageAlert = conversationId => async (
  dispatch,
  getState
) => {
  const userID = getState().auth.currentUser.uid;
  try {
    const newConversationMessageAlertRef = firestore
      .collection('usersPrivate')
      .doc(userID)
      .collection('newStuffAlerts')
      .doc('newConversationMessageAlert');

    await newConversationMessageAlertRef.update({
      [conversationId]: firebase.firestore.FieldValue.delete()
    });
  } catch (error) {
    console.log({ error });
  }
};

// deletes newInboxMessageAlert after visiting inboxpage/InboxMessages.js
export const deleteNewInboxMessageAlert = () => async (dispatch, getState) => {
  const userID = getState().auth.currentUser.uid;
  try {
    const newInfoboxMessageAlertRef = firestore
      .collection('usersPrivate')
      .doc(userID)
      .collection('newStuffAlerts')
      .doc('newInboxMessageAlert');

    await newInfoboxMessageAlertRef.delete();
  } catch (error) {
    console.log({ error });
  }
};
