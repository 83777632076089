// Note: https://stackoverflow.com/questions/50707211/warning-it-looks-like-youre-using-the-development-build-of-the-firebase-js-sdk
// https://firebase.google.com/docs/web/setup
import firebase from 'firebase/app'; // import the base app that is required
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';
import 'firebase/firestore'; // import firestore
import 'firebase/auth'; // import firebase authentication
// import 'firebase/storage' //import firebase cloud storage
import { firebaseConfig } from './config/keys';

firebase.initializeApp(firebaseConfig);
firebase.analytics();
const firestore = firebase.firestore();
// const settings = {
//   timestampsInSnapshots: true
// };
// firestore.settings(settings);

export { firestore, firebase as default };
