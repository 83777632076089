// checks the promptObject before submitting it to the firestore
// works for /create and /edit
// returns an error if the promptObject does not pass the inspection
// when you make changes here, be sure to make the same changes in
// promptValidation() in Firestore Security Rules

export default promptObject => {
  const { title, text, iam, lookingfor } = promptObject;
  const errors = {};
  if (title.length === 0) {
    errors.title = 'Title is empty!';
  } else if (title.length < 6) {
    errors.title = 'Title is too short!';
  }
  if (title.length > 300) {
    errors.title = 'Title has to be 300 characters or less!';
  }
  let modText = text;
  if (typeof text === 'object') {
    modText = text.getCurrentContent().getPlainText();
  }
  if (modText.length === 0) {
    errors.text = 'Text is missing! Write something!';
  } else if (modText.length < 6) {
    errors.text = 'Text is too short! Write more!';
  }
  const genders = [
    'all',
    'male',
    'female',
    'transwoman',
    'transman',
    'non-binary'
  ];
  if (!genders.includes(iam)) {
    errors.iam = 'I am is invalid';
  }
  if (!genders.includes(lookingfor)) {
    errors.lookingfor = 'Looking for is invalid';
  }
  if (Object.keys(errors).length === 0) {
    return null;
  }
  errors.type = 'manmade';
  throw errors;
};

// checks the prompt before it is saved as a draft
// makes sure the prompt has a title of at least 6 characters
export const checkPromptObjectBeforeDraft = promptObject => {
  const { title } = promptObject;
  const errors = {};
  if (title.length === 0) {
    errors.title = 'Title is empty!';
  } else if (title.length < 6) {
    errors.title = 'Title is too short!';
  }
  if (title.length > 300) {
    errors.title = 'Title has to be 300 characters or less!';
  }
  if (Object.keys(errors).length === 0) {
    return null;
  }
  errors.type = 'manmade';
  throw errors;
};
