// get the auth'd user's following and followers subcollections' counts (usersPrivate)
// used in profilepage/ProfilePenpals
import { firestore } from '../firebase';
import { GET_MY_FOLLOWING, GET_MY_FOLLOWERS } from './types';

// get the followingCount doc in following subcollection of auth'd user (usersPrivate)
export const getMyFollowing = getNewMyFollowing => async (
  dispatch,
  getState
) => {
  try {
    const user = getState().auth.currentUser;
    const userID = user.uid;
    const currentMyFollowingList = getState().myPenpals.myFollowingList;

    const myFollowingRef = firestore
      .collection('usersPrivate')
      .doc(userID)
      .collection('following')
      .orderBy('createdAt', 'desc')
      .limit(10);

    const currentLastMyFollowing = getState().myPenpals.lastMyFollowing;

    let modMyFollowingRef;
    if (getNewMyFollowing) {
      modMyFollowingRef = myFollowingRef;
    } else {
      modMyFollowingRef = myFollowingRef.startAfter(currentLastMyFollowing);
    }

    const myFollowingSnapshot = await modMyFollowingRef.get();

    const myFollowingList = {};
    myFollowingSnapshot.docs.forEach(doc => {
      const data = doc.data();
      myFollowingList[doc.id] = { ...data };
    });

    let modMyFollowingList;
    if (getNewMyFollowing) {
      modMyFollowingList = { ...myFollowingList, ...currentMyFollowingList };
    } else {
      modMyFollowingList = { ...currentMyFollowingList, ...myFollowingList };
    }

    let lastMyFollowing;
    let moreMyFollowing;
    if (getNewMyFollowing && currentLastMyFollowing) {
      lastMyFollowing = currentLastMyFollowing;
      const currentMoreMyFollowing = getState().myPenpals.moreMyFollowing;
      moreMyFollowing = currentMoreMyFollowing;
    } else {
      lastMyFollowing =
        myFollowingSnapshot.docs[myFollowingSnapshot.docs.length - 1];
      if (myFollowingSnapshot.docs.length === 10) {
        moreMyFollowing = true;
      } else {
        moreMyFollowing = false;
      }
    }

    dispatch({
      type: GET_MY_FOLLOWING,
      payload: {
        myFollowingList: modMyFollowingList,
        lastMyFollowing,
        moreMyFollowing
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// export const removeMyFollowingReduxState = () => async dispatch => {};

// get the followersCount doc in the followers subcollection of auth'd user (usersPrivate)
export const getMyFollowers = getNewMyFollowers => async (
  dispatch,
  getState
) => {
  try {
    const user = getState().auth.currentUser;
    const userID = user.uid;
    const currentMyFollowersList = getState().myPenpals.myFollowersList;

    const myFollowersRef = firestore
      .collection('usersPrivate')
      .doc(userID)
      .collection('followers')
      .orderBy('createdAt', 'desc')
      .limit(10);

    const currentLastMyFollower = getState().myPenpals.lastMyFollower;

    let modMyFollowersRef;
    if (getNewMyFollowers) {
      modMyFollowersRef = myFollowersRef;
    } else {
      modMyFollowersRef = myFollowersRef.startAfter(currentLastMyFollower);
    }

    const myFollowersSnapshot = await modMyFollowersRef.get();
    const myFollowersList = {};
    myFollowersSnapshot.docs.forEach(doc => {
      const data = doc.data();
      myFollowersList[doc.id] = { ...data };
    });

    let modMyFollowersList;
    if (getNewMyFollowers) {
      modMyFollowersList = { ...myFollowersList, ...currentMyFollowersList };
    } else {
      modMyFollowersList = { ...currentMyFollowersList, ...myFollowersList };
    }

    let lastMyFollower;
    let moreMyFollowers;
    if (getNewMyFollowers && currentLastMyFollower) {
      lastMyFollower = currentLastMyFollower;
      const currentMoreMyFollowers = getState().myPenpals.moreMyFollowers;
      moreMyFollowers = currentMoreMyFollowers;
    } else {
      lastMyFollower =
        myFollowersSnapshot.docs[myFollowersSnapshot.docs.length - 1];
      if (myFollowersSnapshot.docs.length === 10) {
        moreMyFollowers = true;
      } else {
        moreMyFollowers = false;
      }
    }

    dispatch({
      type: GET_MY_FOLLOWERS,
      payload: {
        myFollowersList: modMyFollowersList,
        lastMyFollower,
        moreMyFollowers
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// export const removeMyFollowersReduxState = () => async dispatch => {};
