import { createMuiTheme } from '@material-ui/core/styles';
// import amber from '@material-ui/core/colors/amber';

/*
Tools:
Color Tool: https://material.io/tools/color/
Color Hex: https://www.color-hex.com/color/f9eed9
*/

// default
// export const customMuiTheme = createMuiTheme();

// sexy redish color from https://www.bustle.com/articles/22110-color-science-reveals-which-shades-are-perceived-as-sexy-sad-calm-and-confident
// export const customMuiTheme = createMuiTheme({
//   typography: {
//     useNextVariants: true
//   },
//   palette: {
//     primary: {
//       // light: '#fcf8ef',
//       main: '#cd2144'
//       // dark: ''
//     },
//     secondary: {
//       main: '#C01823'
//     },
//     // play around with text color
//     text: {
//       secondary: amber[900]
//     }
//   }
// });

// pink color theme (glossier)
// export const customMuiTheme = createMuiTheme({
//   typography: {
//     useNextVariants: true
//   },
//   palette: {
//     primary: {
//       // shruti's color
//       main: '#e3687d',
//       // light: '#fcf8ef',
//       // main: '#f6e3e7',
//       dark: '#ecc5ce' // darker pink
//     },
//     secondary: {
//       main: '#e3f6f2',
//       dark: '#c5ece4'
//     },
//     // play around with text color
//     text: {
//       // secondary: amber[900]
//     }
//   }
// });
//
// pink skin colored theme (provacateur)
export const customMuiTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    title: {
      fontSize: '24px',
      fontWeight: '400',
      letterSpacing: 'normal',
      lineHeight: '2rem'
    },
    title2: {
      fontSize: '20px',
      fontWeight: '500',
      letterSpacing: '0.15px',
      lineHeight: '2rem'
    },
    titleTab: {
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '0.4px',
      lineHeight: '24.5px'
    },
    titleMobile: {
      fontSize: '20px',
      fontWeight: '400',
      letterSpacing: 'normal',
      lineHeight: '26.68px'
    }
  },
  palette: {
    primary: {
      // shruti's color
      // main: '#e3687d',
      // main: '#fb5202',
      // light: '#fcf8ef',
      main: '#feede6',
      // main: '#feded3' darker pink
      // dark: '#fbb89c' // darker pink
      // dark: '#faaa89'
      dark: '#fcccb8',
      superDark: '#f9976f'
    },
    secondary: {
      // main: '#A4A7CB'
      main: '#e6f7fe'
    },
    // play around with text color
    text: {
      meta: '#888888', // grey color used be meta text of promptsList, from reddit
      meta2: 'rgba(0, 0, 0, 0.54)', // grey color used by textfields of material ui, on login and sign up modals
      link: '#0079d3', // navy blue color used by reddit
      linkDark: '#336699' // dark blue color used by certain old subreddits like /r/ddp, /r/relationship_advice
      // secondary: amber[900]
    },
    background: {
      tabs: '#feede6'
    }
  }
});

// yellow skin colored theme
// export const customMuiTheme = createMuiTheme({
//   palette: {
//     primary: {
//       // light: '#fcf8ef',
//       main: '#f9eed9',
//       // dark: '#f6e4c3'
//       dark: '#f2daad'
//       // dark: '#efd097'
//     },
//     secondary: {
//       main: '#C01823'
//     },
//     // play around with text color
//     text: {
//       secondary: amber[900]
//     }
//   }
// });

/*
primary colors: #f9eed9
secondary colors that compliment #f9eed9: #d9e4f9
that literary site uses #C01823
*/
