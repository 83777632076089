// action creators for both conversation and conversations
// get conversation from firestore, create (start) conversation for a prompt
// get conversations from firestore
import firebase, { firestore } from '../firebase';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';
import {
  GET_CONVERSATION,
  REMOVE_CONVERSATION_REDUX_STATE,
  UPDATE_CONVERSATIONS_REDUX_STATE,
  REMOVE_CONVERSATIONS_REDUX_STATE
} from './types';
// import mergeSortedConvosArrays from '../utils/mergeSortedConvosArrays';
import { getConversationsMessages } from './messagesActions';
import { openSnackbar } from './snackbarActions';
import { dispatchFirebaseError } from './errorsActions';

// create the a new conversation doc based on prompt and the auth'd user
// dispatch the new conversationID as the conversationObject
// we return conversationID so PromptPage.js can redirect user to the conversation
export const createConversation = promptObject => async (
  dispatch,
  getState
) => {
  dispatch(asyncActionStart());
  try {
    const {
      promptID,
      title: promptTitle,
      creator: promptCreator,
      creatorID: promptCreatorID,
      text: promptText,
      creatorDeleted
    } = promptObject;

    // check if the creator of the prompt is deleted / creatorDeleted is true
    // if creatorDeleted is true, do not create the conversation, send a error snackbar instead
    if (creatorDeleted) {
      const message = "The prompt creator's account has been deleted!";
      dispatch(
        openSnackbar('ErrorSnackbar', {
          message,
          autoHideDuration: 12000
        })
      );
      dispatch(asyncActionError());
      return null;
    }

    const currentUserID = getState().auth.currentUser.uid;
    const currentUserDisplayname = getState().auth.currentUser.displayName;
    const participants = [promptCreator, currentUserDisplayname];
    const participantsIDs = [promptCreatorID, currentUserID];
    const conversationRef = firestore.collection('conversations');
    const result = await conversationRef.add({
      promptID,
      promptTitle,
      conversationStarter: currentUserDisplayname,
      conversationStarterID: currentUserID,
      promptCreator,
      promptCreatorID,
      participants,
      participantsIDs,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      promptText,
      streak: 0, // number of current streaks
      // streakTimeLeft: firebase.firestore.FieldValue.serverTimestamp(), // time left to keep the streak going
      streakWaitingForUser: null // waiting for this user to keep the streak going
    });
    const conversationID = result.id;

    dispatch(asyncActionFinish());
    return conversationID;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    return null;
  }
};

// get the conversation doc and also (this replaces getConversation() action creator)
// listen to the change in current conversation doc
// more precisely, listen to changes in the streak field
export const conversationListener = conversationID => async (
  dispatch,
  getState
) => {
  dispatch(asyncActionStart());
  try {
    const conversationRef = firestore
      .collection('conversations')
      .doc(conversationID);

    const unsubscribe = conversationRef.onSnapshot(
      doc => {
        // this catches the error if the doc DOES NOT EXIST
        // because we are in onSnapshot, we cannot catch a thrown error, that's
        // why we are dispatching error from here directly
        // we create a fake firebase permission error to match the error from below
        // to keep it uniform
        if (!doc.exists) {
          const error = {
            code: 'permission-denied',
            message: 'Missing or insufficient permissions.'
          };
          dispatch(asyncActionError());
          dispatch(dispatchFirebaseError(error));
          return null;
        }
        const oldStreak = getState().conversation.streak;

        const newStreak = doc.data().streak;
        if (oldStreak !== newStreak) {
          const conversationData = doc.data();
          const conversationObject = {
            ...conversationData,
            conversationID,
            unsubscribeToConversationListener: unsubscribe,
            conversationListenerLoaded: true
          };
          dispatch({ type: GET_CONVERSATION, payload: { conversationObject } });
        }
        return true;
      },
      // this catches the error if the user doesn't have permission to view a doc
      // because we are in onSnapshot, we cannot catch a thrown error, that's
      // why we are dispatching error from here directly
      error => {
        dispatch(asyncActionError());
        dispatch(dispatchFirebaseError(error));
      }
    );
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
  }
};

// check if the conversation with that prompt and the currently auth'd user exists
// if it exists then directly update the conversation redux state and go to the conversation page
// if it does Not exist, then call createConversation()
// after getting conversationID, return it back to PromptPage.js so PromptPage can
// redirect the user to the conversation page based on the conversationID
export const checkConversation = promptID => async (dispatch, getState) => {
  dispatch(asyncActionStart());
  try {
    const currentUserID = getState().auth.currentUser.uid;
    // check if there is already a conversation with that promptID and currentUserID
    const conversationQuery = firestore
      .collection('conversations')
      .where('promptID', '==', promptID)
      // .where('conversationStarterID', '==', currentUserID); //this line doesn't work because of security rules
      .where('participantsIDs', 'array-contains', currentUserID);
    const conversationSnapshot = await conversationQuery.get();
    // if conversationSnapshot is empty that means the conversation does Not exist
    // call the createConversation to make a new one
    if (conversationSnapshot.empty) {
      const promptRef = firestore.collection('prompts').doc(promptID);
      const promptSnapshot = await promptRef.get();
      const promptData = promptSnapshot.data();
      const promptObject = { ...promptData, promptID };
      return dispatch(createConversation(promptObject));
    }
    // otherwise, the conversation exists so we get the conversation and go to the page
    const conversationData = conversationSnapshot.docs[0].data();
    const conversationID = conversationSnapshot.docs[0].id;
    const conversationObject = { ...conversationData, conversationID };
    dispatch({ type: GET_CONVERSATION, payload: { conversationObject } });
    dispatch(asyncActionFinish());
    return conversationID;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    return null;
  }
};

// //This is currently NOT used. We use conversationListener instead!
// // get the existing conversation from firestore based on the conversationID
// export const getConversation = conversationID => async dispatch => {
//   try {
//     const conversationRef = firestore
//       .collection('conversations')
//       .doc(conversationID);
//     const conversationSnapshot = await conversationRef.get();
//     const conversationData = conversationSnapshot.data();
//     const conversationObject = { ...conversationData, conversationID };
//     dispatch({ type: GET_CONVERSATION, payload: { conversationObject } });
//   } catch (error) {
//     console.log(error);
//   }
// };

// resets the conversation redux state
// also unsubscribes from both conversationListener and conversationMessagesListener from state
export const removeConversationInState = () => async (dispatch, getState) => {
  try {
    const {
      unsubscribeToConversationListener,
      unsubscribeToMessagesListener
    } = getState().conversation;
    if (unsubscribeToConversationListener) {
      await unsubscribeToConversationListener();
    }
    if (unsubscribeToMessagesListener) {
      await unsubscribeToMessagesListener();
    }

    dispatch({ type: REMOVE_CONVERSATION_REDUX_STATE });
  } catch (error) {
    console.log(error);
  }
};

export const getMyConversations = (searchConversationsObject = {}) => async (
  dispatch,
  getState
) => {
  dispatch(asyncActionStart());
  try {
    // // searchText and searchType are used for searching in SearchConversationsPage.js (/search_conversations/:searchID)
    // const { searchText, searchType, promptID } = searchConversationsObject;
    const { promptID } = searchConversationsObject;
    const userID = getState().auth.currentUser.uid;
    const currentMyConversations = getState().conversations.myConversations;

    // get the conversations that I am involved in
    let myConversationsQuery = firestore
      .collection('conversations')
      .where('participantsIDs', 'array-contains', userID)
      .orderBy('lastUpdated', 'desc')
      .limit(10);

    // if promptID is passed in as an argument, get the conversations with that promptID
    if (promptID) {
      myConversationsQuery = myConversationsQuery.where(
        'promptID',
        '==',
        promptID
      );
    }

    // get the lastConversation from state, note that this is the
    // last conversation fetched from firestore and NOT the last conversation you've started
    // if it exists then find conversations after it,
    const currentLastConversation = getState().conversations.lastConversation;
    let modMyConversationsQuery;
    if (currentLastConversation) {
      modMyConversationsQuery = myConversationsQuery.startAfter(
        currentLastConversation
      );
    } else {
      modMyConversationsQuery = myConversationsQuery;
    }

    const myConversationsSnapshot = await modMyConversationsQuery.get();
    const myConversationsArray = myConversationsSnapshot.docs.map(doc => {
      const convoID = doc.id;
      const convoData = doc.data();
      return { ...convoData, conversationID: convoID };
    });

    // dispatch the action to get the messages for that conversation
    myConversationsArray.forEach(myConversation => {
      const { conversationID } = myConversation;
      dispatch(getConversationsMessages(conversationID, 'desc', 1));
    });

    // make myConversations into an object
    const preMyConversations = {};
    myConversationsArray.forEach(myConversation => {
      preMyConversations[myConversation.conversationID] = myConversation;
    });

    // merge the myConversations already in state with the newly fetched myConversations
    const myConversations = {
      ...currentMyConversations,
      ...preMyConversations
    };

    // get the lastConversation from the recently fetched conversations
    const lastConversation =
      myConversationsSnapshot.docs[myConversationsSnapshot.docs.length - 1];

    // see if there are any more conversations left in the firestore
    let moreConversations;
    if (myConversationsSnapshot.docs.length === 10) {
      moreConversations = true;
    } else {
      moreConversations = false;
    }

    dispatch({
      type: UPDATE_CONVERSATIONS_REDUX_STATE,
      payload: { myConversations, lastConversation, moreConversations }
    });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

// export the testConversations in test_content/exampleConversations to firestore
// This works only when corresponding users and prompts are already in firestore
// used in testpage/TestPage.js
export const exportConversations = conversationObjects => async () => {
  dispatch(asyncActionStart());
  try {
    await Object.values(conversationObjects).forEach(async value => {
      console.log({ value });

      const promptQuery = firestore
        .collection('prompts')
        .where('title', '==', value.promptTitle);
      const promptResult = await promptQuery.get();
      const prompt = promptResult.docs[0].data();
      const promptID = promptResult.docs[0].id;
      console.log({ prompt });

      const userQuery = firestore
        .collection('usersPublic')
        .where('usernameLower', '==', value.conversationStarter.toLowerCase());
      const userResult = await userQuery.get();
      const user = userResult.docs[0].data();
      const userID = userResult.docs[0].id;
      console.log({ user });
      const newConversation = {
        promptTitle: value.promptTitle,
        promptID,
        promptCreator: prompt.creator,
        promptCreatorID: prompt.creatorID,
        conversationStarter: user.username,
        conversationStarterID: userID,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      };
      await firestore.collection('conversations').add(newConversation);
    });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

// reset the conversations in redux state to initialState ({})
export const removeConversationsInState = () => dispatch => {
  dispatch({ type: REMOVE_CONVERSATIONS_REDUX_STATE });
};

// allow an user to "delete" the conversation by removing it's
// username and userId from participants and participantsIDs arrays
// of the conversation doc, also makes either promptCreator or conversationStarter
// null depends on who has deleted the conversation
export const deleteConversation = conversationId => async (
  dispatch,
  getState
) => {
  dispatch(asyncActionStart());
  try {
    const userId = getState().auth.currentUser.uid;
    const username = getState().auth.currentUser.displayName;
    const conversationRef = firestore
      .collection('conversations')
      .doc(conversationId);

    await firestore.runTransaction(async transaction => {
      const conversationDoc = await transaction.get(conversationRef);
      const {
        participants,
        participantsIDs,
        conversationStarterID,
        promptCreatorID
      } = conversationDoc.data();
      const participantsMod = participants.filter(participant => {
        return participant.toLowerCase() !== username;
      });
      const participantsIDsMod = participantsIDs.filter(participantId => {
        return participantId !== userId;
      });
      if (conversationStarterID === userId) {
        const conversationStarterIDMod = null;
        await transaction.update(conversationRef, {
          participants: participantsMod,
          participantsIDs: participantsIDsMod,
          conversationStarterID: conversationStarterIDMod
        });
      } else if (promptCreatorID === userId) {
        const promptCreatorIDMod = null;
        await transaction.update(conversationRef, {
          participants: participantsMod,
          participantsIDs: participantsIDsMod,
          promptCreatorID: promptCreatorIDMod
        });
      }
    });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};
