import React from 'react';
import StartConversationButton from '../commoncomponents/StartConversationButton';
import BumpPromptButton from './BumpPromptButton';
import NotPublishedButton from './NotPublishedButton';
import { promptType } from '../../types';

const PromptHeaderButton = ({ prompt }) => {
  const { id, creatorID, published } = prompt;
  return (
    <>
      <StartConversationButton promptID={id} creatorID={creatorID} />
      {published ? (
        <BumpPromptButton promptID={id} creatorID={creatorID} />
      ) : (
        <NotPublishedButton promptID={id} />
      )}
    </>
  );
};

PromptHeaderButton.propTypes = {
  prompt: promptType.isRequired
};

export default PromptHeaderButton;
