// sanitize the tags array before saving it to the firestore
// properly split up the tags and remove all the white spaces and empty strings
// also removes all duplicates
import uniq from 'lodash/uniq';

export default tags => {
  const tagsInput = [...tags];
  // for tags that were not split up (still strings), split them up via comma
  const splitUpTags = [];
  tagsInput.forEach(tag => {
    splitUpTags.push(...tag.split(','));
  });
  // for each tag remove white space to the left and right (trim())
  // then remove any extra white space by making multiple consecutive white spaces into 1 space
  // then remove any forward slashes (/)
  const trimmedTags = splitUpTags.map(tag =>
    tag
      .trim()
      .replace(/\s+/g, ' ')
      .replace(/\//g, '')
  );

  // remove any empty strings
  const filteredTags = trimmedTags.filter(tag => tag !== '');

  // use lodash to remove all duplicates from the array
  const uniqueTags = uniq(filteredTags);

  return uniqueTags;
};
