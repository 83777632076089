// create firestore query based on the filter fields
import { numberOfPromptsPerFetch } from './constants';

export const createPromptsQuery = (initialRef, filter, getState = null) => {
  const {
    iam,
    lookingfor,
    // type,
    // category,
    tag,
    creator,
    published,
    sort,
    lastPrompt,
    previousOrNext,
    hide
  } = filter;
  let ref = initialRef;

  // hides the prompt from view, usually because the prompt was flagged
  ref = ref.where('hide', '==', hide);

  if (iam && iam !== 'all') {
    ref = ref.where('iam', '==', iam);
  }
  if (lookingfor && lookingfor !== 'all') {
    // ref = ref.where('lookingfor', '==', lookingfor);
    ref = ref.where('lookingfor', 'in', [lookingfor, 'all']);
  }
  // if (type && type !== 'All') {
  //   ref = ref.where('type', '==', type);
  // }
  // if (category && category !== 'all') {
  //   ref = ref.where('category', '==', category);
  // }
  if (tag && tag !== 'all') {
    ref = ref.where('tagsLower', 'array-contains', tag.toLowerCase());
  }
  if (creator && creator !== 'all') {
    ref = ref.where('creatorLower', '==', creator.toLowerCase());
  }
  if (published === true) {
    ref = ref.where('published', '==', published);
  } else if (published === false) {
    ref = ref.where('published', '==', published);
    const userID = getState().auth.currentUser.uid;
    ref = ref.where('creatorID', '==', userID);
  }

  if (sort && sort !== 'none') {
    if (sort === 'hot') {
      // console.log('sorted by hot');
      ref = ref.orderBy('bumpedAt', 'desc');
    } else if (sort === 'new') {
      ref = ref.orderBy('publishedAt', 'desc');
    } else if (sort === 'top') {
      ref = ref.orderBy('lovesCount', 'desc');
    } else if (sort === 'bump') {
      ref = ref.orderBy('bumpedAt', 'desc');
    } else if (sort === 'old') {
      ref = ref.orderBy('publishedAt', 'asc');
    }
  }

  if (lastPrompt && previousOrNext) {
    if (previousOrNext === 'previous') {
      ref = ref.endAt(lastPrompt);
    } else if (previousOrNext === 'next') {
      ref = ref.startAfter(lastPrompt);
    }
  }

  return ref.limit(numberOfPromptsPerFetch);
};
