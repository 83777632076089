// fresh filter for filtering prompts

const freshFilter = {
  iam: 'all',
  lookingfor: 'all',
  category: 'all',
  tag: 'all',
  creator: 'all',
  published: true,
  sort: 'hot',
  lastPrompt: null, // the last prompt getPrompt fetched
  // if next, get the next set of prompts
  // if previous, get the previous set of prompts
  previousOrNext: null,
  // pageNumber decides if we should show Previous button or not, and
  // shows what part of prompts list we are in i.e. (11 - 20 for pageNumber of 2)
  pageNumber: 1,
  // if there are no more prompts after this page then lastPage is true
  // it controls the rendering of Next button
  lastPage: false,
  // hides the prompt from view, usually because the prompt was flagged
  hide: false
};

export default freshFilter;
