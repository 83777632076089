// uses react-helmet that manages <head>...</head> information of a page
// Page Title
// page title is the main link text for your website that shows up in google
// length of page title:
// Meta Description
// meta description is the short snipit of text that shows up under your website's
// link and page title
// length of meta description: about 155 - 160 characters, can be more
// also creates indicates its canonical url as itself with the <link> tag
import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import {
  pageTitleType,
  metaDescriptionType,
  matchType,
  promptLookingforType
} from '../../types';

const HeadManager = ({
  pageTitle,
  metaDescription,
  match: { url: uri },
  promptLookingfor
}) => {
  const url = `https://eropenpal.com${uri}`;
  const renderTitle = () => {
    if (url.includes('/prompt/')) {
      return (
        <title>{`${promptLookingfor} ${pageTitle} - EroPenPal: Erotic Roleplay and Naughty Penpals`}</title>
      );
    }
    if (url.includes('/user/')) {
      return (
        <title>{`${pageTitle} - User Profile - EroPenPal: Erotic Roleplay and Naughty Penpals`}</title>
      );
    }
    if (url.includes('/tag/')) {
      return (
        <title>{`${pageTitle} - Tag Page - EroPenPal: Erotic Roleplay and Naughty Penpals`}</title>
      );
    }
    return (
      <title>{`${pageTitle} - EroPenPal: Erotic Roleplay and Naughty Penpals`}</title>
    );
  };

  return (
    <Helmet>
      {renderTitle()}
      <link rel="canonical" href={url} />
      {metaDescription && (
        <meta
          name="description"
          content={metaDescription.replace(/"/g, '&quot;')}
        />
      )}
    </Helmet>
  );
};

HeadManager.propTypes = {
  pageTitle: pageTitleType.isRequired,
  metaDescription: metaDescriptionType,
  match: matchType.isRequired,
  promptLookingfor: promptLookingforType
};

HeadManager.defaultProps = {
  metaDescription: null,
  promptLookingfor: null
};

export default withRouter(HeadManager);
