// all of the genders in an array of object
// used by editpromptpage/EditPromptForm, filter/QuickFilter, adminpage/AdminCreatePrompt
export default [
  {
    value: 'all',
    label: 'All'
  },
  {
    value: 'male',
    label: 'Male'
  },
  {
    value: 'female',
    label: 'Female'
  },
  {
    value: 'transwoman',
    label: 'Transwoman'
  },
  {
    value: 'transman',
    label: 'Transman'
  },
  {
    value: 'non-binary',
    label: 'Non-binary'
  }
];
