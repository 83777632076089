import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { promptIDType, historyType, classesType } from '../../types';

const styles = {
  root: {
    width: '9rem' // make each button the same size (square / 2 rows)
  },
  // when it is 600px or less / on mobile
  '@media (max-width: 37.5rem)': {
    root: {
      width: '100%', // make it fullWidth
      borderRadius: '10rem' // make the corners round
    }
  }
};

const NotPublishedButton = ({ history, promptID, classes }) => {
  return (
    <Button
      className={classes.root}
      onClick={() => {
        history.push(`/edit/${promptID}`);
      }}
      variant="contained"
      color="primary"
    >
      Currently Not Published
    </Button>
  );
};

NotPublishedButton.propTypes = {
  promptID: promptIDType.isRequired,
  history: historyType.isRequired,
  classes: classesType.isRequired
};

const enhance = compose(withStyles(styles), withRouter);

export default enhance(NotPublishedButton);
