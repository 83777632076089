// fresh prompt used in create prompt page to fill some default values
import { EditorState } from 'draft-js';
// import makeDecoratorFromPlugins from '../components/texteditor/plugins/makeDecoratorFromPlugins';

const freshPrompt = {
  creator: '',
  creatorLower: '',
  creatorID: '',
  id: '',
  title: '',
  // text: EditorState.createEmpty(makeDecoratorFromPlugins()),
  // does not initialize the editorstate with any text
  text: EditorState.createEmpty(),
  iam: '',
  lookingfor: '',
  // category: '',
  tags: [],
  lovesCount: 0
};

export default freshPrompt;
