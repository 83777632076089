// reducer for actions/expandRetractActions.js
// determines if a message or conversation should be expanded or retracted
// by default, all messages and conversages are expanded and the initialState is an empty array
// when a message or convo is retracted, it is added to the state array
// when a message or convo is expanded, it is removed from the state array
// RETRACT_ALL adds all of the messageIDs to the state array
// EXPAND_ALL removes all of the messageIDs from the state array
import { EXPAND, RETRACT, EXPAND_ALL, RETRACT_ALL } from '../actions/types'; // import the action type

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case RETRACT: {
      const { messageID } = action.payload;
      if (state.includes(messageID)) {
        return state;
      }
      return [...state, messageID];
    }
    case EXPAND: {
      const { messageID } = action.payload;
      const newState = state.filter(cur => cur !== messageID);
      return newState;
    }
    case RETRACT_ALL: {
      const { messageIDs } = action.payload;
      const newState = [...state];
      messageIDs.forEach(messageID => {
        if (!newState.includes(messageID)) {
          newState.push(messageID);
        }
      });
      return newState;
    }
    case EXPAND_ALL: {
      const { messageIDs } = action.payload;
      const newState = state.filter(cur => {
        if (messageIDs.includes(cur)) {
          return false;
        }
        return true;
      });
      return newState;
    }
    default:
      return state;
  }
}
