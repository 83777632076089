// reducer for getPrompts.js
// got the prompts data from firestore
// import merge from 'lodash/merge';
import { GET_PROMPTS, RESET_PROMPTS, DELETE_PROMPT } from '../actions/types'; // import the action type

const initalState = {};

export default function(state = initalState, action) {
  switch (action.type) {
    case GET_PROMPTS: {
      const { payload: prompts } = action;
      return prompts;
    }
    case RESET_PROMPTS: {
      return initalState;
    }
    case DELETE_PROMPT: {
      const { promptID } = action.payload;
      const prompts = { ...state };
      const filteredPrompts = {};
      Object.keys(prompts).forEach(promptKey => {
        if (promptID !== promptKey) {
          const prompt = prompts[promptKey];
          filteredPrompts[promptKey] = prompt;
        }
      });
      return filteredPrompts;
    }
    default:
      return state;
  }
}
