import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { isFooterVisible } from '../../actions';
import rtaLabel from '../../images/rtalabel2.png';
import { classesType, isFooterVisibleType } from '../../types';

const styles = theme => ({
  root: {
    marginTop: '1rem',
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    borderTop: `solid 3px ${theme.palette.secondary.main}`,
    padding: '1rem'
  },
  links: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: '2rem'
  },
  linkItem: {
    margin: '0 0.5rem',
    textDecoration: 'none' // remove the underline of the link
  },
  warning: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginBottom: '1rem'
  },
  rtaLabel: {
    textAlign: 'center'
  },
  // when it is 600px or less
  '@media (max-width: 37.5rem)': {
    root: {
      marginTop: '0.5rem'
    },
    links: {
      marginTop: '0.5rem'
    }
  }
});

const getCurrentYear = () => {
  const currentTime = new Date();
  return currentTime.getFullYear();
};

const Footer = props => {
  const { classes } = props;
  return (
    <VisibilitySensor
      onChange={isVisible => {
        props.isFooterVisible(isVisible);
      }}
    >
      <Paper elevation={0} className={classes.root}>
        <div className={classes.links}>
          <Link to="/guide" className={classes.linkItem}>
            Guide
          </Link>
          <Link to="/rules" className={classes.linkItem}>
            Rules
          </Link>
          <Link to="/contact" className={classes.linkItem}>
            Contact
          </Link>
          <Link to="/contact" className={classes.linkItem}>
            Feedback
          </Link>
          <Link to="/privacypolicy" className={classes.linkItem}>
            Privacy Policy
          </Link>
          {/*
        <Link to="/termsandconditions" className={classes.linkItem}>
          Terms and Conditions
        </Link> */}
          <Typography className={classes.linkItem}>
            {`© ${getCurrentYear()} EroPenPal`}
          </Typography>
        </div>
        <div className={classes.warning}>
          <Typography variant="caption">
            No part may be reproduced in any form without explicit written
            permission. All characters in all stories on this site are over 18.
          </Typography>
        </div>
        <div className={classes.rtaLabel}>
          <a href="http://www.rtalabel.org/">
            <img src={rtaLabel} alt="RTA Label" height="31px" width="91.75px" />
          </a>
        </div>
      </Paper>
    </VisibilitySensor>
  );
};

Footer.propTypes = {
  classes: classesType.isRequired,
  isFooterVisible: isFooterVisibleType.isRequired
};

const actions = { isFooterVisible };

const enhance = compose(withStyles(styles), connect(null, actions));

export default enhance(Footer);
