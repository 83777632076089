// used by pages like loginpage, signuppage, loginmodal, signupmodal
// settingspage, editpromptpage to sent error messages
// there are 2 types of errors: error from firebae and errors made by me
import { FIREBASE_ERROR, MANMADE_ERRORS, RESET_ERRORS } from './types';
import changeFirebaseError from '../utils/changeFirebaseError';

export const dispatchFirebaseError = error => async dispatch => {
  const modError = changeFirebaseError(error);
  dispatch({ type: FIREBASE_ERROR, payload: { error: modError } });
};

export const dispatchManmadeErrors = errors => async dispatch => {
  dispatch({ type: MANMADE_ERRORS, payload: { errors } });
};

export const resetErrors = () => async dispatch => {
  dispatch({ type: RESET_ERRORS });
};
