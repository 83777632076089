import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import SearchIcon from '@material-ui/icons/Search';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import CreateIcon from '@material-ui/icons/CreateOutlined';
import ForumIcon from '@material-ui/icons/ForumOutlined';
import Badge from '@material-ui/core/Badge';
import { checkIfAdmin } from '../../utils/adminUsers';
import logoIcon from '../../images/logoIconMobile.png';
import logoText from '../../images/logoText.png';
import {
  classesType,
  loggedInType,
  toggleMobileMenuType,
  mobileMenuAnchorType,
  handleLogInType,
  handleSignUpType,
  handleLogOutType,
  newConvoMsgCountType,
  isHeaderMobileVisibleType,
  handleCloseMenuType,
  authType
} from '../../types';

const styles = {
  appbar: {
    backgroundColor: 'white' // changes the background color of app bar
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  left: {
    display: 'flex',
    alignItems: 'center'
  },
  right: {
    display: 'flex',
    alignItems: 'center'
  },
  mobileMenuButton: {},
  toolbarButtons: {
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    '&:hover': {
      cursor: 'pointer'
    },
    '&:active': {
      cursor: 'pointer'
    },
    display: 'flex',
    alignItems: 'center'
  },
  logoIcon: { height: '1rem' },
  logoText: { height: '1.5rem' },
  iconButton: {
    color: 'black'
    // margin: '-0.3rem' // reduce space between the icon buttons
  },
  loginButton: {
    marginRight: '4px' // add some space to the right of the button
  },
  signupButton: {
    marginLeft: '4px', // add some space to the left of the button
    marginRight: '5px'
  },
  // when it is 600px or less
  '@media (max-width: 37.5rem)': {
    toolbar: {
      padding: '0rem'
    }
  }
};

const HeaderMobile = props => {
  const {
    classes,
    mobileMenuAnchor,
    toggleMobileMenu,
    loggedIn,
    handleCloseMenu,
    handleLogIn,
    handleSignUp,
    handleLogOut,
    newConvoMsgCount,
    isHeaderMobileVisible,
    auth
  } = props;

  const renderNewConvoMsgCount = buttonText => {
    if (newConvoMsgCount && newConvoMsgCount !== 0) {
      return (
        <Badge badgeContent={newConvoMsgCount} color="primary">
          {buttonText}
        </Badge>
      );
    }
    return buttonText;
  };

  // if loggedIn is true then render logout menu items
  // if loggedIn is false then render sign up and login menu items
  const renderMenuItems = () => {
    if (loggedIn) {
      return (
        <div className="LoggedInMobileMenuItems">
          <MenuItem
            component={Link}
            to="/conversations"
            onClick={handleCloseMenu}
          >
            {renderNewConvoMsgCount('Conversations')}
          </MenuItem>
          <MenuItem component={Link} to="/profile" onClick={handleCloseMenu}>
            Profile
          </MenuItem>
          <MenuItem component={Link} to="/settings" onClick={handleCloseMenu}>
            Settings
          </MenuItem>
          {auth &&
            auth.currentUser &&
            auth.currentUser.displayName &&
            checkIfAdmin(auth.currentUser.displayName) && (
              <MenuItem component={Link} to="/admin" onClick={handleCloseMenu}>
                Admin
              </MenuItem>
            )}
          <MenuItem onClick={handleLogOut}>Logout</MenuItem>
        </div>
      );
    }
    return (
      <div className="LoggedOutMobileMenuItems">
        <MenuItem
          onClick={() => {
            toggleMobileMenu();
            handleLogIn();
          }}
        >
          Log In
        </MenuItem>
        <MenuItem
          onClick={() => {
            toggleMobileMenu();
            handleSignUp();
          }}
        >
          Sign Up
        </MenuItem>
      </div>
    );
  };

  const renderToolbarButtons = () => {
    if (loggedIn) {
      return (
        <div className={classes.toolbarButtons}>
          {/* <IconButton className={classes.iconButton}>
            <SearchIcon />
          </IconButton> */}
          <IconButton
            component={Link}
            to="/create"
            onClick={handleCloseMenu}
            className={classes.iconButton}
          >
            <CreateIcon />
          </IconButton>
          <IconButton
            component={Link}
            to="/conversations"
            onClick={handleCloseMenu}
            className={classes.iconButton}
          >
            <Badge badgeContent={newConvoMsgCount} color="primary">
              <ForumIcon />
            </Badge>
          </IconButton>
          <IconButton
            component={Link}
            to="/profile"
            onClick={handleCloseMenu}
            className={classes.iconButton}
          >
            <AccountCircleIcon />
          </IconButton>
        </div>
      );
    }
    return (
      <>
        {/*
        <IconButton>
          <SearchIcon />
        </IconButton>
        */}
        <Button
          variant="outlined"
          onClick={handleLogIn}
          className={classes.loginButton}
        >
          Log In
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSignUp}
          className={classes.signupButton}
        >
          Sign Up
        </Button>
      </>
    );
  };

  return (
    <VisibilitySensor
      partialVisibility
      onChange={isVisible => {
        isHeaderMobileVisible(isVisible);
      }}
    >
      <AppBar position="static" elevation={0} className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.left}>
            <IconButton
              className={classes.mobileMenuButton}
              color="inherit"
              aria-label="Menu"
              aria-owns={mobileMenuAnchor ? 'simple-menu' : null}
              aria-haspopup="true"
              onClick={toggleMobileMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={mobileMenuAnchor}
              open={Boolean(mobileMenuAnchor)}
              onClose={toggleMobileMenu}
            >
              <MenuItem component={Link} to="/" onClick={handleCloseMenu}>
                Front Page
              </MenuItem>
              <MenuItem component={Link} to="/create" onClick={handleCloseMenu}>
                Create A Prompt
              </MenuItem>
              {renderMenuItems()}
            </Menu>
            <Link to="/" className={classes.logo}>
              <img
                alt="EroPenPal Logo Icon"
                src={logoIcon}
                className={classes.logoIcon}
                width="17.875px"
                height="16px"
              />
              <img
                alt="EroPenPal Logo Text"
                src={logoText}
                className={classes.logoText}
                width="101.869px"
                height="24px"
              />
            </Link>
          </div>
          <div className={classes.right}>{renderToolbarButtons()}</div>
        </Toolbar>
      </AppBar>
    </VisibilitySensor>
  );
};

HeaderMobile.propTypes = {
  classes: classesType.isRequired,
  loggedIn: loggedInType.isRequired,
  toggleMobileMenu: toggleMobileMenuType.isRequired,
  mobileMenuAnchor: mobileMenuAnchorType,
  handleLogIn: handleLogInType,
  handleSignUp: handleSignUpType,
  handleLogOut: handleLogOutType,
  newConvoMsgCount: newConvoMsgCountType,
  isHeaderMobileVisible: isHeaderMobileVisibleType.isRequired,
  handleCloseMenu: handleCloseMenuType.isRequired,
  auth: authType
};

HeaderMobile.defaultProps = {
  mobileMenuAnchor: null,
  handleLogIn: null,
  handleSignUp: null,
  handleLogOut: null,
  newConvoMsgCount: 0,
  auth: null
};

export default withStyles(styles)(HeaderMobile);
