// update the prompt field in redux state
// note that his does NOT update the prompt in firestore
// used when creating and editing prompt
import {
  UPDATE_PROMPT_REDUX_STATE,
  GET_PROMPT,
  UPDATE_MY_PROMPTS_REDUX_STATE
} from './types';
import freshPrompt from '../utils/freshPrompt';

// used by AdminQueuedPrompts.js to chance queued prompts in redux state
// note that queuedPrompts are saved in myPrompts redux state
export const updateMyPromptsReduxState = (
  promptId,
  fieldType,
  fieldValue
) => async dispatch => {
  dispatch({
    type: UPDATE_MY_PROMPTS_REDUX_STATE,
    payload: { promptId, fieldType, fieldValue }
  });
};

export const updatePromptReduxState = (
  fieldType,
  fieldValue
) => async dispatch => {
  dispatch({
    type: UPDATE_PROMPT_REDUX_STATE,
    payload: { fieldType, fieldValue }
  });
};

// currently this is used in create prompt page to fill some default values
export const updateEntirePromptReduxState = promptObject => async dispatch => {
  dispatch({
    type: GET_PROMPT,
    payload: promptObject
  });
};

// resets the prompt object in redux state back to utils/freshPrompt.js
// this is needed so when you visit another prompt page, the current prompt in state
// won't flash on screen for a split second before the new prompt info is fetched and displayed
export const removePromptInState = () => async dispatch => {
  dispatch({ type: GET_PROMPT, payload: freshPrompt });
};
