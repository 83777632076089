import React from 'react';
import classNames from 'classnames'; // allows you to use multiple class names for a single className=""
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import TextEditor from '../texteditor/TextEditor';
import TextFieldErrorMessage from '../commoncomponents/TextFieldErrorMessage';
import genders from '../../utils/genders';
import {
  promptType,
  handleChangeType,
  classesType,
  errorsType,
  isSmallScreenBoolType
} from '../../types';

const styles = theme => ({
  // root: {
  //   padding: '1rem' // add some padding around the whole paper
  // },
  paper: {
    padding: '1rem' // add some padding around the whole paper
  },
  paperAbove: {
    marginBottom: '0.5rem', // add some margin to the bottom of the first paper (title and looking for)
    backgroundColor: theme.palette.primary.main
  },
  textField: {
    '& label.Mui-focused': {
      color: 'black' // keep the field label black after you've focused on the field
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black' // keep the field underline black after you've focused on the field
    },
    '& label.Mui-error': {
      color: red[700] // make the field label red when you make an error
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: red[700] // make the field underline red when you make an error
    }
  },
  lookingForIamContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'start'
    // maxWidth: '28rem' // limit the max size text so it doesn't strech out
  },
  fieldContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  iam: {
    marginRight: '1rem' // adds some space to the right of iam
  },
  selectionField: {
    marginLeft: '0.5rem' // add some space between the field and the label
  },
  // when it is 600px or less reduce padding
  '@media (max-width: 37.5rem)': {
    paper: {
      padding: '0.5rem'
    },
    paperAbove: {
      display: 'flex', // helps to center lookingForIamContainer
      flexDirection: 'column', // helps to center lookingForIamContainer
      alignItems: 'center' // helps to center lookingForIamContainer
    },
    lookingForIamContainer: {
      display: 'block' // helps to center lookingForIamContainer
    },
    iam: {
      marginRight: '0' // removes the space to the right of iam when screen is small
    },
    textFieldText: {}
  }
});

const EditPromptForm = props => {
  const { classes, handleChange, prompt, errors, isSmallScreen } = props;
  const { title, text, iam, lookingfor } = prompt;

  return (
    <div className={classes.root}>
      <Paper
        className={classNames(classes.paper, classes.paperAbove)}
        elevation={0}
      >
        <TextField
          error={errors && errors.title && true}
          onChange={handleChange('title')}
          label="Title"
          value={title}
          className={classes.textField}
          margin="normal"
          fullWidth
          multiline={isSmallScreen}
          classes={{ root: classes.textfieldRoot }}
        />
        <TextFieldErrorMessage errors={errors} errorType="title" />
        <div className={classes.lookingForIamContainer}>
          <div className={classNames(classes.fieldContainer, classes.iam)}>
            <Typography className={classes.textFieldText}>
              I am / RPing as
            </Typography>
            <TextField
              error={errors && errors.iam && true}
              id="iamMenuItem"
              select
              label=""
              className={classes.selectionField}
              value={iam}
              onChange={handleChange('iam')}
              InputProps={{}}
              helperText=""
              margin="normal"
            >
              {genders
                .filter(option => {
                  if (option.value !== 'all') {
                    return true;
                  }
                  return false;
                })
                .map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </TextField>
          </div>
          <div
            className={classNames(classes.fieldContainer, classes.lookingFor)}
          >
            <Typography className={classes.textFieldText}>
              Looking For
            </Typography>
            <TextField
              error={errors && errors.lookingfor && true}
              id="lookingforMenuItem"
              select
              label=""
              className={classes.selectionField}
              value={lookingfor}
              onChange={handleChange('lookingfor')}
              InputProps={{}}
              helperText=""
              margin="normal"
            >
              {genders.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
      </Paper>
      <Paper className={classes.paper} elevation={0}>
        <TextFieldErrorMessage errors={errors} errorType="text" />
        <TextEditor
          text={text}
          onChange={handleChange('text')}
          placeholder="Tell a story..."
        />
      </Paper>
    </div>
  );
};

EditPromptForm.propTypes = {
  prompt: promptType.isRequired,
  handleChange: handleChangeType.isRequired,
  classes: classesType.isRequired,
  errors: errorsType.isRequired,
  isSmallScreen: isSmallScreenBoolType.isRequired
};

export default withStyles(styles)(EditPromptForm);
