// get the prompts from firestore
import {
  GET_PROMPTS,
  GET_MY_PROMPTS,
  RESET_PROMPTS,
  RESET_MY_PROMPTS
} from './types';
import { firestore } from '../firebase';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';
import { filterPrompts } from './filterPrompts';
import { createPromptsQuery } from '../utils/createPromptsQuery';
import { numberOfPromptsPerFetch } from '../utils/constants';

export const getPrompts = (filter, searchKeyWord, thisIsMyPrompts) => async (
  dispatch,
  getState
) => {
  dispatch(asyncActionStart());
  try {
    const promptsRef = firestore.collection('prompts');

    const query = createPromptsQuery(promptsRef, filter, getState);
    // const query = promptsRef.where('published', '==', true).limit(10);
    const querySnapshots = await query.get();
    // console.log({ querySnapshots });
    // const promptUpdatePromises = querySnapshots.docs.map(promptDoc => {
    //   console.log({ promptDoc });
    //   const promptRef = promptDoc.ref;
    //   return promptRef.update({ creatorDeleted: 111 });
    // });
    // console.log('waiting for all of the prompt update promises');
    // console.log(promptUpdatePromises);
    // const result = await Promise.all(promptUpdatePromises);
    // console.log('after all of promptUpdatePromises ');
    // console.log(promptUpdatePromises);
    // console.log(result);

    // get the lastPrompt for filter in redux state
    const lastPrompt = querySnapshots.docs[querySnapshots.docs.length - 1];

    const prompts = {};
    querySnapshots.docs.forEach(doc => {
      const { id } = doc;
      const data = doc.data();
      prompts[id] = { ...data, id };
    });

    // if hide is true, then we are on admin page and looking at hidden prompts
    // then we get the reasonCounter from the flags collection for each prompt
    const { hide } = filter;
    if (hide) {
      const reasonCounterPromises = Object.keys(prompts).map(async promptId => {
        const promptFlagsReasonCounterRef = firestore
          .collection('prompts')
          .doc(promptId)
          .collection('flags')
          .doc('reasonCounter');
        const reasonCounterSnapshot = await promptFlagsReasonCounterRef.get();
        const reasonCounter = reasonCounterSnapshot.data();
        prompts[promptId].reasonCounter = reasonCounter;
      });
      await Promise.all(reasonCounterPromises);
    }

    if (thisIsMyPrompts === true) {
      dispatch({ type: GET_MY_PROMPTS, payload: prompts });
    } else {
      dispatch({ type: GET_PROMPTS, payload: prompts });
    }

    // 10 prompts then we are Not on last page
    // fewer than 10 prompts then we are on last page
    let lastPage;
    if (querySnapshots.docs.length === numberOfPromptsPerFetch) {
      lastPage = false;
    } else {
      lastPage = true;
    }

    // update the filter, this is required for CategoryPage, TagPage, TypePage etc
    // because in those pages you click on a button in the sidebar to get prompts and update filter
    // also resets the filter when you initially mounts a page like FrontPage
    // also updates the lastPrompt field for pagination
    dispatch(
      filterPrompts({ ...filter, lastPrompt, previousOrNext: null, lastPage })
    );

    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
  return null;
};

// resets the prompts object in redux state back to initialState of empty object ({})
// this is needed so when you visit another page with promptslist, the current prompts in state
// won't flash on screen for a split second before the new prompts are fetched and displayed
export const removePromptsInState = () => async dispatch => {
  dispatch({ type: RESET_PROMPTS });
};

// resets the myPrompts object in redux state
// used in profilepage/ProfilePrompts.js when switching from published to unpublished prompts
export const removeMyPromptsInState = () => async dispatch => {
  dispatch({ type: RESET_MY_PROMPTS });
};

// get the
// export const getPromptsFlags = () => async (dispatch, getState) => {
//   try {
//     const { prompts } = getState();
//     Object.keys(prompts).map(async promptId => {
//       const prompt = prompts[promptId];
//       console.log({ prompt });
//       const promptFlagsReasonCounterRef = firestore
//         .collection('prompts')
//         .doc(promptId)
//         .collection('flags')
//         .doc('reasonCounter');
//       const reasonCounterSnapshot = await promptFlagsReasonCounterRef.get();
//     });
//   } catch (error) {}
// };
