// checks if a component is visible or scrolled into view, either true or false
// relies on the package React Visibility Sensor
// when a component is in view or out of view, renders a button or something
// mostly for mobile
import {
  CONVOSFORMYPROMPT_VISIBILITY,
  EDITMESSAGEFORM_VISIBILITY,
  FOOTER_VISIBILITY,
  HEADERDESKTOP_VISIBILITY,
  HEADERMOBILE_VISIBILITY,
  HEADER_VISIBILITY,
  PROMPTBOTTOMMENUCONTAINER_VISIBILITY,
  PROMPTHEADERBUTTON_VISIBILITY
} from './types';

export const isConvosForMyPromptVisible = isVisible => dispatch => {
  dispatch({ type: CONVOSFORMYPROMPT_VISIBILITY, payload: isVisible });
};

export const isEditMessageFormVisible = isVisible => async dispatch => {
  dispatch({ type: EDITMESSAGEFORM_VISIBILITY, payload: isVisible });
};

export const isFooterVisible = isVisible => async dispatch => {
  dispatch({ type: FOOTER_VISIBILITY, payload: isVisible });
};

export const isHeaderDesktopVisible = isVisible => dispatch => {
  dispatch({ type: HEADERDESKTOP_VISIBILITY, payload: isVisible });
  dispatch({ type: HEADER_VISIBILITY, payload: isVisible });
};

export const isHeaderMobileVisible = isVisible => dispatch => {
  dispatch({ type: HEADERMOBILE_VISIBILITY, payload: isVisible });
  dispatch({ type: HEADER_VISIBILITY, payload: isVisible });
};

export const isPromptBottomMenuContainerVisible = isVisible => async dispatch => {
  dispatch({ type: PROMPTBOTTOMMENUCONTAINER_VISIBILITY, payload: isVisible });
};

export const isPromptHeaderButtonVisible = isVisible => dispatch => {
  dispatch({ type: PROMPTHEADERBUTTON_VISIBILITY, payload: isVisible });
};
