// kinks list used by utils/transformKinksList to change into an useable object
export default {
  bodies: {
    category: 'bodies',
    pov: ['general'],
    kinks: [
      'skinny',
      'chubby',
      'plus size', // suggested by D,
      'small breasts',
      'large breasts',
      'small cocks',
      'large cocks',
      'hairy / natural', // suggested by D,
      'shaved / waxed' // suggested by D,
    ]
  },
  clothing: {
    category: 'clothing',
    pov: ['self', 'partner'],
    kinks: [
      'clothed sex',
      'lingerie',
      'stockings',
      'heels',
      'leather',
      'latex',
      'uniform / costume',
      'crossdressing'
    ]
  },
  groupings: {
    category: 'groupings',
    pov: ['general'],
    kinks: [
      'you and 1 male',
      'you and 1 female',
      'you and MtF trans',
      'you and FtM trans',
      'you and 1 male, 1 female',
      'you and 2 males',
      'you and 2 females',
      'orgy',
      'dogging' // suggested by D, British slang, not sure how popular it is, maybe include something else
    ]
  },
  general: {
    category: 'general',
    pov: ['giving', 'receiving'],
    kinks: [
      'romance / affection',
      'handjob / fingering',
      'blowjob',
      'deep throat',
      'swallowing',
      'facials',
      'cunnilingus',
      'face-sitting',
      'edging',
      'teasing',
      'JOI, SI',
      'massage', // suggested by D,
      'shaving / waxing', // suggested by D,
      'verbal abuse', // suggested by D,
      'hair pulling', // suggested by D,
      'tribbing', // suggested by D, awkward because single POV
      'frotting', // suggested by D, awkward because single POV
      'phone sex' // suggested by D, awkward because single POV
    ]
  },
  assPlay: {
    category: 'ass play',
    pov: ['giving', 'receiving'],
    kinks: [
      'anal toys',
      'anal sex, pegging',
      'rimming',
      'double penetration',
      'anal fisting',
      'enema', // suggested by D
      'fart play', // suggested by D
      'figging' // suggested by D, not sure how popular this is.... or if we should include it
    ]
  },
  restrictive: {
    category: 'restrictive',
    pov: ['self', 'partner'],
    kinks: [
      'gag',
      'collar',
      'leash',
      'chastity',
      'light bondage',
      'heavy bondage',
      'encasement',
      'choking', // suggested by D
      'shibari' // suggested by D
    ]
  },
  toys: {
    category: 'toys',
    pov: ['self', 'partner'],
    kinks: [
      'dildos',
      'plugs',
      'vibrators',
      'sounding',
      'anal beads', // suggested by D
      'penis pump', // suggested by D
      'pussy pump' // suggested by D
    ]
  },
  domination: {
    category: 'domination',
    pov: ['dominant', 'submissive'],
    kinks: [
      'dominant / submissive',
      'domestic servitude',
      'slavery',
      'pet play',
      'DD/lg, MD/lb',
      'discipline',
      'begging',
      'forced orgasm',
      'orgasm control',
      'orgasm denial',
      'power exchange'
    ]
  },
  noConsent: {
    category: 'no consent',
    pov: ['aggressor', 'target'],
    kinks: [
      'non-con / rape',
      'blackmail / coercion',
      'kidnapping',
      'drugs / alcohol',
      'sleep play'
    ]
  },
  taboo: {
    category: 'taboo',
    pov: ['general'],
    kinks: [
      'incest',
      'ageplay',
      'raceplay',
      'bestiality',
      'necrophilia',
      'cheating',
      'exhibitionism',
      'voyeurism'
    ]
  },
  surrealism: {
    category: 'surrealism',
    pov: ['self', 'partner'],
    kinks: [
      'futanari',
      'furry',
      'vore',
      'transformation',
      'tentacles',
      'monster or alien'
    ]
  },
  fluids: {
    category: 'fluids',
    pov: ['general'],
    kinks: [
      'blood',
      'watersports',
      'scat',
      'lactation',
      'diapers',
      'cum play',
      'squirting', // suggested by D
      'period sex' // suggested by D
    ]
  },
  degradation: {
    category: 'degradation',
    pov: ['giving', 'receiving'],
    kinks: ['glory hole', 'name calling', 'humiliation']
  },
  touchAndStimulation: {
    category: 'touch and stimulation',
    pov: ['giving', 'receiving'],
    kinks: [
      'cock/pussy worship',
      'ass worship',
      'foot play/worship', // worship suggested by D
      'tickling',
      'sensation play',
      'electro stimulation',
      'nipple play', // suggested by D
      'breast worship', // suggested by D
      'ice play', // suggested by D
      'blindfold' // suggested by D
    ]
  },
  miscellaneousFetish: {
    category: 'miscellaneous fetish',
    pov: ['giving', 'receiving'],
    kinks: [
      'fisting',
      'gangbang',
      'breath play',
      'impregnation',
      'pregnancy',
      'feminization',
      'cuckold / cuckquean',
      'medical play' // suggested by D
    ]
  },
  pain: {
    category: 'pain',
    pov: ['giving', 'receiving'],
    kinks: [
      'light pain',
      'heavy pain',
      'nipple clamps',
      'clothes pins',
      'caning',
      'flogging',
      'beating',
      'spanking',
      'cock/pussy slapping',
      'cock/pussy torture',
      'hot Wax',
      'scratching',
      'biting',
      'cutting'
    ]
  }
};
