// the main page for a prompt
import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import VisibilitySensor from 'react-visibility-sensor';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {
  getPrompt,
  removePromptInState,
  resetErrors,
  isPromptBottomMenuContainerVisible,
  isConvosForMyPromptVisible,
  isPromptHeaderButtonVisible
} from '../../actions';
import PromptBottomMenuContainer from './PromptBottomMenuContainer';
import PromptSidebar from './PromptSidebar';
import PromptHeaderButton from './PromptHeaderButton';
import ConvosForMyPrompt from './ConvosForMyPrompt';
import TextEditorReadOnly from '../texteditor/TextEditorReadOnly';
import CircularLoading from '../commoncomponents/CircularLoading';
import HeadManager from '../commoncomponents/HeadManager';
import GoToTopBottomFab from '../commoncomponents/GoToTopBottomFab';
import genderToIcon from '../../utils/genderToIcon';
import { editorStateToPlainTextMetaDescription } from '../../utils/convertEditorState';
import {
  matchType,
  classesType,
  getPromptType,
  promptType,
  removePromptInStateType,
  historyType,
  authType,
  loadingType,
  errorsType,
  resetErrorsType,
  isPromptBottomMenuContainerVisibleType,
  isSmallScreenBoolType,
  isConvosForMyPromptVisibleType,
  isPromptHeaderButtonVisibleType
} from '../../types';

const PromptNotFound = lazy(() => import('./PromptNotFound'));

export const styles = theme => ({
  notFoundPrompt: {
    padding: '1.25rem 1.5rem'
  },
  grid: {
    padding: '1.25rem 1.5rem' // add some padding to the entire grid (20px by 24px)
  },
  paper: {
    padding: '1rem' // add some padding so text does not start at the border
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: theme.typography.title,
  headerButtonWrapperMobile: {
    display: 'none' // hide the mobile button when the screen is large
  },
  subheader: {
    margin: '1rem 0', // add some margin to subheader text
    color: theme.palette.text.meta
  },
  greenAvatar: {
    color: '#fff',
    backgroundColor: green[500]
  },
  notPublished: {
    opacity: '0.6'
  },
  // when it is 600px or less remove some padding
  '@media (max-width: 37.5rem)': {
    container: {
      padding: '0' // remove padding in container (the gray stuff to the left and right)
    },
    grid: {
      padding: '0.5rem 0rem'
    },
    header: {
      flexDirection: 'column' // put the start conversation button below the title
    },
    headerButtonWrapperDesktop: {
      display: 'none' // hide the desktop button when the screen is small
    },
    headerButtonWrapperMobile: {
      display: 'flex', // display mobile button again when the screen is small
      justifyContent: 'center', // centers the button
      marginTop: '1rem',
      marginBottom: '1rem'
    },
    title: {
      // fontSize: '20px'
    }
  }
});

class PromptPage extends Component {
  componentDidMount() {
    const { promptID } = this.props.match.params;
    this.props.getPrompt(promptID);
  }

  // when navigating away, this is used to reset the prompt in redux state
  // so old data won't flash on screen when on new prompt page
  componentWillUnmount() {
    this.props.removePromptInState();
    this.props.resetErrors();
  }

  // renders the header button
  // it is rendered next to the title text when on desktop (large screen)
  // it is rendered below the subheader when on mobile (small screen)
  renderHeaderButton = () => {
    const { prompt } = this.props;
    return (
      <VisibilitySensor
        partialVisibility
        onChange={isVisible => {
          this.props.isPromptHeaderButtonVisible(isVisible);
        }}
      >
        <PromptHeaderButton prompt={prompt} />
      </VisibilitySensor>
    );
  };

  renderPromptSidebar = () => {
    const { classes } = this.props;
    return (
      <Grid item xs={12} sm={4}>
        <PromptSidebar
          className={classes.sidebar}
          promptButtomMenuElement={this.promptBottonMenu}
        />
      </Grid>
    );
  };

  render() {
    const {
      classes,
      prompt,
      history,
      match,
      auth,
      loading,
      errors,
      isSmallScreen
    } = this.props;

    const {
      title,
      creator,
      creatorID,
      createdAt,
      text,
      published,
      iam,
      lookingfor,
      creatorDeleted
    } = prompt;

    const iamIcon = genderToIcon(iam);
    const lookingforIcon = genderToIcon(lookingfor);
    const metaDescription = editorStateToPlainTextMetaDescription(text);

    return (
      <Container maxWidth="lg" className={classes.container}>
        <HeadManager
          pageTitle={title}
          metaDescription={metaDescription}
          promptLookingfor={`[${iamIcon}4${lookingforIcon}]`}
        />
        {!loading && errors.permission && (
          <PromptNotFound classes={classes} history={history} />
        )}
        {!errors.permission && (
          <Grid className={classes.grid} container spacing={1}>
            <Grid item xs={12} sm={8}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <GoToTopBottomFab
                    pageName="PromptPage"
                    goToElement={this.promptBottonMenu}
                  />
                  <Paper className={classes.paper} elevation={0}>
                    {loading && <CircularLoading />}
                    {prompt && prompt.title && (
                      <>
                        <div className={classes.header}>
                          <Typography
                            className={classes.title}
                            variant="h1"
                            color="inherit"
                          >
                            {`[${iamIcon}4${lookingforIcon}] `}
                            {title}
                          </Typography>
                          <div className={classes.headerButtonWrapperDesktop}>
                            {this.renderHeaderButton()}
                          </div>
                        </div>
                        <Typography
                          variant="caption"
                          className={classes.subheader}
                        >
                          {`Submitted ${
                            createdAt
                              ? `${formatDistance(
                                  createdAt.toDate(),
                                  new Date()
                                )} ago`
                              : ''
                          }
                    by `}
                          {creatorDeleted ? (
                            '[deleted]'
                          ) : (
                            <Link to={`/user/${creator}`}>{creator}</Link>
                          )}
                        </Typography>
                        <div className={classes.headerButtonWrapperMobile}>
                          {this.renderHeaderButton()}
                        </div>
                        <div className={!published ? classes.notPublished : ''}>
                          <TextEditorReadOnly text={text} />
                        </div>
                      </>
                    )}
                    <Divider />
                    <div
                      ref={el => {
                        this.promptBottonMenu = el;
                      }}
                    />
                    <VisibilitySensor
                      partialVisibility
                      onChange={isVisible => {
                        this.props.isPromptBottomMenuContainerVisible(
                          isVisible
                        );
                      }}
                    >
                      <PromptBottomMenuContainer
                        prompt={prompt}
                        history={history}
                        match={match}
                      />
                    </VisibilitySensor>
                  </Paper>
                </Grid>
                {isSmallScreen && this.renderPromptSidebar()}
                {auth &&
                  auth.authenticated &&
                  auth.currentUser.uid === creatorID && (
                    <Grid item xs={12}>
                      <VisibilitySensor
                        partialVisibility
                        onChange={isVisible => {
                          this.props.isConvosForMyPromptVisible(isVisible);
                        }}
                      >
                        <ConvosForMyPrompt
                          promptID={prompt.id}
                          creatorID={creatorID}
                          auth={auth}
                          history={history}
                          promptTitle={title}
                        />
                      </VisibilitySensor>
                    </Grid>
                  )}
              </Grid>
            </Grid>
            {!isSmallScreen && this.renderPromptSidebar()}
          </Grid>
        )}
      </Container>
    );
  }
}

PromptPage.propTypes = {
  match: matchType.isRequired,
  classes: classesType.isRequired,
  getPrompt: getPromptType.isRequired,
  prompt: promptType.isRequired,
  removePromptInState: removePromptInStateType.isRequired,
  history: historyType.isRequired,
  auth: authType.isRequired,
  loading: loadingType.isRequired,
  errors: errorsType.isRequired,
  resetErrors: resetErrorsType.isRequired,
  isPromptBottomMenuContainerVisible:
    isPromptBottomMenuContainerVisibleType.isRequired,
  isSmallScreen: isSmallScreenBoolType.isRequired,
  isConvosForMyPromptVisible: isConvosForMyPromptVisibleType.isRequired,
  isPromptHeaderButtonVisible: isPromptHeaderButtonVisibleType.isRequired
};

function mapStateToProps({
  prompt,
  auth,
  errors,
  async: { loading },
  isSmallScreen
}) {
  return {
    prompt,
    auth,
    errors,
    loading,
    isSmallScreen
  };
}

const actions = {
  getPrompt,
  removePromptInState,
  resetErrors,
  isPromptBottomMenuContainerVisible,
  isConvosForMyPromptVisible,
  isPromptHeaderButtonVisible
};

export default connect(
  mapStateToProps,
  actions
)(withStyles(styles)(PromptPage));
