// when changing routes, automatically scroll to the top of the page
// react router does not come with this function. We implement it ourselves https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { locationType, childrenType } from '../../types';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

ScrollToTop.propTypes = {
  location: locationType.isRequired,
  children: childrenType.isRequired
};

export default withRouter(ScrollToTop);
