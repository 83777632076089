// reducer for myStats (number of prompts, conversations)
// used by actions/myStatsActions.js
import { GET_MY_STATS } from '../actions/types';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_STATS: {
      const {
        promptsNumber,
        conversationsStartedNumber,
        conversationsReceivedNumber
      } = action.payload;
      return {
        ...state,
        promptsNumber,
        conversationsStartedNumber,
        conversationsReceivedNumber
      };
    }
    default:
      return state;
  }
};
