// getMyUserInfo gets the usersPrivate doc of the currently authenticated user
// getUserInfo gets the usersPublic doc of the user with user name
import { firestore } from '../firebase';
import { UPDATE_USER_PAGE_REDUX_STATE } from './types';
import { updateMyUserReduxState } from './updateMyUserReduxState';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';

// get the private information (usersPrivate) of the currently authenticated user
// uses reducers/myUserReducer.js as its reducer
export const getMyUserInfoPrivate = () => async (dispatch, getState) => {
  // dispatch(asyncActionStart()); //removed so PromptPage doesn't flash on screen due to loading when authenticating
  try {
    const { currentUser } = getState().auth;
    const currentUserPrivateRef = firestore
      .collection('usersPrivate')
      .doc(currentUser.uid);
    const currentUserPrivateSnapshot = await currentUserPrivateRef.get();
    const myUserObject = currentUserPrivateSnapshot.data();

    dispatch(updateMyUserReduxState(myUserObject));
    // dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    // dispatch(asyncActionError());
  }
};

// get the public information (usersPublic) of the currently authenticated user
// uses reducers/myUserReducer.js as its reducer
export const getMyUserInfoPublic = () => async (dispatch, getState) => {
  dispatch(asyncActionStart());
  try {
    const { currentUser } = getState().auth;
    const currentUserPublicRef = firestore
      .collection('usersPublic')
      .doc(currentUser.uid);
    const currentUserPublicSnapshot = await currentUserPublicRef.get();
    const myUserObject = currentUserPublicSnapshot.data();
    dispatch(updateMyUserReduxState(myUserObject));
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

// get the public information (usersPublic) of the user with that username
// uses reducers/userPageReducer.js as its reducer
export const getUserInfo = username => async dispatch => {
  dispatch(asyncActionStart());
  try {
    const query = firestore
      .collection('usersPublic')
      .where('usernameLower', '==', username.toLowerCase())
      .limit(1);
    const result = await query.get();
    const user = result.docs[0];
    // if the query gets something back
    if (result.docs.length === 1) {
      dispatch({
        type: UPDATE_USER_PAGE_REDUX_STATE,
        payload: { loaded: true, id: user.id, ...user.data() }
      });
      // if the query does not get anything back
    } else {
      dispatch({
        type: UPDATE_USER_PAGE_REDUX_STATE,
        payload: { loaded: true, id: null }
      });
    }
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

// resets the userPage part of the redux state
export const removeUserPageInState = () => async dispatch => {
  dispatch({
    type: UPDATE_USER_PAGE_REDUX_STATE,
    payload: { loaded: false, id: null }
  });
};
