// send user a password reset email, used by ForgotPasswordPage.js
import firebase from '../firebase';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';
import { openSnackbar } from './snackbarActions';
import { dispatchFirebaseError } from './errorsActions';

export const sendPasswordResetEmail = emailAddress => async dispatch => {
  dispatch(asyncActionStart());
  try {
    const auth = firebase.auth();
    await auth.sendPasswordResetEmail(emailAddress);
    dispatch(
      openSnackbar('SuccessSnackbar', {
        message: 'successfully sent password reset email!'
      })
    );
    dispatch(asyncActionFinish());
    const success = true;
    return success;
  } catch (error) {
    // console.log(error);
    dispatch(asyncActionError());
    dispatch(dispatchFirebaseError(error));
  }
  return null;
};
