// about sidebar for the prompt page
// NOTE: on desktop, after scrolled, the entire sidebar might dissapear when you
// click on something that opens a Menu because of overflow-x: hidden in index.html
// because position: sticky does not work with changed overflow values
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import { filterPrompts } from '../../actions';
import PromptSidebarBottom from './PromptSidebarBottom';
import { tagToUri } from '../../utils/convertToFromUri';
import {
  classesType,
  promptType,
  historyType,
  filterPromptsType,
  filterType,
  isSmallScreenBoolType,
  promptButtomMenuElementType,
  promptBottomMenuContainerVisibilityType,
  promptHeaderButtonVisibilityType,
  headerVisibilityType
} from '../../types';

const styles = () => ({
  // @global effects everything on the page!
  '@global': {
    a: {
      '&:link': {
        textDecoration: 'none' // removes underline from the links
      },
      '&:hover': {
        textDecoration: 'underline' // adds the underline back when hovering the link
      }
    }
  },
  card: {
    top: 0,
    position: 'sticky'
    // backgroundColor: theme.palette.primary.main
  },
  header: {
    // fontSize: '120%' // font size is 18px
    marginBottom: '1rem'
    // display: 'flex',
    // justifyContent: 'space-between'
  },
  body: {
    // margin: '0rem 0.5rem'
  },
  lookingfor: {
    margin: '0.25rem 0rem',
    display: 'flex'
  },
  typeTitle: {
    fontWeight: 450,
    marginRight: '0.5rem',
    flexWrap: 'wrap'
  },
  lookingforText: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  tags: {
    margin: '0.25rem 0rem',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  tagsTitle: {
    fontWeight: 450,
    marginRight: '0.5rem'
  },
  chip: {
    margin: '0.2rem 0.1rem', // add some space around each chip
    '&:hover': {
      textDecoration: 'none' // removes underline for the Chip with Link as component
    }
  },
  // when it is 600px or less
  '@media (max-width: 37.5rem)': {
    header: {
      // display: 'flex',
      // justifyContent: 'space-between'
    },
    title: {
      // fontSize: '18px'
    },
    lookingforText: {
      // fontSize: '16px'
    }
  }
});

const PromptSidebar = ({
  classes,
  prompt,
  filter,
  isSmallScreen,
  promptButtomMenuElement,
  promptBottomMenuContainerVisibility,
  promptHeaderButtonVisibility,
  headerVisibility,
  ...props
}) => {
  const { iam, lookingfor, tags } = prompt;
  const changeFilterBeforeNavigateToLookingForPage = async () => {
    await props.filterPrompts({
      ...filter,
      iam, // set iam to the iam for this prompt
      lookingfor: 'all' // set looking for to all, decide NOT to change lookingfor
    });
  };

  // const lookingforLinkType = linkColorBasedOnIam(iam, classes, 'promptsidebar');
  return (
    <Card className={classes.card} elevation={0}>
      <CardContent>
        <div className={classes.header}>
          <Typography variant="h6" className={classes.title}>
            About Prompt
          </Typography>
        </div>
        <div className={classes.body}>
          <div className={classes.lookingfor}>
            <Typography className={classes.typeTitle}>Type: </Typography>
            <Typography
              component={Link}
              to="/"
              onClick={changeFilterBeforeNavigateToLookingForPage}
              // className={classNames(classes.lookingforText, lookingforLinkType)}
              className={classes.lookingforText}
            >
              {`${iam} looking for ${lookingfor}`}
            </Typography>
          </div>
          <div className={classes.tags}>
            <Typography className={classes.tagsTitle}>Tags: </Typography>
            {tags.map(tag => (
              // component={Link} is used to give an <a> tag and href so google bot can
              // crawl through it. clickable is true to make it appear to be clickable
              <Chip
                component={Link}
                to={tagToUri(tag)}
                variant="outlined"
                // size="small"
                key={tag}
                label={tag}
                clickable
                className={classes.chip}
              />
            ))}
          </div>
          <PromptSidebarBottom
            prompt={prompt}
            promptButtomMenuElement={promptButtomMenuElement}
            promptHeaderButtonVisibility={promptHeaderButtonVisibility}
            // headerDesktopVisibility={headerDesktopVisibility}
            // headerMobileVisibility={headerMobileVisibility}
            headerVisibility={headerVisibility}
            isSmallScreen={isSmallScreen}
          />
        </div>
      </CardContent>
    </Card>
  );
};

PromptSidebar.propTypes = {
  classes: classesType.isRequired,
  prompt: promptType.isRequired,
  history: historyType.isRequired,
  filterPrompts: filterPromptsType.isRequired,
  filter: filterType.isRequired,
  isSmallScreen: isSmallScreenBoolType.isRequired,
  promptButtomMenuElement: promptButtomMenuElementType,
  promptBottomMenuContainerVisibility:
    promptBottomMenuContainerVisibilityType.isRequired,
  promptHeaderButtonVisibility: promptHeaderButtonVisibilityType.isRequired,
  headerVisibility: headerVisibilityType.isRequired
};

PromptSidebar.defaultProps = {
  promptButtomMenuElement: null
};

const mapStateToProps = ({
  prompt,
  filter,
  isSmallScreen,
  componentVisibility: {
    promptBottomMenuContainerVisibility,
    promptHeaderButtonVisibility,
    headerVisibility
  }
}) => ({
  prompt,
  filter,
  isSmallScreen,
  promptBottomMenuContainerVisibility,
  promptHeaderButtonVisibility,
  headerVisibility
});

const actions = { filterPrompts };

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps, actions),
  withRouter
);

export default enhance(PromptSidebar);
