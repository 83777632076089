import React from 'react';
import { connect } from 'react-redux';
import FlagPromptModal from './FlagPromptModal';
import SignUpModal from './SignUpModal';
import LogInModal from './LogInModal';
import FlagUserModal from './FlagUserModal';
import UnsavedChangesModal from './UnsavedChangesModal';
import UnsubmittedMessageModal from './UnsubmittedMessageModal';
import DeletePromptModal from './DeletePromptModal';
import CircularLoadingModal from './CircularLoadingModal';
import DeleteConvoModal from './DeleteConvoModal';
import { currentModalType } from '../../types';

const modalLookup = {
  FlagPromptModal,
  SignUpModal,
  LogInModal,
  FlagUserModal,
  UnsavedChangesModal,
  UnsubmittedMessageModal,
  DeletePromptModal,
  CircularLoadingModal,
  DeleteConvoModal
};

const ModalManager = ({ currentModal }) => {
  let renderedModal;
  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];

    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <div>{renderedModal}</div>;
};

ModalManager.propTypes = {
  currentModal: currentModalType
};

ModalManager.defaultProps = {
  currentModal: null
};

const mapStateToProps = ({ modal }) => ({
  currentModal: modal
});

export default connect(mapStateToProps)(ModalManager);
