// save or load redux state to the localStorage of the browser
// tutorial is from https://egghead.io/lessons/javascript-redux-persisting-the-state-to-the-local-storage
// loadState and saveState are used in Root.js
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // ignore write errors.
  }
};

// export const loadState = () => {
//   try {
//     const state = localStorage.getItem('state');
//     if (state === null) {
//       return undefined;
//     }
//     return state;
//   } catch (err) {
//     return undefined;
//   }
// };
//
// export const saveState = state => {
//   try {
//     localStorage.setState('state', state);
//   } catch (err) {
//     // ignore write errors.
//   }
// };
