// create a new prompt and send the data to firestore
// import { CREATE_PROMPT } from './types';
import firebase, { firestore } from '../firebase';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';
import { openSnackbar } from './snackbarActions';
import { prepareEditorStateForStorage } from '../utils/convertEditorState';
import { updateMyUser } from './updateUserActions';
import checkPromptObjectBefore, {
  checkPromptObjectBeforeDraft
} from '../utils/checkPromptObjectBefore';
import { dispatchManmadeErrors, resetErrors } from './errorsActions';
import updateLookingforPreferences from '../utils/updateLookingforPreferences';

export const createPrompt = (prePromptObject, checkForError = true) => async (
  dispatch,
  getState
) => {
  dispatch(asyncActionStart());
  try {
    // creates a new promptObject to NOT mutate anything on original one.
    const promptObject = { ...prePromptObject };
    // creates or updates tagsLower with the latest tags,
    // tagsLower is used to fetch prompts based on tags in a case agnostic way
    promptObject.tagsLower = promptObject.tags.map(tag => tag.toLowerCase());

    // checks the promptObject to make sure it does not have empty title or text or other deficiencies
    // throws an error when it does not pass
    // checkForError is false when we are saving the prompt as a draft for the first time
    // so we do a mild version of error checking, checking only if the title is correct
    if (checkForError) {
      checkPromptObjectBefore(promptObject);
    } else {
      checkPromptObjectBeforeDraft(promptObject);
    }
    // prepare text (draftjs text editor state) ready for database storage
    const { text: originalText } = promptObject;
    promptObject.text = prepareEditorStateForStorage(originalText);

    // get the creator's username and usernameLower
    const creatorID = getState().auth.currentUser.uid;
    const creatorRef = firestore.collection('usersPrivate').doc(creatorID);
    const result = await creatorRef.get();
    const user = result.data();
    const { username: creator, usernameLower: creatorLower } = user;

    // create a new prompt in prompts collection
    const promptRef = firestore.collection('prompts');
    const createdPromptRef = await promptRef.add({
      ...promptObject,
      creator,
      creatorLower,
      creatorID,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      bumpedAt: firebase.firestore.FieldValue.serverTimestamp(),
      publishedAt: promptObject.published
        ? firebase.firestore.FieldValue.serverTimestamp()
        : null, // if published is true then put the current date, otherwise put null
      hide: false
    });

    // update the newly created prompt with it's generated promptID
    const createdPromptID = createdPromptRef.id;
    await createdPromptRef.update({ id: createdPromptID });

    // get the prompt just created in the firebase back
    const createdPromptSnapshot = await createdPromptRef.get();
    const createdPrompt = createdPromptSnapshot.data();

    // if the prompt's lookingfor and iam are different from myUser's lookingforPreferences
    // then update the lookingforPreferences in the user's document in usersPrivate collection
    updateLookingforPreferences({
      getState,
      promptObject,
      dispatch,
      updateMyUser
    });

    // if prompt is successfully updated, remove all errors from redux state
    dispatch(resetErrors());

    dispatch(asyncActionFinish());

    console.log('returning newly created prompt object!');
    // return createdPromptID; // return the newly created prompt
    const newPromptObject = {
      ...createdPrompt,
      text: originalText
    };
    return newPromptObject;
  } catch (error) {
    console.log({ error });
    // if the error is manmade, made in checkPromptObjectBefore.js
    if (error.type === 'manmade') {
      dispatch(dispatchManmadeErrors(error));
    } else {
      // if the error is generated by firebase
      const { code, message } = error;
      dispatch(asyncActionError({ code, message }));
      dispatch(openSnackbar('ErrorSnackbar', { message }));
    }
    return null;
  }
  // dispatch({ type: CREATE_PROMPT, payload: promptObject });
};

// export the testPrompts in test_content/examplePrompts to firestore through batch write
// used in testpage/TestPage.js
export const exportPrompts = promptObjects => async (dispatch, getState) => {
  dispatch(asyncActionStart());
  try {
    const creatorID = getState().auth.currentUser.uid;
    const batch = firestore.batch();
    await Object.values(promptObjects).forEach(async value => {
      const promptRef = firestore.collection('prompts').doc();
      const newValue = {
        ...value,
        creatorID,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        bumpedAt: firebase.firestore.FieldValue.serverTimestamp(),
        publishedAt: firebase.firestore.FieldValue.serverTimestamp()
      };
      await batch.set(promptRef, newValue);
    });
    await batch.commit();
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};
