// looks at the key of an object from firestore, makes sure the key is a firestore
// generated document id by making sure the key is NOT a manmade variable
// used by getAllNewConvoMsgAlerts() in adminActions and utils/numberOfConvosWithNewMsg
export default key => {
  if (
    key !== 'documentId' &&
    key !== 'lastUpdate' &&
    key !== 'userId' &&
    key !== 'count' &&
    key !== 'totalCount' &&
    key !== 'id'
  ) {
    return true;
  }
  return false;
};
