// used by the admin
import { GET_CROSS_POSTED_PROMPTS } from '../actions/types';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CROSS_POSTED_PROMPTS: {
      const { crossPostedPromptsIds } = action.payload;
      return { ...state, crossPostedPromptsIds };
    }
    default:
      return state;
  }
};
