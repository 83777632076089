// menu at the bottom of the prompt
// this is the presentation component
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import indigo from '@material-ui/core/colors/indigo';
// import blueGrey from '@material-ui/core/colors/blueGrey';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import NearMeOutlinedIcon from '@material-ui/icons/NearMeOutlined';
import FlagPrompt from './FlagPrompt';
import ShareMenu from './ShareMenu';
import {
  sameUserType,
  valueType,
  lovesCountType,
  promptIDType,
  handleChangeType,
  handleLoveButtonType,
  handleEditPromptType,
  handleBookmarkPromptType,
  alreadyBookmarkedType,
  alreadyLovedType,
  classesType,
  handleShareButtonType,
  handleShareMenuCloseType,
  anchorElType,
  handleLoggedOutLoveAndBookmarkType,
  loggedInOrNotType,
  promptTitleType,
  promptTagsType,
  promptCreatorIDType,
  promptCreatorType
} from '../../types';

// in order to change the styles of active BottomNavigationAction
// we had to use &$ to increase it's specificity
// https://stackoverflow.com/questions/52602392/material-ui-style-override
const styles = {
  root: {
    paddingTop: '0.6rem' // adding some padding so space to the top of the icons will balance the space to their bottom
  },
  edit: {
    color: '#000000',
    '&$selectedEdit': {
      color: '#000000'
    }
  },
  selectedEdit: {},
  redHeart: {
    color: red[500],
    '&$selectedRedHeart': {
      color: red[500]
    }
  },
  selectedRedHeart: {},
  bookmark: {
    color: indigo[500],
    '&$selectedBookmark': {
      color: indigo[500]
    }
  },
  selectedBookmark: {},
  share: {
    // color: blueGrey[500],
    '&$selectedShare': {
      // color: blueGrey[500]
      color: 'rgba(0, 0, 0, 0.54)'
    }
  },
  selectedShare: {}
};

const PromptBottomMenu = props => {
  const {
    sameUser,
    value,
    lovesCount,
    promptID,
    classes,
    handleChange,
    handleEditPrompt,
    handleBookmarkPrompt,
    handleLoveButton,
    alreadyBookmarked,
    alreadyLoved,
    anchorEl,
    handleShareButton,
    handleShareMenuClose,
    handleLoggedOutLoveAndBookmark,
    loggedInOrNot,
    promptTitle,
    promptTags,
    promptCreatorID,
    promptCreator
  } = props;

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      className={classes.root}
    >
      {sameUser && (
        <BottomNavigationAction
          onClick={handleEditPrompt}
          label="Edit"
          icon={<EditOutlinedIcon />}
          classes={{
            root: classes.edit,
            selected: classes.selectedEdit
          }}
        />
      )}
      <BottomNavigationAction
        label={lovesCount}
        icon={alreadyLoved ? <FavoriteIcon /> : <FavoriteBorderIcon />}
        className={classes.redHeart}
        classes={{
          selected: classes.selectedRedHeart
        }}
        onClick={
          loggedInOrNot ? handleLoveButton : handleLoggedOutLoveAndBookmark
        }
      />
      <BottomNavigationAction
        label="Bookmark"
        icon={alreadyBookmarked ? <BookmarkIcon /> : <BookmarkBorderIcon />}
        className={classes.bookmark}
        classes={{
          selected: classes.selectedBookmark
        }}
        onClick={
          loggedInOrNot ? handleBookmarkPrompt : handleLoggedOutLoveAndBookmark
        }
      />
      <BottomNavigationAction
        label="Share"
        icon={<NearMeOutlinedIcon />}
        className={classes.share}
        classes={{
          selected: classes.selectedShare
        }}
        onClick={handleShareButton}
      />
      <ShareMenu
        anchorEl={anchorEl}
        handleShareMenuClose={handleShareMenuClose}
        promptTitle={promptTitle}
        promptTags={promptTags}
      />
      {!sameUser && (
        <FlagPrompt
          promptID={promptID}
          promptTitle={promptTitle}
          promptCreatorID={promptCreatorID}
          promptCreator={promptCreator}
        />
      )}
    </BottomNavigation>
  );
};

PromptBottomMenu.propTypes = {
  sameUser: sameUserType.isRequired,
  value: valueType.isRequired,
  lovesCount: lovesCountType.isRequired,
  promptID: promptIDType,
  handleChange: handleChangeType.isRequired,
  handleLoveButton: handleLoveButtonType.isRequired,
  handleEditPrompt: handleEditPromptType.isRequired,
  handleBookmarkPrompt: handleBookmarkPromptType.isRequired,
  alreadyBookmarked: alreadyBookmarkedType.isRequired,
  alreadyLoved: alreadyLovedType.isRequired,
  classes: classesType.isRequired,
  handleShareButton: handleShareButtonType.isRequired,
  handleShareMenuClose: handleShareMenuCloseType.isRequired,
  anchorEl: anchorElType,
  handleLoggedOutLoveAndBookmark: handleLoggedOutLoveAndBookmarkType.isRequired,
  loggedInOrNot: loggedInOrNotType.isRequired,
  promptTitle: promptTitleType.isRequired,
  promptTags: promptTagsType.isRequired,
  promptCreatorID: promptCreatorIDType.isRequired,
  promptCreator: promptCreatorType.isRequired
};

PromptBottomMenu.defaultProps = {
  promptID: '',
  anchorEl: null
};

export default withStyles(styles)(PromptBottomMenu);
