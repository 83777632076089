import React from 'react';
// import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularLoadingButton from '../commoncomponents/CircularLoadingButton';
import { handleCancelUpdateType, loadingType, classesType } from '../../types';

const styles = theme => ({
  // wrapper is needed to center the progress indicator with the button
  wrapper: {
    margin: theme.spacing(1), // gives it 8px margin around it
    position: 'relative' // relative allows progress indicator, which is absolute, to go inside it
  }
});

const CancelButton = ({ classes, loading, handleCancelUpdate }) => (
  <div className={classes.wrapper}>
    <Button
      // size={isSmallScreen ? 'small' : 'medium'}
      size="medium"
      // variant="contained"
      // color="primary"
      disabled={loading}
      onClick={handleCancelUpdate}
    >
      Cancel
    </Button>
    {loading && <CircularLoadingButton />}
  </div>
);

CancelButton.propTypes = {
  handleCancelUpdate: handleCancelUpdateType.isRequired,
  loading: loadingType.isRequired,
  classes: classesType.isRequired
};

const enhance = compose(withStyles(styles));

export default enhance(CancelButton);
