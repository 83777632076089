import {
  UPDATE_USER_PAGE_REDUX_STATE,
  UPDATE_USER_KINKS_REDUX_STATE,
  REMOVE_USER_KINKS_REDUX_STATE
} from '../actions/types';

const initialState = { loaded: false, id: null, kinks: null };

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_PAGE_REDUX_STATE: {
      return {
        ...state,
        ...action.payload
      };
    }
    case UPDATE_USER_KINKS_REDUX_STATE: {
      return {
        ...state,
        kinks: { ...action.payload }
      };
    }
    case REMOVE_USER_KINKS_REDUX_STATE: {
      return {
        ...state,
        kinks: null
      };
    }
    default:
      return state;
  }
};
