// Root is required for testing with jest and enzyme
// It allows the <Provider> to be exported and placed on any component
// not just the App
import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
// redux thunk gives Action Creators manual access to dispatch function
// allowing us to dispatch the action at any point in time, instead of instantly
import reduxThunk from 'redux-thunk';
import throttle from 'lodash/throttle'; // use throttle to reduce number of times state is saved to localStorage
import { childrenType, initialStateType } from './types';

import reducers from './reducers'; // import the combineReducers for createStore
// get functions to save and load state to the user's local storage
import { loadState, saveState } from './utils/localStorage';
// load the state that was saved in localStorage previously
const persistedState = loadState();

const Root = ({ children, initialState }) => {
  // persistedState is used to load stuff from the localStorage
  // initialState is used to pass state during testing
  // if initialState is an empty object then we run the persistedState
  // if initialState is not an empty object then we passed in state during testing
  let state;
  if (
    Object.keys(initialState).length === 0 &&
    initialState.constructor === Object
  ) {
    // console.log('getting persistedState');
    state = persistedState;
  } else {
    // console.log('getting initialState');
    state = initialState;
  }

  // create a new instance of redux store
  // 3 arguments: reducers, initial state and middleware
  // const store = createStore(reducers, {}, applyMiddleware(reduxThunk));
  // below is the same store as above but with redux devtools added (https://github.com/zalmoxisus/redux-devtools-extension)
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    reducers,
    state,
    composeEnhancers(applyMiddleware(reduxThunk))
  );

  /* const unsubscribe = */ store.subscribe(
    // throttle is used to limit the number of times state is saved to localStorage
    // because JSON.stringify is an expensive operation
    // currently once per second
    throttle(() => {
      // console.log('state has changed and saved', store.getState());

      // when the state is changed, save the state to localStorage in user's browser
      // currently only saving myKinks and prompts from redux state but
      // it can save the entire state
      saveState({
        // myKinks: store.getState().myKinks,
        // prompts: store.getState().prompts,
        users: store.getState().users,
        retracted: store.getState().retracted,
        retractedKinks: store.getState().retractedKinks
      });
    }, 1000)
  );

  return <Provider store={store}>{children}</Provider>;
};

Root.propTypes = {
  children: childrenType.isRequired,
  initialState: initialStateType
};

Root.defaultProps = {
  initialState: {}
};

export default Root;
