// dispatch(asyncActionStart()) at the start of reading or writing to firebase
// dispatch(asyncActionFinish()) at the end of reading or writing to firebase
// dispatch(asyncActionError()) at the catching of an error when reading or writing to firebase
import {
  ASYNC_ACTION_START,
  ASYNC_ACTION_FINISH,
  ASYNC_ACTION_ERROR
} from './types';

export const asyncActionStart = () => ({
  type: ASYNC_ACTION_START
});

export const asyncActionFinish = () => ({
  type: ASYNC_ACTION_FINISH
});

export const asyncActionError = error => ({
  type: ASYNC_ACTION_ERROR,
  payload: { error }
});
