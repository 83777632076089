import { GET_MOST_POPULAR_TAGS, GET_ALL_TAGS } from '../actions/types';

// note that mostPopularTags is an array while allTags is an objet
// mostPopularTags are fetched as docs of a collection while allTags are fields from a single doc
const initialState = {
  mostPopularTags: [],
  allTags: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MOST_POPULAR_TAGS: {
      const { mostPopularTags } = action.payload;
      return { ...state, mostPopularTags: [...mostPopularTags] };
    }
    case GET_ALL_TAGS: {
      const { allTags } = action.payload;
      return { ...state, allTags: { ...allTags } };
    }
    default:
      return state;
  }
};
