import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SaveDraftButton from './SaveDraftButton';
import PostPromptButton from './PostPromptButton';
import CancelButton from './CancelButton';
import {
  handleCreatePromptType,
  handleUpdatePromptType,
  handleSaveDraftOrUnpublishType,
  handleCancelUpdateType,
  loadingType,
  matchType,
  promptType,
  classesType,
  handleLoggedOutButtonClickType,
  authType,
  isSmallScreenBoolType
} from '../../types';

const styles = () => ({
  root: {
    display: 'flex'
  }
});

const EditPromptPageButtons = ({
  classes,
  loading,
  prompt,
  match,
  handleSaveDraftOrUnpublish,
  handleUpdatePrompt,
  handleCreatePrompt,
  handleCancelUpdate,
  handleLoggedOutButtonClick,
  auth,
  isSmallScreen
}) => (
  <div className={classes.root}>
    <CancelButton loading={loading} handleCancelUpdate={handleCancelUpdate} />
    {!isSmallScreen && (
      <SaveDraftButton
        loading={loading}
        handleSaveDraft={handleSaveDraftOrUnpublish}
        text={prompt.published === true ? 'Hide Prompt' : 'Save Draft'}
        handleLoggedOutButtonClick={handleLoggedOutButtonClick}
        auth={auth}
      />
    )}
    <PostPromptButton
      loading={loading}
      handleUpdatePrompt={handleUpdatePrompt}
      handleCreatePrompt={handleCreatePrompt}
      text={
        match.path === '/create' || prompt.published === false
          ? 'Post Prompt'
          : 'Update Prompt'
      }
      createOrEdit={prompt.id ? 'edit' : 'create'}
      handleLoggedOutButtonClick={handleLoggedOutButtonClick}
      auth={auth}
    />
  </div>
);

EditPromptPageButtons.propTypes = {
  handleCreatePrompt: handleCreatePromptType.isRequired,
  handleUpdatePrompt: handleUpdatePromptType.isRequired,
  handleSaveDraftOrUnpublish: handleSaveDraftOrUnpublishType.isRequired,
  handleCancelUpdate: handleCancelUpdateType.isRequired,
  loading: loadingType.isRequired,
  match: matchType.isRequired,
  prompt: promptType.isRequired,
  classes: classesType.isRequired,
  handleLoggedOutButtonClick: handleLoggedOutButtonClickType.isRequired,
  auth: authType.isRequired,
  isSmallScreen: isSmallScreenBoolType.isRequired
};

export default withStyles(styles)(EditPromptPageButtons);
