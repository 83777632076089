// opens or closes a modal, used by the modals/ModalManager.js
import { MODAL_OPEN, MODAL_CLOSE } from './types';

export const openModal = (modalType, modalProps) => async dispatch => {
  dispatch({ type: MODAL_OPEN, payload: { modalType, modalProps } });
};

export const closeModal = () => async dispatch => {
  dispatch({ type: MODAL_CLOSE });
};

// // display the error message on the modal
// export const modalError = errorMessage => async dispatch => {
//   dispatch({ type: MODAL_ERROR, payload: { errorMessage } });
// };
