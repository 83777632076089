// reducer for getMyFollowing and getMyFollowers in myPenpalsActions.js action creators
// updates the redux state for myPenapals for profilepage/ProfilePenpals.js
// current this redux state consists of myFollowing (users I am following) and
// myFollowers (users who are following me)
import {
  GET_MY_FOLLOWING,
  GET_MY_FOLLOWERS,
  NOT_FOLLOWING_USER
} from '../actions/types';

const initialState = {
  myFollowingList: {},
  lastMyFollowing: null,
  myFollowersList: {},
  lastMyFollowers: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_FOLLOWING: {
      const {
        myFollowingList,
        lastMyFollowing,
        moreMyFollowing
      } = action.payload;
      return {
        ...state,
        myFollowingList,
        lastMyFollowing,
        moreMyFollowing
      };
    }
    case GET_MY_FOLLOWERS: {
      const {
        myFollowersList,
        lastMyFollower,
        moreMyFollowers
      } = action.payload;
      return {
        ...state,
        myFollowersList,
        lastMyFollower,
        moreMyFollowers
      };
    }
    // removes the userID from the myFollowingList when you unfollow someone
    case NOT_FOLLOWING_USER: {
      const { userID } = action.payload;
      const { myFollowingList } = state;
      const filteredMyFollowingList = {};
      Object.keys(myFollowingList).forEach(followingKey => {
        if (followingKey !== userID) {
          const following = myFollowingList[followingKey];
          filteredMyFollowingList[followingKey] = following;
        }
      });
      return { ...state, myFollowingList: { ...filteredMyFollowingList } };
    }
    default:
      return state;
  }
};
