// used in edit prompt page (/edit) and create prompt page (/create)
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux'; // compose from lodash also works
import { withStyles } from '@material-ui/core/styles';
import { Prompt } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import EditPromptForm from './EditPromptForm';
import EditPromptPageButtons from './EditPromptPageButtons';
import ChooseTags from './ChooseTags';
// import RulesSidebar from '../sidebarcomponents/RulesSidebar';
import {
  updatePrompt,
  getPrompt,
  createPrompt,
  updatePromptReduxState,
  updateEntirePromptReduxState,
  removePromptInState,
  openSnackbar,
  openModal,
  resetErrors,
  getMostPopularTags
} from '../../actions';
import freshPrompt from '../../utils/freshPrompt';
import sanitizeTagsInput from '../../utils/sanitizeTagsInput';
import BottomLeftMobileMenu from './BottomLeftMobileMenu';
import HeadManager from '../commoncomponents/HeadManager';
import metaDescriptions from '../../utils/metaDescriptions';
import { promptIdTitleToUri } from '../../utils/convertToFromUri';
import {
  createPromptType,
  updatePromptType,
  getPromptType,
  updatePromptReduxStateType,
  updateEntirePromptReduxStateType,
  removePromptInStateType,
  openSnackbarType,
  loadingType,
  classesType,
  matchType,
  historyType,
  promptType,
  authType,
  openModalType,
  myUserType,
  errorsType,
  resetErrorsType,
  mostPopularTagsType,
  getMostPopularTagsType,
  isSmallScreenBoolType
} from '../../types';

const styles = theme => ({
  grid: {
    padding: '1.25rem 1.5rem' // add some padding around the whole thing
  },
  header: {
    padding: '1rem', // add some padding to the header
    marginBottom: '0.5rem', // add some space to the bottom of header
    display: 'flex', // make the header paper a flex container to align text and buttons
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: theme.typography.title,
  form: {
    marginBottom: '0.5rem' // add some space to the bottom of form
  },
  bottomButtonsPaper: {
    padding: '0.5rem 0.75rem', // add some padding to the paper of bottom buttons
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  // when it is 600px or less reduce padding
  '@media (max-width: 37.5rem)': {
    container: {
      padding: '0' // remove padding in container (the gray stuff to the left and right)
    },
    grid: {
      padding: '0.5rem 0'
    },
    header: {
      padding: '0.5rem',
      flexDirection: 'column'
    },
    title: theme.typography.titleMobile
  }
});

class EditPromptPage extends Component {
  state = {
    // keeps track of if any of the textfields and if the editor is changed
    // if changed is true then a modal will popup if user attempts to leave the page
    // changed state becomes true when textfields or editor are changed
    // changed state becomes false when changes are saved to firestore
    changed: false
  };

  componentDidMount() {
    // if the path is on /edit, then fetch the existing prompt
    if (this.props.match.path !== '/create') {
      // fetch the prompt based on uri from firestore
      const { promptID } = this.props.match.params;
      this.props.getPrompt(promptID);
    } else {
      // if the path is on /create, then make an empty prompt
      const { myUser } = this.props;
      // if existing user preferences exist, i.e. the user is logged in
      // then load the existing user preferences
      if (myUser && myUser.lookingforPreferences) {
        const { iam, lookingfor } = myUser.lookingforPreferences;
        this.populateDefaultPromptInfo(iam, lookingfor);
        // if not, i.e. the user is logged out, then load the default preferences
      } else {
        this.populateDefaultPromptInfo();
      }
    }

    // fetch most popular tags if the tags are missing from redux
    const { mostPopularTags } = this.props;
    if (mostPopularTags.length <= 0) {
      this.props.getMostPopularTags();
    }
  }

  componentDidUpdate(prevProps) {
    // if the uri has changed but the component did not unmount then remount
    // that means we moved from /edit to /create or vice versa
    if (prevProps.match.path !== this.props.match.path) {
      // if moving from /edit to /create, reset the fields
      if (this.props.match.path === '/create') {
        // get user preferences
        const {
          iam,
          lookingfor
          // category
        } = this.props.myUser.lookingforPreferences;
        // this.populateDefaultPromptInfo(iam, lookingfor, category);
        this.populateDefaultPromptInfo(iam, lookingfor);
      }
      // if moving from /create to /edit, fetch the prompt
      if (this.props.match.path.includes('/edit')) {
        // fetch the prompt based on uri from firestore
        const { promptID } = this.props.match.params;
        this.props.getPrompt(promptID);
      }
    }
    // if the user's preferences changed that means we just logged in or just
    // hard refreshed at /create, then we update the default prompt info with
    // the user preferences we just received
    if (
      prevProps.myUser.lookingforPreferences !==
      this.props.myUser.lookingforPreferences
    ) {
      if (this.props.match.path === '/create') {
        // check if authenticated to prevent the app from crash when logging out
        // while on the /create page. so below would only activate when logging in
        if (this.props.auth.authenticated) {
          const {
            iam,
            lookingfor
            // category
          } = this.props.myUser.lookingforPreferences;
          // this.populateDefaultPromptInfo(iam, lookingfor, category);
          this.populateDefaultPromptInfo(iam, lookingfor);
        }
      }
    }
  }

  componentWillUnmount() {
    // when navigating away, this is used to reset the prompt in redux state
    // so old data won't flash on screen when on new prompt page
    this.props.removePromptInState();
    // when navigating away, resets the error redux state
    this.props.resetErrors();
  }

  populateDefaultPromptInfo = (
    iam = 'female',
    lookingfor = 'male'
    // category = 'Category_1'
  ) => {
    this.props.updateEntirePromptReduxState({
      ...freshPrompt,
      iam,
      lookingfor
      // category
    });
  };

  handleChangeTextField = fieldType => event => {
    // if it is a draft js editor state event then send the whole event
    if (fieldType === 'text') {
      // get the editorState from both the event and the prompt in redux
      const eventContentState = event.getCurrentContent();
      const reduxContentState = this.props.prompt.text.getCurrentContent();
      // get the selectionState from both the event and the prompt in redux
      const eventSelectionState = event.getSelection();
      const reduxSelectionState = this.props.prompt.text.getSelection();

      // when the editor states in both event and the redux are the same
      if (eventContentState === reduxContentState) {
        // when focus states are the same
        if (eventSelectionState.hasFocus === reduxSelectionState.hasFocus) {
          // when both both editorState and focusState are the same, changed is false
          this.setState({ changed: false });
        }
        // when editor states are different
      } else {
        // when editor states are different, changed is true
        this.setState({ changed: true });
      }
      this.props.updatePromptReduxState(fieldType, event);
      // otherwise only send the event's value
    } else {
      this.props.updatePromptReduxState(fieldType, event.target.value);
      this.setState({ changed: true });
    }
  };

  handleClickTag = tag => () => {
    if (!this.props.prompt.tags.includes(tag)) {
      this.props.updatePromptReduxState('tagClick', tag);
      this.setState({ changed: true });
    }
  };

  handleTagsTextField = event => {
    const tagsArray = event.target.value.split(', ');
    this.props.updatePromptReduxState('tags', tagsArray);
    this.setState({ changed: true });
  };

  // saves a draft or hide (unpublish) a prompt
  handleSaveDraftOrUnpublish = saveOrUnpublish => async () => {
    this.setState({ changed: false });
    const promptObject = {
      ...this.props.prompt,
      published: false
    };
    promptObject.tags = sanitizeTagsInput(promptObject.tags);

    // check to see if we are creating the draft or just updating it

    // if we are creating the draft for the first time (no prompt.id),
    // update the id field in the prompt redux state after we get the id from the firestore
    if (this.props.match.path === '/create' && !this.props.prompt.id) {
      // const createdPromptId = await this.props.createPrompt(promptObject)
      const checkForError = false;
      const newPromptObject = await this.props.createPrompt(
        promptObject,
        checkForError
      );
      if (newPromptObject && newPromptObject.id) {
        this.props.openSnackbar('SuccessSnackbar', {
          message: 'successfully saved the draft!'
        });
        const { id } = newPromptObject;
        this.props.history.push(`/edit/${id}`); // go to the page with the newly created prompt id
      }
      // if we are updating the draft or reverting it from published back to draft
      // we update the published field in redux state to false after we sucessfully updates firestore
    } else {
      const checkForError = false;
      const successOrNot = await this.props.updatePrompt(
        promptObject,
        checkForError
      );
      if (successOrNot === 'success') {
        this.props.updateEntirePromptReduxState(promptObject);

        // // update the redux state to show that published is false in case we are staying on the page
        // this.props.updatePromptReduxState('published', false)
        if (saveOrUnpublish === 'Save Draft') {
          this.props.openSnackbar('SuccessSnackbar', {
            message: 'successfully saved the draft!'
          });
          // show a snackbar of successfully saving the prompt
        } else if (saveOrUnpublish === 'Hide') {
          this.props.openSnackbar('SuccessSnackbar', {
            message: 'successfully unpublished the prompt!'
          });
          // show a snackbar of successfully unpublishing the prompt
        }
      }
    }
  };

  // if id for prompt exists then we call edit
  handleUpdatePrompt = async () => {
    this.setState({ changed: false });
    const { prompt } = this.props;
    const previousPublishState = prompt.published;
    const promptObject = {
      ...prompt,
      published: true
    };
    promptObject.tags = sanitizeTagsInput(promptObject.tags);

    const successOrNot = await this.props.updatePrompt(promptObject);
    if (successOrNot === 'success') {
      // if previously the prompt was a draft, then forward the user to the new prompt page
      // because this means that the user is going from draft to published
      if (previousPublishState === false) {
        this.props.openSnackbar('SuccessSnackbar', {
          message: 'successfully posted the prompt!'
        });
        this.props.history.push(promptIdTitleToUri(prompt.id, prompt.title));
      } else {
        // otherwise allow the user to stay on the current page
        // if we are allowing em to stay on page, then we update the entire redux state
        this.props.updateEntirePromptReduxState(promptObject);
        this.props.openSnackbar('SuccessSnackbar', {
          message: 'successfully updated the prompt!'
        });
      }
    }
  };

  // if the id for prompt does NOT exist then we call create
  handleCreatePrompt = async () => {
    this.setState({ changed: false });
    const promptObject = {
      ...this.props.prompt,
      published: true
    };
    promptObject.tags = sanitizeTagsInput(promptObject.tags);

    const newPromptObject = await this.props.createPrompt(promptObject);
    if (newPromptObject && newPromptObject.id) {
      this.props.openSnackbar('SuccessSnackbar', {
        message: 'successfully created the prompt!'
      });
      const { id, title } = newPromptObject;
      this.props.history.push(promptIdTitleToUri(id, title)); // go to the page with the newly created prompt id
    }
  };

  // used by the Cancel button to go backward
  handleCancelUpdate = () => {
    this.props.history.goBack();
  };

  // changes changed state to true then go to the next location
  // used by the UnsavedChangesModal
  handleGoToNextLocation = nextLocation => {
    this.setState({ changed: false }, () =>
      this.props.history.push(nextLocation)
    );
  };

  // used by the Prompt component provided by the react-router-dom to stop
  // you from leaving EditPromptPage if changed state is true
  // if handleBlockNavigation returns true then we move to the next location
  // if handleBlockNavigation returns false then we STOP the navigation
  // if navigation is stopped then the UnsavedChangesModal opens
  handleBlockNavigation = nextLocation => {
    if (this.state.changed === true) {
      // staying here and opening the modal
      this.props.openModal('UnsavedChangesModal', {
        handleGoToNextLocation: () => this.handleGoToNextLocation(nextLocation),
        handleSaveDraft: this.handleSaveDraftOrUnpublish,
        handleUpdatePrompt: this.handleUpdatePrompt,
        published: this.props.prompt.published || false
      });
      return false;
    }
    // moving to the next location
    return true;
  };

  // handles button click when the user is logged out
  handleLoggedOutButtonClick = () => {
    this.props.openModal('SignUpModal');
  };

  // renders the 3 buttons
  renderButtons = (loading, prompt, match, auth, isSmallScreen) => (
    <EditPromptPageButtons
      loading={loading}
      prompt={prompt}
      match={match}
      handleSaveDraftOrUnpublish={this.handleSaveDraftOrUnpublish}
      handleUpdatePrompt={this.handleUpdatePrompt}
      handleCreatePrompt={this.handleCreatePrompt}
      handleCancelUpdate={this.handleCancelUpdate}
      handleLoggedOutButtonClick={this.handleLoggedOutButtonClick}
      auth={auth}
      isSmallScreen={isSmallScreen}
    />
  );

  render() {
    const {
      classes,
      loading,
      prompt,
      match,
      auth,
      errors,
      mostPopularTags,
      isSmallScreen
    } = this.props;
    const { id: promptID, title: promptTitle } = prompt;
    return (
      <Container maxWidth="md" className={classes.container}>
        <HeadManager
          pageTitle={
            match.path === '/create' ? 'Create A Prompt' : `Edit ${promptTitle}`
          }
          metaDescription={metaDescriptions.CreatePage}
        />
        <div className={classes.grid}>
          <Paper className={classes.header} elevation={0}>
            <Prompt
              // when={!this.state.changed}
              message={this.handleBlockNavigation}
            />
            <Typography variant="h1" color="inherit" className={classes.title}>
              {match.path === '/create' ? 'Create a prompt' : 'Edit the prompt'}
            </Typography>
            {this.renderButtons(loading, prompt, match, auth, isSmallScreen)}
          </Paper>
          <div className={classes.form}>
            <EditPromptForm
              handleChange={this.handleChangeTextField}
              prompt={prompt}
              errors={errors}
              isSmallScreen={isSmallScreen}
            />
          </div>
          <ChooseTags
            handleTagsTextField={this.handleTagsTextField}
            handleClickTag={this.handleClickTag}
            tags={prompt.tags}
            mostPopularTags={mostPopularTags}
            isSmallScreen={isSmallScreen}
          />
          <Paper className={classes.bottomButtonsPaper} elevation={0}>
            <BottomLeftMobileMenu
              promptID={promptID}
              promptTitle={promptTitle}
              loading={loading}
              handleSaveDraftOrUnpublish={this.handleSaveDraftOrUnpublish}
              prompt={prompt}
              handleLoggedOutButtonClick={this.handleLoggedOutButtonClick}
              auth={auth}
              match={match}
            />
            {this.renderButtons(loading, prompt, match, auth, isSmallScreen)}
          </Paper>
        </div>
      </Container>
    );
  }
}

EditPromptPage.propTypes = {
  createPrompt: createPromptType.isRequired,
  updatePrompt: updatePromptType.isRequired,
  getPrompt: getPromptType.isRequired,
  updatePromptReduxState: updatePromptReduxStateType.isRequired,
  updateEntirePromptReduxState: updateEntirePromptReduxStateType.isRequired,
  removePromptInState: removePromptInStateType.isRequired,
  openSnackbar: openSnackbarType.isRequired,
  loading: loadingType.isRequired,
  classes: classesType.isRequired,
  match: matchType.isRequired,
  history: historyType.isRequired,
  prompt: promptType.isRequired,
  auth: authType.isRequired,
  openModal: openModalType.isRequired,
  myUser: myUserType.isRequired,
  errors: errorsType.isRequired,
  resetErrors: resetErrorsType.isRequired,
  mostPopularTags: mostPopularTagsType.isRequired,
  getMostPopularTags: getMostPopularTagsType.isRequired,
  isSmallScreen: isSmallScreenBoolType.isRequired
};

// by grabbing the loading state, this component updates and renders a few additional times
function mapStateToProps({
  prompt,
  auth,
  async,
  myUser,
  errors,
  tags: { mostPopularTags },
  isSmallScreen
}) {
  // const modErrors = checkEditPromptErrors(errors);
  return {
    prompt,
    auth,
    loading: async.loading,
    myUser,
    errors,
    mostPopularTags,
    isSmallScreen
  };
}

const actions = {
  updatePrompt,
  getPrompt,
  createPrompt,
  updatePromptReduxState,
  updateEntirePromptReduxState,
  removePromptInState,
  openSnackbar,
  openModal,
  resetErrors,
  getMostPopularTags
};

const enhance = compose(connect(mapStateToProps, actions), withStyles(styles));

export default enhance(EditPromptPage);
