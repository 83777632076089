import { GET_OUR_USERS, GET_USERS_PRIVATE_DOCS } from '../actions/types';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OUR_USERS: {
      return {
        ...state,
        ourUsers: {
          ...action.payload.ourUsers
        }
      };
    }
    case GET_USERS_PRIVATE_DOCS: {
      return {
        ...state,
        usersPrivateDocs: {
          ...action.payload.usersPrivateDocs
        }
      };
    }
    default:
      return state;
  }
};
