// add / remove the current prompt to the loves collection of the currently logged in user
// add / remove listener to check if the current prompt is in the user's loves collection
import {
  LOVED,
  NOT_LOVED,
  REMOVE_LOVE_LISTENER,
  UPDATE_LOVES_PROMPT_REDUX_STATE,
  GET_MY_LOVES,
  DELETE_MY_LOVE,
  LOVE_BUTTON_CLICK_PENDING_START,
  LOVE_BUTTON_CLICK_PENDING_FINISH
} from './types';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';
import firebase, { firestore } from '../firebase';

// add the prompt to user's loves collection
// then create the illusion of incrementing loves by 1 in redux state
export const lovePrompt = promptObject => async (dispatch, getState) => {
  dispatch(asyncActionStart());
  dispatch({ type: LOVE_BUTTON_CLICK_PENDING_START });
  try {
    const {
      id: promptID,
      title: promptTitle,
      creator: promptCreator
    } = promptObject;
    const currentLoves = getState().prompt.lovesCount;
    // add the prompt to the user's loves collection
    const userID = getState().auth.currentUser.uid;
    const userRef = firestore.collection('usersPrivate').doc(userID);
    await userRef
      .collection('loves')
      .doc(promptID)
      .set({
        promptTitle,
        promptCreator,
        createdAt: firebase.firestore.FieldValue.serverTimestamp() // only this works. firestore.FieldValue.serverTimestamp() does Not work!
      });
    // the actualy incrementing of loves count in prompt doc is done by cloud functions
    // we use the below to create the illusion of incrementing it by 1 to the user
    dispatch({
      type: UPDATE_LOVES_PROMPT_REDUX_STATE,
      payload: currentLoves + 1
    });
    dispatch({ type: LOVE_BUTTON_CLICK_PENDING_FINISH });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

// remove the prompt from user's loves collection
// then create the illusion of decrementing loves by 1 in redux state
export const unlovePrompt = (promptID, promptIsDeleted = false) => async (
  dispatch,
  getState
) => {
  dispatch(asyncActionStart());
  dispatch({ type: LOVE_BUTTON_CLICK_PENDING_START });
  try {
    const currentLoves = getState().prompt.lovesCount;
    const userID = getState().auth.currentUser.uid;
    const userRef = firestore.collection('usersPrivate').doc(userID);
    await userRef
      .collection('loves')
      .doc(promptID)
      .delete();
    // if prompt is Not deleted, we are on the prompt page
    if (!promptIsDeleted) {
      // the actualy decrementing of loves count in prompt doc is done by cloud functions
      // we use the below to create the illusion of decrementing it by 1 to the user
      dispatch({
        type: UPDATE_LOVES_PROMPT_REDUX_STATE,
        payload: currentLoves - 1
      });
      dispatch({ type: LOVE_BUTTON_CLICK_PENDING_FINISH });
    } else {
      // if the prompt is deleted then we are on the ProfileLoves page unloving
      // prompts that are already deleted
      dispatch({ type: DELETE_MY_LOVE, payload: { promptID } });
    }
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

// create a listener that checks if the current prompt is loved by the current user
// it is called by componenetDidMount / componentDidUpdate of promptpage/PromptBottomMenuContainer.js
export const loveListener = promptID => async (dispatch, getState) => {
  try {
    const userID = getState().auth.currentUser.uid;
    const loveRef = firestore
      .collection('usersPrivate')
      .doc(userID)
      .collection('loves')
      .doc(promptID);
    const unsubscribe = await loveRef.onSnapshot(doc => {
      if (doc.exists) {
        dispatch({ type: LOVED, payload: { unsubscribe } });
      } else {
        dispatch({ type: NOT_LOVED, payload: { unsubscribe } });
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const removeLoveListener = () => async (dispatch, getState) => {
  try {
    if (getState().love.listenerLoaded) {
      const { unsubscribe } = getState().love;
      unsubscribe();
      dispatch({ type: REMOVE_LOVE_LISTENER });
    }
  } catch (error) {
    console.log(error);
  }
};

// the next 2 action creators are for profilepage/ProfilLoves.js
// get latest 10 loved prompts of the authenticated user
// currently 20 queries are made for every 10 loved prompts (10 for loves, 10 for prompts)
export const getMyLoves = getNewMyLoves => async (dispatch, getState) => {
  dispatch(asyncActionStart());
  try {
    const user = getState().auth.currentUser;
    const userID = user.uid;
    const currentMyLovesList = getState().myLoves.myLovesList;

    // get newest docs from loves collections
    const myLovesQuery = firestore
      .collection('usersPrivate')
      .doc(userID)
      .collection('loves')
      .orderBy('createdAt', 'desc')
      .limit(10);

    // get the lastMyLove from the state, note that this is the
    // last myLove fetched and NOT the newest myLove created!
    const currentLastMyLove = getState().myLoves.lastMyLove;

    let modMyLovesQuery;
    // if we are getting new loves then do not modify the query
    // if we are getting old / previous ones then start after the currentLastMyLove
    if (getNewMyLoves) {
      modMyLovesQuery = myLovesQuery;
    } else {
      modMyLovesQuery = myLovesQuery.startAfter(currentLastMyLove);
    }

    const myLovesSnapshot = await modMyLovesQuery.get();
    // get all the prompts info based on the loves docs
    const justFetchedMyLovesList = await Promise.all(
      myLovesSnapshot.docs.map(async doc => {
        const promptID = doc.id;
        const lovePromptData = doc.data();
        const promptRef = firestore.collection('prompts').doc(promptID);
        try {
          const promptSnapshot = await promptRef.get();
          const promptData = promptSnapshot.data();
          return { id: promptID, ...lovePromptData, ...promptData };
        } catch (error) {
          // console.log({ error });
          return { id: promptID, ...lovePromptData };
        }
      })
    );

    const myLovesList = {};
    justFetchedMyLovesList.forEach(justFetchedLove => {
      myLovesList[justFetchedLove.id] = justFetchedLove;
    });

    // merge myLovesList just fetched with currentMyLovesList from redux state
    // if the just fetched myLovesList is newly created, put them in front of the current ones
    // if the just fetched myLovesList is older, put them after the current ones
    let modMyLovesList;
    if (getNewMyLoves) {
      modMyLovesList = { ...myLovesList, ...currentMyLovesList };
    } else {
      modMyLovesList = { ...currentMyLovesList, ...myLovesList };
    }

    // if getNewMyLoves is true and currentLastMyLove already exists then
    // DO NOT update lastMyLove and moreMyLoves. This means that we are getting
    // new loves on to put on top of existing ones
    let lastMyLove;
    let moreMyLoves;
    if (getNewMyLoves && currentLastMyLove) {
      lastMyLove = currentLastMyLove;
      const currentMoreMyLoves = getState().myLoves.moreMyLoves;
      moreMyLoves = currentMoreMyLoves;
    } else {
      // this means we just arrived to the page and loaded a fresh list to modMyLovesList
      // or just hit the bottom of the page and fetched an older set of loves
      // either way update lastMyLove
      lastMyLove = myLovesSnapshot.docs[myLovesSnapshot.docs.length - 1];
      // if we fetched 10 loves then that means there might be more loves in firestore
      if (myLovesSnapshot.docs.length === 10) {
        moreMyLoves = true;
      } else {
        moreMyLoves = false;
      }
    }
    dispatch({
      type: GET_MY_LOVES,
      payload: { myLovesList: modMyLovesList, lastMyLove, moreMyLoves }
    });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

// currently we do Not remove myLoves in redux state when leaving the page
// export const removeMyLovesReduxState = () => async dispatch => {};
