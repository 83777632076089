import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { bumpPrompt } from '../../actions';
import {
  creatorIDType,
  promptIDType,
  bumpPromptType,
  authType,
  classesType
} from '../../types';

const styles = {
  bumpPromptButton: {
    width: '9rem' // make each button the same size (square / 2 rows)
  },
  // when it is 600px or less / on mobile
  '@media (max-width: 37.5rem)': {
    bumpPromptButton: {
      width: '100%',
      borderRadius: '10rem'
    }
  }
};

const BumpPromptButton = props => {
  const handleBumpButtonClick = () => {
    props.bumpPrompt(props.promptID);
  };

  const { auth, creatorID, classes } = props;

  // only show this button if the auth'd userID is the same as the creatorID
  if (auth && auth.currentUser && auth.currentUser.uid === creatorID) {
    return (
      <Button
        className={classes.bumpPromptButton}
        onClick={handleBumpButtonClick}
        variant="contained"
        color="primary"
      >
        Bump My Prompt
      </Button>
    );
  }
  return null;
};

BumpPromptButton.propTypes = {
  creatorID: creatorIDType.isRequired,
  promptID: promptIDType.isRequired,
  bumpPrompt: bumpPromptType.isRequired,
  auth: authType.isRequired,
  classes: classesType.isRequired
};

const mapStateToProps = ({ auth, isSmallScreen }) => ({ auth, isSmallScreen });

const actions = { bumpPrompt };

const enhance = compose(connect(mapStateToProps, actions), withStyles(styles));

export default enhance(BumpPromptButton);
