import { FILTER_FEEDBACKS } from '../actions/types'; // import the action type
import freshFeedbacksFilter from '../utils/freshFeedbacksFilter';

const initialState = freshFeedbacksFilter;

export default function(state = initialState, action) {
  switch (action.type) {
    case FILTER_FEEDBACKS: {
      return { ...state, ...action.payload.feedbacksFilter };
    }
    default:
      return state;
  }
}
