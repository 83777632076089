// add / remove the current prompt to the bookmarks collection of the currently logged in user
// add / remove listener to check if the current prompt is in the user's bookmarks collection
import {
  BOOKMARKED,
  NOT_BOOKMARKED,
  REMOVE_BOOKMARK_LISTENER,
  GET_MY_BOOKMARKS,
  DELETE_MY_BOOKMARK,
  BOOKMARK_BUTTON_CLICK_PENDING_START,
  BOOKMARK_BUTTON_CLICK_PENDING_FINISH
} from './types';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';
import firebase, { firestore } from '../firebase';

// bookmark a prompt on the prompt page
export const bookmarkPrompt = promptObject => async (dispatch, getState) => {
  dispatch(asyncActionStart());
  dispatch({ type: BOOKMARK_BUTTON_CLICK_PENDING_START });
  try {
    const {
      id: promptID,
      title: promptTitle,
      creator: promptCreator
    } = promptObject;
    const userID = getState().auth.currentUser.uid;
    const userRef = firestore.collection('usersPrivate').doc(userID);
    // create a new doc in the bookmarks collection
    await userRef
      .collection('bookmarks')
      .doc(promptID)
      .set({
        promptTitle,
        promptCreator,
        createdAt: firebase.firestore.FieldValue.serverTimestamp() // only this works. firestore.FieldValue.serverTimestamp() does Not work!
      });
    dispatch({ type: BOOKMARK_BUTTON_CLICK_PENDING_FINISH });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

// unbookmark a prompt on a prompt page
export const unbookmarkPrompt = (promptID, promptIsDeleted = false) => async (
  dispatch,
  getState
) => {
  dispatch(asyncActionStart());
  dispatch({ type: BOOKMARK_BUTTON_CLICK_PENDING_START });
  try {
    const userID = getState().auth.currentUser.uid;
    const userRef = firestore.collection('usersPrivate').doc(userID);
    await userRef
      .collection('bookmarks')
      .doc(promptID)
      .delete();
    // if prompt is Not deleted, we are on the prompt page
    if (!promptIsDeleted) {
      dispatch({ type: BOOKMARK_BUTTON_CLICK_PENDING_FINISH });
    } else {
      // if the prompt is deleted then we are on the ProfileBookmarks page unbookmarking
      // prompts that are already deleted
      dispatch({ type: DELETE_MY_BOOKMARK, payload: { promptID } });
    }
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

// create a listener that checks if the current prompt is booked by the current user
// it is called by componenetDidMount / componentDidUpdate of promptpage/PromptBottomMenuContainer.js
export const bookmarkListener = promptID => async (dispatch, getState) => {
  try {
    const userID = getState().auth.currentUser.uid;
    const bookmarkRef = firestore
      .collection('usersPrivate')
      .doc(userID)
      .collection('bookmarks')
      .doc(promptID);
    const unsubscribe = await bookmarkRef.onSnapshot(doc => {
      if (doc.exists) {
        dispatch({ type: BOOKMARKED, payload: { unsubscribe } });
      } else {
        dispatch({ type: NOT_BOOKMARKED, payload: { unsubscribe } });
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// remove the bookmark listener when leaving the prompt page
export const removeBookmarkListener = () => async (dispatch, getState) => {
  try {
    if (getState().bookmark.listenerLoaded) {
      const { unsubscribe } = getState().bookmark;
      unsubscribe();
      dispatch({ type: REMOVE_BOOKMARK_LISTENER });
    }
  } catch (error) {
    console.log(error);
  }
};

// the next 2 action creators are for profilepage/ProfileBookmarks.js
// get latest 10 bookmarked prompts of the authenticated user
// currently 20 queries are made for every 10 bookmarked prompts (10 for bookmarks, 10 for prompts)
export const getMyBookmarks = getNewMyBookmarks => async (
  dispatch,
  getState
) => {
  dispatch(asyncActionStart());
  try {
    const user = getState().auth.currentUser;
    const userID = user.uid;
    const currentMyBookmarksList = getState().myBookmarks.myBookmarksList;

    // get all the docs from bookmarks collections
    const myBookmarksQuery = firestore
      .collection('usersPrivate')
      .doc(userID)
      .collection('bookmarks')
      .orderBy('createdAt', 'desc')
      .limit(10);

    // get the lastMyBookmark from the state, note that this is the
    // last myBookmark fetched and NOT the newest myBookmark created!
    const currentLastMyBookmark = getState().myBookmarks.lastMyBookmark;

    let modMyBookmarksQuery;
    // if we are getting new bookmarks then do not modify the query
    // if we are getting old / previous ones then start after the currentLastMyBookmark
    if (getNewMyBookmarks) {
      modMyBookmarksQuery = myBookmarksQuery;
    } else {
      modMyBookmarksQuery = myBookmarksQuery.startAfter(currentLastMyBookmark);
    }

    const myBookmarksSnapshot = await modMyBookmarksQuery.get();
    // get all the prompts info based on the bookmarks docs
    const justFetchedMyBookmarksList = await Promise.all(
      myBookmarksSnapshot.docs.map(async doc => {
        const promptID = doc.id;
        const bookmarkPromptData = doc.data();
        const promptRef = firestore.collection('prompts').doc(promptID);
        // if the bookedmarked prompt is unpublished or deleted
        // then return undefined
        try {
          const promptSnapshot = await promptRef.get();
          const promptData = promptSnapshot.data();
          return { id: promptID, ...bookmarkPromptData, ...promptData };
        } catch (error) {
          // console.log({ error });
          return { id: promptID, ...bookmarkPromptData };
        }
      })
    );

    const myBookmarksList = {};
    justFetchedMyBookmarksList.forEach(justFetchedBookmark => {
      myBookmarksList[justFetchedBookmark.id] = justFetchedBookmark;
    });

    // merge myBookmarksList just fetched with currentMyBookmarksList already in redux state
    // if the just fetched myBookmarksList is newly created, then put them in front of currentMyBookmarksList
    // if the just fetched myBookmarksList is older, then put them after currentMyBookmarksList
    let modMyBookmarksList;
    if (getNewMyBookmarks) {
      modMyBookmarksList = { ...myBookmarksList, ...currentMyBookmarksList };
    } else {
      modMyBookmarksList = { ...currentMyBookmarksList, ...myBookmarksList };
    }

    // if getNewMyBookmarks is true and currentLastMyBookmark exists then
    // DO NOT Update lastMyBookmark and moreMyBookmarks. It means we just got
    // new my bookmarks to put on TOP of existing my bookmarks list.
    let lastMyBookmark;
    let moreMyBookmarks;
    if (getNewMyBookmarks && currentLastMyBookmark) {
      lastMyBookmark = currentLastMyBookmark;
      const currentMoreMyBookmarks = getState().myBookmarks.moreMyBookmarks;
      moreMyBookmarks = currentMoreMyBookmarks;
    } else {
      // this means we just arrived to the page and loaded a fresh list of modMyBookmarksList
      // or we have just got to the bottom of the page and fetched an older set of bookmarks
      // either way we update the lastMyBookmark
      lastMyBookmark =
        myBookmarksSnapshot.docs[myBookmarksSnapshot.docs.length - 1];
      // if we have fetched 10 bookmarks that means there might be more bookmakrs in firestore
      if (myBookmarksSnapshot.docs.length === 10) {
        moreMyBookmarks = true;
      } else {
        moreMyBookmarks = false;
      }
    }

    dispatch({
      type: GET_MY_BOOKMARKS,
      payload: {
        myBookmarksList: modMyBookmarksList,
        lastMyBookmark,
        moreMyBookmarks
      }
    });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

// currently we do Not remove myBookmarks in redux state when leaving the page
// export const removeMyBookmarksReduxState = () => async dispatch => {};
