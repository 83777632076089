// staging.js don't commit this!
// this is eropenpal-dev
export const firebaseConfig = {
  apiKey: 'AIzaSyAnn8sr-F7NNvXc5yTvMMZIV27lIHWHS4E',
  authDomain: 'eropenpal-dev.firebaseapp.com',
  databaseURL: 'https://eropenpal-dev.firebaseio.com',
  projectId: 'eropenpal-dev',
  storageBucket: 'eropenpal-dev.appspot.com',
  messagingSenderId: '736701297496',
  appId: '1:736701297496:web:d7dd7c3d53965f3bdfab2d',
  measurementId: 'G-FW4D1Q6WP4'
};
