// calculate the number of conversations that have new messages
// takes in newConversationMessageAlert of newStuffAlerts
// count the number of docs in newConversationMessageAlert, aside from id and totalCount
import checkIfKeyIsAConversationId from './checkIfKeyIsAConversationId';

export default newConversationMessageAlert => {
  const number = Object.keys(newConversationMessageAlert).reduce(
    (acc, docName) => {
      let newAcc;
      if (checkIfKeyIsAConversationId(docName)) {
        newAcc = acc + 1;
      } else {
        newAcc = acc;
      }
      return newAcc;
    },
    0
  );
  return number;
};
