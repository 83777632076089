import { SNACKBAR_OPEN, SNACKBAR_CLOSE } from '../actions/types';

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case SNACKBAR_OPEN: {
      const { snackbarType, snackbarProps } = action.payload;
      return { snackbarType, snackbarProps };
    }
    case SNACKBAR_CLOSE:
      return null;
    default:
      return state;
  }
};
