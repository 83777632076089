// update the myUser field in redux state
// note that his does NOT update the user in firestore
// used in SettingsNotifications.js
// it should also update the userPage field in redux state future but it's not yet implemented
import { UPDATE_MY_USER_REDUX_STATE, RESET_MY_USER_REDUX_STATE } from './types';

export const updateMyUserReduxState = myUserObject => async dispatch => {
  dispatch({ type: UPDATE_MY_USER_REDUX_STATE, payload: myUserObject });
};

export const resetMyUserReduxState = () => async dispatch => {
  console.log('dispatching myUser reset');
  dispatch({ type: RESET_MY_USER_REDUX_STATE });
};
