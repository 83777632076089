// get the prompts from firestore
import { GET_PROMPT } from './types';
import { firestore } from '../firebase';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from './asyncActions';
// import { openSnackbar } from './snackbarActions';
import { reviveEditorStateAfterStorage } from '../utils/convertEditorState';
import { dispatchFirebaseError } from './errorsActions';

export const getPrompt = promptID => async dispatch => {
  dispatch(asyncActionStart());
  try {
    const promptRef = firestore.collection('prompts').doc(promptID);
    const querySnapshot = await promptRef.get();
    const data = querySnapshot.data();
    const { id } = querySnapshot;

    const prompt = { ...data, id };

    // make prompt.text into a Draft js editor state again
    prompt.text = reviveEditorStateAfterStorage(prompt.text);

    dispatch({ type: GET_PROMPT, payload: prompt });
    dispatch(asyncActionFinish());
  } catch (error) {
    // const { code, message } = error;
    // console.log(error);
    // dispatch(asyncActionError({ code, message }));
    // dispatch(openSnackbar('ErrorSnackbar', { message }));
    console.log(error);
    dispatch(asyncActionError());
    dispatch(dispatchFirebaseError(error));
  }
  return null;
};
