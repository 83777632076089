// displays an error message for a textfield
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import { classesType, errorTypeType, errorsType } from '../../types';

const styles = () => ({
  error: {
    color: red[700]
  }
});

const TextFieldErrorMessage = ({ errors, errorType, classes }) => {
  // if errors is provided by edit prompt page
  if (errors && errors[errorType]) {
    return (
      <Typography className={classes.error}>{errors[errorType]}</Typography>
    );
  }
  // // if errors is provided by errors on authentication pages
  // // if errors' errorType matches the errorType provided then
  // // display the error message
  // if (errors && errors.errorType === errorType) {
  //   return <Typography className={classes.error}>{errors.message}</Typography>;
  // }
  return null;
};

TextFieldErrorMessage.propTypes = {
  classes: classesType.isRequired,
  errorType: errorTypeType.isRequired,
  errors: errorsType.isRequired
};

export default withStyles(styles)(TextFieldErrorMessage);
