// use this form to login
// used in header/Header.js
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import {
  closeModal,
  login,
  socialLogin,
  openModal,
  resetErrors
} from '../../actions';
import SocialLogin from '../loginpage/SocialLogin';
import TextFieldErrorMessage from '../commoncomponents/TextFieldErrorMessage';
import CircularLoadingButton from '../commoncomponents/CircularLoadingButton';
import {
  loginType,
  socialLoginType,
  closeModalType,
  openModalType,
  classesType,
  errorsType,
  historyType,
  resetErrorsType,
  loadingType
} from '../../types';

const styles = theme => ({
  title: {
    margin: 'auto',
    paddingBottom: '0'
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
    width: '20rem'
  },
  textfield: {
    '& label.Mui-focused': {
      color: 'black' // keep the field label black after you've focused on the field
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black' // keep the field underline black after you've focused on the field
    },
    '& label.Mui-error': {
      color: red[700] // make the field label red when you make an error
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: red[700] // make the field underline red when you make an error
    }
  },
  buttonWrapper: {
    margin: 'auto',
    position: 'relative' // relative allows progress indicator, which is absolute, to go inside it
  },
  loginButton: {
    paddingLeft: '2rem',
    paddingRight: '2rem'
  },
  or: {
    margin: 'auto'
  },
  socialLoginButtons: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  errorMessage: {
    margin: 'auto',
    color: red[700]
  },
  forgotPassword: {
    color: theme.palette.text.link,
    '&:hover': {
      cursor: 'pointer'
    },
    alignSelf: 'flex-end',
    marginTop: '0.25rem'
  },
  signupThings: {
    margin: 'auto',
    display: 'flex',
    alignItems: 'baseline',
    padding: '1rem',
    color: theme.palette.text.meta2
  },
  signupButton: {
    color: theme.palette.text.link
  },
  // when it is 600px or less
  '@media (max-width: 37.5rem)': {
    fields: {
      width: '17rem'
    }
  }
});

class LogInModal extends Component {
  state = {
    email: '',
    password: ''
  };

  componentWillUnmount() {
    this.props.resetErrors();
  }

  handleEmail = event => {
    this.setState({ email: event.target.value });
  };

  handlePassword = event => {
    this.setState({ password: event.target.value });
  };

  handleSubmit = () => {
    const { email, password } = this.state;
    this.props.login({ email, password });
  };

  handleKeyPress = event => {
    // if "Enter" is pressed, submit the form
    if (event.key === 'Enter') {
      this.handleSubmit();
    }
  };

  handleSocialLogin = selectedProvider => () => {
    this.props.socialLogin(selectedProvider);
  };

  handleForgotPassword = () => {
    this.props.closeModal();
    this.props.history.push('/forgotpassword');
  };

  render() {
    const { email, password } = this.state;
    const { classes, errors, loading } = this.props;
    return (
      <Dialog
        open
        onClose={this.props.closeModal}
        onKeyPress={this.handleKeyPress}
        className={classes.root}
      >
        <DialogTitle className={classes.title}>Log In!</DialogTitle>
        <DialogContent className={classes.fields}>
          {/* <DialogContentText>Log in</DialogContentText> */}
          <TextFieldErrorMessage errors={errors} errorType="reauth" />
          <TextField
            error={errors && errors.email && true}
            id="email"
            label="Email"
            type="text"
            value={email}
            onChange={this.handleEmail}
            className={classes.textfield}
          />
          <TextFieldErrorMessage errors={errors} errorType="email" />
          <TextField
            error={errors && errors.password && true}
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={this.handlePassword}
            className={classes.textfield}
          />
          <TextFieldErrorMessage errors={errors} errorType="password" />
          <Typography
            variant="caption"
            onClick={this.handleForgotPassword}
            className={classes.forgotPassword}
          >
            {`Forgot your password?`}
            {/* `Forgot password?` */}
          </Typography>
        </DialogContent>
        <DialogActions>
          <div className={classes.buttonWrapper}>
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
              className={classes.loginButton}
            >
              Log in
            </Button>
            {loading && <CircularLoadingButton />}
          </div>
        </DialogActions>
        <Typography variant="body2" className={classes.or}>
          or
        </Typography>
        <DialogActions className={classes.socialLoginButtons}>
          <SocialLogin handleSocialLogin={this.handleSocialLogin} />
        </DialogActions>
        <Typography variant="caption" className={classes.signupThings}>
          Don&apos;t have an account?
          <Button
            size="small"
            onClick={() => this.props.openModal('SignUpModal')}
            className={classes.signupButton}
          >
            Sign up
          </Button>
          {/* Not on EroPenPal yet? Sign up */}
        </Typography>
      </Dialog>
    );
  }
}

LogInModal.propTypes = {
  login: loginType.isRequired,
  socialLogin: socialLoginType.isRequired,
  closeModal: closeModalType.isRequired,
  openModal: openModalType.isRequired,
  classes: classesType.isRequired,
  errors: errorsType.isRequired,
  history: historyType.isRequired,
  resetErrors: resetErrorsType.isRequired,
  loading: loadingType.isRequired
};

const mapStateToProps = ({ errors, async: { loading } }) => {
  return { errors, loading };
};

const actions = {
  login,
  closeModal,
  socialLogin,
  openModal,
  resetErrors
};

const enhance = compose(
  connect(mapStateToProps, actions),
  withStyles(styles),
  withRouter
);

export default enhance(LogInModal);
