import React from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from './SnackbarContentWrapper';
import { closeSnackbar } from '../../actions';
import {
  closeSnackbarType,
  messageType,
  autoHideDurationType
} from '../../types';

const SuccessSnackbar = props => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    open
    autoHideDuration={props.autoHideDuration || 3000}
    onClose={props.closeSnackbar}
  >
    <SnackbarContentWrapper
      onClose={props.closeSnackbar}
      variant="success"
      message={props.message}
    />
  </Snackbar>
);

SuccessSnackbar.propTypes = {
  closeSnackbar: closeSnackbarType.isRequired,
  message: messageType.isRequired,
  autoHideDuration: autoHideDurationType
};

SuccessSnackbar.defaultProps = {
  autoHideDuration: null
};

const actions = { closeSnackbar };

export default connect(null, actions)(SuccessSnackbar);
