// pick popular tags and write your own tags
import React from 'react';
import { compose } from 'redux'; // compose from lodash also works
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import {
  classesType,
  tagsType,
  handleClickTagType,
  handleTagsTextFieldType,
  mostPopularTagsType,
  isSmallScreenBoolType
} from '../../types';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column', // places the text field above the tag chips
    padding: '1rem 1rem',
    marginBottom: '0.5rem' // add some space to the bottom of tags paper
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  textField: {
    '& label.Mui-focused': {
      color: 'black' // keep the field label black after you've focused on the field
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black' // keep the field underline black after you've focused on the field
    }
  },
  popularTagsText: {
    margin: '0.25rem 0' // add some margin to the top and bottom of the Popular Tags: text
  }
});

const ChooseTags = ({
  classes,
  tags,
  handleClickTag,
  handleTagsTextField,
  mostPopularTags,
  isSmallScreen
}) => (
  <Paper className={classes.root} elevation={0}>
    <div>
      <TextField
        value={tags.length > 0 ? tags.join(', ') : ''}
        label="Choose Tags"
        className={classes.textField}
        margin="normal"
        fullWidth
        multiline={isSmallScreen}
        onChange={handleTagsTextField}
      />
    </div>
    <div>
      <Typography variant="body2" className={classes.popularTagsText}>
        Popular Tags:
      </Typography>
      {mostPopularTags.map(tag => {
        const avatar = null;
        return (
          <Chip
            variant="outlined"
            key={tag}
            avatar={avatar}
            label={tag}
            onClick={handleClickTag(tag)}
            className={classes.chip}
          />
        );
      })}
    </div>
  </Paper>
);

ChooseTags.propTypes = {
  classes: classesType.isRequired,
  tags: tagsType,
  handleClickTag: handleClickTagType.isRequired,
  handleTagsTextField: handleTagsTextFieldType.isRequired,
  mostPopularTags: mostPopularTagsType.isRequired,
  isSmallScreen: isSmallScreenBoolType.isRequired
};

ChooseTags.defaultProps = {
  tags: []
};

const enhance = compose(withStyles(styles));
export default enhance(ChooseTags);
