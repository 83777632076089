// reducer for actions/expandRetractKinksActions.js
// determines if a kink type tab should be expanded or retracted in mykinks/MyKinks.js
// by default, all kink types are retracted and the initialState is an array of all the kink types
// when a kink type is retracted, it is added to the state array
// when a kink type is expanded, it is removed from the state array
// RETRACT_KINK_ALL adds all of the kink types to the state array
// EXPAND_KINK_ALL removes all of the kink types from the state array
import {
  EXPAND_KINK,
  RETRACT_KINK,
  EXPAND_KINK_ALL,
  RETRACT_KINK_ALL
} from '../actions/types'; // import the action type
import kinkTypes from '../utils/kinkTypes';

const initialState = kinkTypes;

export default function(state = initialState, action) {
  switch (action.type) {
    case RETRACT_KINK: {
      const { kinkType } = action.payload;
      if (state.includes(kinkType)) {
        return state;
      }
      return [...state, kinkType];
    }
    case EXPAND_KINK: {
      const { kinkType } = action.payload;
      const newState = state.filter(cur => cur !== kinkType);
      return newState;
    }
    case RETRACT_KINK_ALL: {
      const newState = [...initialState];
      return newState;
    }
    case EXPAND_KINK_ALL: {
      return [];
    }
    default:
      return state;
  }
}
