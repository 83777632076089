// contains all the action types
// export const GET_PROMPTS_LIST = 'get_prompts_list';
// export const GET_USERS_LIST = 'get_users_list';
export const UPDATE_PROMPT = 'update_prompt';
export const FILTER_PROMPTS = 'filter_prompts';
export const CREATE_PROMPT = 'create_prompt';
export const GET_PROMPTS = 'get_prompts';
export const GET_MY_PROMPTS = 'get_my_prompts';
export const RESET_PROMPTS = 'reset_prompts';
export const RESET_MY_PROMPTS = 'reset_my_prompts';
export const GET_PROMPT = 'get_prompt';
export const UPDATE_PROMPT_REDUX_STATE = 'update_prompt_redux_state';
export const DELETE_PROMPT = 'delete_prompt';
export const UPDATE_MY_PROMPTS_REDUX_STATE = 'update_my_prompts_redux_state';

export const LOGIN_USER = 'login_user';
export const LOGOUT_USER = 'logout_user';
export const UNSUB_FROM_AUTH = 'unsub_from_auth';

export const UPDATE_MY_USER_REDUX_STATE = 'update_my_user_redux_state';
export const RESET_MY_USER_REDUX_STATE = 'reset_my_user_redux_state';

export const UPDATE_USER_PAGE_REDUX_STATE = 'update_user_page_redux_state';
export const REMOVE_USER_KINKS_REDUX_STATE = 'remove_user_kinks_redux_state';

export const BOOKMARKED = 'bookmarked';
export const NOT_BOOKMARKED = 'not_bookmarked';
export const REMOVE_BOOKMARK_LISTENER = 'remove_bookmark_listener';
export const GET_MY_BOOKMARKS = 'get_my_bookmarks';
export const DELETE_MY_BOOKMARK = 'delete_my_bookmark';
export const BOOKMARK_BUTTON_CLICK_PENDING_START =
  'bookmark_button_click_pending_start';
export const BOOKMARK_BUTTON_CLICK_PENDING_FINISH =
  'bookmark_button_click_pending_finish';

export const LOVED = 'loved';
export const NOT_LOVED = 'not_loved';
export const REMOVE_LOVE_LISTENER = 'remove_love_listener';
export const UPDATE_LOVES_PROMPT_REDUX_STATE =
  'update_loves_prompt_redux_state';
export const GET_MY_LOVES = 'get_my_loves';
export const DELETE_MY_LOVE = 'delete_my_love';
export const LOVE_BUTTON_CLICK_PENDING_START =
  'love_button_click_pending_start';
export const LOVE_BUTTON_CLICK_PENDING_FINISH =
  'love_button_click_pending_finish';

export const UPDATE_MY_KINKS_REDUX_STATE = 'update_my_kinks_redux_state';
export const UPDATE_USER_KINKS_REDUX_STATE = 'update_user_kinks_redux_state';

export const GET_INBOX_SENT_MESSAGES = 'get_inbox_sent_messages';
export const GET_INBOX_RECEIVED_MESSAGES = 'get_inbox_received_messages';

export const GET_CONVERSATION = 'get_conversation';
export const UPDATE_CONVERSATION_MESSAGES_REDUX_STATE =
  'update_conversation_messages_redux_state';
export const REMOVE_CONVERSATION_REDUX_STATE =
  'remove_conversation_redux_state';

export const UPDATE_CONVERSATIONS_REDUX_STATE =
  'update_conversations_redux_state';
export const UPDATE_CONVERSATIONS_MESSAGES_REDUX_STATE =
  'update_conversations_messages_redux_state';
export const REMOVE_CONVERSATIONS_REDUX_STATE =
  'remove_conversations_redux_state';

export const GET_GENERATED_CONVO_MSGS = 'get_generated_convo_msgs';
export const UPDATE_GENERATED_CONVO_MSG_REDUX_STATE =
  'update_generated_convo_msg_redux_state';
export const APPROVE_GENERATED_CONVO_MSG_REDUX_STATE =
  'approve_generated_convo_msg_redux_state';
export const DELETE_GENERATED_CONVO_MSG_REDUX_STATE =
  'delete_generated_convo_msg_redux_state';
export const DELETE_ALL_GENERATED_CONVO_MSGS_REDUX_STATE =
  'delete_all_generated_convo_msgs_redux_state';

export const GET_USERS_PRIVATE_DOCS = 'get_users_private_docs';

export const FOLLOWING_USER = 'following_user';
export const NOT_FOLLOWING_USER = 'not_following_user';
export const REMOVE_FOLLOW_USER_LISTENER = 'remove_follow_user_listener';
export const GET_MY_FOLLOWING = 'get_my_following';
export const GET_MY_FOLLOWERS = 'get_my_followers';

export const GET_MY_STATS = 'get_my_stats';

export const ASYNC_ACTION_START = 'async_action_start';
export const ASYNC_ACTION_FINISH = 'async_action_finish';
export const ASYNC_ACTION_ERROR = 'async_action_error';

export const MODAL_OPEN = 'modal_open';
export const MODAL_CLOSE = 'modal_close';
export const SNACKBAR_OPEN = 'snackbar_open';
export const SNACKBAR_CLOSE = 'snackbar_close';

export const EXPAND = 'expand';
export const RETRACT = 'retract';
export const EXPAND_ALL = 'expand_all';
export const RETRACT_ALL = 'retract_all';
export const EXPAND_KINK = 'expand_kink';
export const RETRACT_KINK = 'retract_kink';
export const EXPAND_KINK_ALL = 'expand_kink_all';
export const RETRACT_KINK_ALL = 'retract_kink_all';

export const GET_FEEDBACKS = 'get_feedbacks';
export const DELETE_FEEDBACK = 'delete_feedback';
export const FILTER_FEEDBACKS = 'filter_feedbacks';

export const SAVE_TEXT_EDITOR = 'save_text_editor';
export const CLEAR_TEXT_EDITOR = 'clear_text_editor';

export const FIREBASE_ERROR = 'firebase_error';
export const MANMADE_ERRORS = 'manmade_errors';
export const RESET_ERRORS = 'reset_errors';

export const GET_MOST_POPULAR_TAGS = 'get_most_popular_tags';
export const GET_ALL_TAGS = 'get_all_tags';

export const UPDATE_NEW_STUFF_ALERTS = 'update_new_stuff_alerts';
export const GET_ALL_NEW_CONVERSATION_MESSAGE_ALERTS =
  'get_all_new_conversation_message_alerts'; // used by admin to get all conversation message alerts of all users

export const GET_FLAGGED_USERS = 'get_flagged_users';

export const IS_SMALL_SCREEN = 'is_small_screen';
export const NOT_SMALL_SCREEN = 'not_small_screen';

export const CONVOSFORMYPROMPT_VISIBILITY = 'convosformyprompt_visibility';
export const EDITMESSAGEFORM_VISIBILITY = 'editmessageform_visibility';
export const FOOTER_VISIBILITY = 'footer_visibility';
export const HEADERDESKTOP_VISIBILITY = 'headerdesktop_visibility';
export const HEADERMOBILE_VISIBILITY = 'headermobile_visibility';
export const HEADER_VISIBILITY = 'header_visibility';
export const PROMPTBOTTOMMENUCONTAINER_VISIBILITY =
  'promptbottommenucontainer_visibility';
export const PROMPTHEADERBUTTON_VISIBILITY = 'promptheaderbutton_visibility';

export const GET_OUR_USERS = 'get_our_users';

export const GET_DPP_MESSAGES = 'get_dpp_messages';
export const GET_OTHER_DPP_SUBMISSIONS = 'get_other_dpp_submissions';
export const DELETE_OTHER_DPP_SUBMISSION = 'delete_other_dpp_submission';

export const GET_CROSS_POSTED_PROMPTS = 'get_cross_posted_prompts';
