// reducer for asyncActions.js action creator
import { LOGIN_USER, LOGOUT_USER, UNSUB_FROM_AUTH } from '../actions/types';

const initialState = {
  currentUser: {},
  providerData: [],
  authenticated: false,
  triedAuth: false // triedAuth is for PrivateRoute.js, when you hard refresh a protected page
  // it checks to make sure authentication is at least attempted before redirecting you
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER: {
      const { currentUser, providerData } = action.payload;
      return {
        ...state,
        authenticated: true,
        triedAuth: true,
        currentUser: { ...currentUser },
        providerData: [...providerData]
      };
    }

    case LOGOUT_USER: {
      return {
        ...state,
        authenticated: false,
        triedAuth: true,
        currentUser: {},
        providerData: []
      };
    }

    case UNSUB_FROM_AUTH: {
      const { unsubscribeFromAuth } = action.payload;
      return {
        ...state,
        unsubscribeFromAuth
      };
    }

    default:
      return state;
  }
};
