// use this form to signup
// used in header/Header.js
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import {
  registerUser,
  socialLogin,
  closeModal,
  openModal,
  resetErrors,
  dispatchManmadeErrors
} from '../../actions';
import SocialLogin from '../loginpage/SocialLogin';
import TextFieldErrorMessage from '../commoncomponents/TextFieldErrorMessage';
import checkSignUpBefore from '../../utils/checkSignUpBefore';
import CircularLoadingButton from '../commoncomponents/CircularLoadingButton';
import {
  registerUserType,
  socialLoginType,
  closeModalType,
  openModalType,
  classesType,
  errorsType,
  loadingType,
  resetErrorsType,
  dispatchManmadeErrorsType
} from '../../types';

const styles = theme => ({
  fields: {
    display: 'flex',
    flexDirection: 'column',
    width: '20rem'
  },
  textfield: {
    '& label.Mui-focused': {
      color: 'black' // keep the field label black after you've focused on the field
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black' // keep the field underline black after you've focused on the field
    },
    '& label.Mui-error': {
      color: red[700] // make the field label red when you make an error
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: red[700] // make the field underline red when you make an error
    }
  },
  buttonWrapper: {
    margin: 'auto',
    position: 'relative'
  },
  continueButton: {
    paddingLeft: '2rem',
    paddingRight: '2rem'
  },
  socialLoginButtons: {
    margin: 'auto'
  },
  or: {
    margin: 'auto'
  },
  loginThings: {
    margin: 'auto',
    display: 'flex',
    alignItems: 'baseline',
    padding: '1rem',
    color: theme.palette.text.meta2
  },
  loginButton: {
    color: theme.palette.text.link
  },
  // when it is 600px or less
  '@media (max-width: 37.5rem)': {
    fields: {
      width: '17rem'
    },
    loginThings: {
      padding: '1rem 0.5rem'
    }
  }
});

class SignUpModal extends Component {
  state = {
    page: 1,
    username: '',
    age: '',
    email: '',
    password: ''
  };

  componentWillUnmount() {
    this.props.resetErrors();
  }

  // changes from first page to the 2nd after hitting the Continue button
  handleChangePage = async () => {
    const { username, age } = this.state;
    const errors = await checkSignUpBefore({ username, age });
    if (errors) {
      this.props.dispatchManmadeErrors(errors);
    } else {
      this.setState({ page: 2 });
    }
  };

  handleUsername = event => {
    this.setState({ username: event.target.value });
  };

  handleEmail = event => {
    this.setState({ email: event.target.value });
  };

  handlePassword = event => {
    this.setState({ password: event.target.value });
  };

  handleAge = event => {
    this.setState({ age: event.target.value });
  };

  handleSocialLogin = selectedProvider => () => {
    const { username, age } = this.state;
    const user = { username, age };
    this.props.socialLogin(selectedProvider, user);
  };

  handleSubmit = () => {
    const { username, age, email, password } = this.state;
    this.props.registerUser({ username, age, email, password });
    // this.props.closeModal();
  };

  handleKeyPress = event => {
    const { page } = this.state;
    if (event.key === 'Enter') {
      if (page === 1) {
        this.handleChangePage(); // if on page 1 and "enter" is pressed, go to page 2
      } else if (page === 2) {
        this.handleSubmit(); // if on page 2 and "enter" is pressed submit form
      }
    }
  };

  render() {
    const { page, username, email, password, age } = this.state;
    const { classes, errors, loading } = this.props;
    if (page === 1) {
      return (
        <Dialog
          open
          onClose={this.props.closeModal}
          onKeyPress={this.handleKeyPress}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="form-dialog-title">Sign Up!</DialogTitle>
          <DialogContent className={classes.fields}>
            <DialogContentText variant="body2">
              Sign up to Create Prompts and Start Conversations!
              {/* Sign up to Create Prompts and Reply to Em */}
            </DialogContentText>
            <TextField
              error={errors && errors.username && true}
              id="username"
              label="Username"
              type="text"
              value={username}
              onChange={this.handleUsername}
              className={classes.textfield}
            />
            <TextFieldErrorMessage errors={errors} errorType="username" />
            <TextField
              error={errors && errors.age && true}
              id="age"
              label="Age"
              type="number"
              value={age}
              onChange={this.handleAge}
              className={classes.textfield}
            />
            <TextFieldErrorMessage errors={errors} errorType="age" />
          </DialogContent>
          <DialogActions>
            <div className={classes.buttonWrapper}>
              <Button
                onClick={this.handleChangePage}
                variant="contained"
                color="primary"
                className={classes.continueButton}
              >
                Continue
              </Button>
              {loading && <CircularLoadingButton />}
            </div>
          </DialogActions>
          <Typography variant="caption" className={classes.loginThings}>
            Already have an account?
            {/* Already have an EroPenPal account? Log In */}
            <Button
              size="small"
              onClick={() => this.props.openModal('LogInModal')}
              className={classes.loginButton}
            >
              Log in
            </Button>
          </Typography>
        </Dialog>
      );
    }
    if (page === 2) {
      return (
        <Dialog
          open
          onClose={this.props.closeModal}
          onKeyPress={this.handleKeyPress}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="form-dialog-title">Sign Up!</DialogTitle>
          <DialogContent className={classes.fields}>
            <DialogContentText variant="body2">
              Sign up to Create Prompts and Start Conversations!
              {/* Sign up to Create Prompts and Reply to Em */}
            </DialogContentText>
            <TextField
              error={errors && errors.email && true}
              id="email"
              label="Email"
              type="text"
              value={email}
              onChange={this.handleEmail}
              className={classes.textfield}
            />
            <TextFieldErrorMessage errors={errors} errorType="email" />
            <TextField
              error={errors && errors.password && true}
              id="password"
              label="Password"
              type="password"
              value={password}
              onChange={this.handlePassword}
              className={classes.textfield}
            />
            <TextFieldErrorMessage errors={errors} errorType="password" />
          </DialogContent>
          <DialogActions>
            <div className={classes.buttonWrapper}>
              <Button
                onClick={this.handleSubmit}
                variant="contained"
                color="primary"
                className={classes.continueButton}
              >
                Sign Up
              </Button>
              {loading && <CircularLoadingButton />}
            </div>
          </DialogActions>
          <Typography className={classes.or}>or</Typography>
          <DialogActions className={classes.socialLoginButtons}>
            <SocialLogin handleSocialLogin={this.handleSocialLogin} isSignUp />
          </DialogActions>
          <Typography variant="caption" className={classes.loginThings}>
            Already have an account?
            {/* Already have an EroPenPal account? Log In */}
            <Button
              size="small"
              onClick={() => this.props.openModal('LogInModal')}
              className={classes.loginButton}
            >
              Log in
            </Button>
          </Typography>
        </Dialog>
      );
    }
    return null;
  }
}

SignUpModal.propTypes = {
  registerUser: registerUserType.isRequired,
  socialLogin: socialLoginType.isRequired,
  closeModal: closeModalType.isRequired,
  openModal: openModalType.isRequired,
  classes: classesType.isRequired,
  errors: errorsType.isRequired,
  loading: loadingType.isRequired,
  resetErrors: resetErrorsType.isRequired,
  dispatchManmadeErrors: dispatchManmadeErrorsType.isRequired
};

const mapStateToProps = ({ async: { loading }, errors }) => {
  return {
    loading,
    errors
  };
};

const actions = {
  registerUser,
  socialLogin,
  closeModal,
  openModal,
  resetErrors,
  dispatchManmadeErrors
};

const enhance = compose(connect(mapStateToProps, actions), withStyles(styles));

export default enhance(SignUpModal);
