// click this button to open a Share Menu to copy link to clipboard and more
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  TwitterShareButton,
  TumblrShareButton,
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  RedditShareButton,
  TumblrIcon
} from 'react-share';
import LinkIcon from '@material-ui/icons/Link';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';
import FacebookIcon from '@material-ui/icons/Facebook';
import RedditIcon from '@material-ui/icons/Reddit';
import EmailIcon from '@material-ui/icons/Email';
import { openSnackbar } from '../../actions';
import {
  openSnackbarType,
  handleShareMenuCloseType,
  locationType,
  anchorElType,
  promptTitleType,
  promptTagsType,
  classesType
} from '../../types';

const styles = () => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start'
  },
  reactShareIcon: {
    marginLeft: '1px',
    marginBottom: '2px',
    marginRight: '1px'
    // paddingRight: '1px' // make the icon smaller
  }
});

class ShareMenu extends Component {
  handleCopiedLink = () => {
    this.props.openSnackbar('SuccessSnackbar', {
      message: 'Successfully copied the link to clipboard!'
    });
  };

  generateCurrentURL = () => {
    const { location } = this.props;
    if (process.env.NODE_ENV === 'development') {
      return `http://localhost:3000${location.pathname}`;
    }
    return `https://eropenpal.com${location.pathname}`;
  };

  render() {
    const {
      anchorEl,
      handleShareMenuClose,
      promptTitle,
      promptTags,
      classes
    } = this.props;

    return (
      <Menu
        id="shareMenu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleShareMenuClose}
      >
        <CopyToClipboard
          onCopy={this.handleCopiedLink}
          text={this.generateCurrentURL()}
        >
          <MenuItem onClick={handleShareMenuClose}>
            <LinkIcon />
            Copy Link
          </MenuItem>
        </CopyToClipboard>
        <MenuItem onClick={handleShareMenuClose}>
          <TwitterShareButton
            className={classes.menuItem}
            title={promptTitle}
            hashtags={promptTags}
            url={this.generateCurrentURL()}
          >
            <TwitterIcon />
            Twitter
          </TwitterShareButton>
        </MenuItem>
        <MenuItem onClick={handleShareMenuClose}>
          <WhatsappShareButton
            className={classes.menuItem}
            title={promptTitle}
            url={this.generateCurrentURL()}
          >
            <WhatsAppIcon />
            Whatsapp
          </WhatsappShareButton>
        </MenuItem>
        <MenuItem onClick={handleShareMenuClose}>
          <TumblrShareButton
            className={classes.menuItem}
            title={promptTitle}
            tags={promptTags}
            url={this.generateCurrentURL()}
          >
            <TumblrIcon size={20} round className={classes.reactShareIcon} />
            Tumblr
          </TumblrShareButton>
        </MenuItem>
        <MenuItem onClick={handleShareMenuClose}>
          <TelegramShareButton
            className={classes.menuItem}
            url={this.generateCurrentURL()}
          >
            <TelegramIcon />
            Telegram
          </TelegramShareButton>
        </MenuItem>
        <MenuItem onClick={handleShareMenuClose}>
          <FacebookShareButton
            className={classes.menuItem}
            url={this.generateCurrentURL()}
          >
            <FacebookIcon /> Facebook
          </FacebookShareButton>
        </MenuItem>
        <MenuItem onClick={handleShareMenuClose}>
          <RedditShareButton
            className={classes.menuItem}
            url={this.generateCurrentURL()}
          >
            <RedditIcon />
            Reddit
          </RedditShareButton>
        </MenuItem>
        <MenuItem onClick={handleShareMenuClose}>
          <EmailShareButton
            className={classes.menuItem}
            url={this.generateCurrentURL()}
          >
            <EmailIcon />
            Email
          </EmailShareButton>
        </MenuItem>
      </Menu>
    );

    // return (
    //   <Menu
    //     id="shareMenu"
    //     anchorEl={anchorEl}
    //     open={Boolean(anchorEl)}
    //     onClose={handleShareMenuClose}
    //   >
    //     <div className={classes.menuItemsWrapper}>
    //       {' '}
    //       <CopyToClipboard
    //         onCopy={this.handleCopiedLink}
    //         text={this.generateCurrentURL()}
    //       >
    //         <MenuItem onClick={handleShareMenuClose}>
    //           <LinkIcon />
    //           Copy Link
    //         </MenuItem>
    //       </CopyToClipboard>
    //       <TwitterShareButton
    //         title={promptTitle}
    //         hashtags={promptTags}
    //         url={this.generateCurrentURL()}
    //       >
    //         <MenuItem onClick={handleShareMenuClose}>
    //           <TwitterIcon />
    //           Twitter
    //         </MenuItem>
    //       </TwitterShareButton>
    //       <WhatsappShareButton
    //         title={promptTitle}
    //         url={this.generateCurrentURL()}
    //       >
    //         <MenuItem onClick={handleShareMenuClose}>
    //           <WhatsAppIcon />
    //           Whatsapp
    //         </MenuItem>
    //       </WhatsappShareButton>
    //       <TumblrShareButton
    //         title={promptTitle}
    //         tags={promptTags}
    //         url={this.generateCurrentURL()}
    //       >
    //         <MenuItem onClick={handleShareMenuClose}>
    //           <TumblrIcon size={20} round className={classes.reactShareIcon} />
    //           Tumblr
    //         </MenuItem>
    //       </TumblrShareButton>
    //       <TelegramShareButton url={this.generateCurrentURL()}>
    //         <MenuItem onClick={handleShareMenuClose}>
    //           <TelegramIcon />
    //           Telegram
    //         </MenuItem>
    //       </TelegramShareButton>
    //       <FacebookShareButton url={this.generateCurrentURL()}>
    //         <MenuItem onClick={handleShareMenuClose}>
    //           <FacebookIcon /> Facebook
    //         </MenuItem>
    //       </FacebookShareButton>
    //       <EmailShareButton url={this.generateCurrentURL()}>
    //         <MenuItem onClick={handleShareMenuClose}>
    //           <EmailIcon />
    //           Email
    //         </MenuItem>
    //       </EmailShareButton>
    //     </div>
    //   </Menu>
    // );
  }
}

ShareMenu.propTypes = {
  openSnackbar: openSnackbarType.isRequired,
  handleShareMenuClose: handleShareMenuCloseType.isRequired,
  location: locationType.isRequired,
  anchorEl: anchorElType,
  promptTitle: promptTitleType.isRequired,
  promptTags: promptTagsType.isRequired,
  classes: classesType.isRequired
};

ShareMenu.defaultProps = {
  anchorEl: null
};

const actions = {
  openSnackbar
};

const enhance = compose(connect(null, actions), withRouter, withStyles(styles));

export default enhance(ShareMenu);
